import {
  Avatar,
  Button,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import fetchApi from "../Lib/api";
import { useParams } from "react-router-dom";
import Notification from "../Lib/Notification";

export default function MeetingConfirmation() {
  const { token } = useParams();
  const [meeting, setMeeting] = useState(null);
  const [notification, setNotification] = useState(null);
  console.log(token);

  const fetchData = async (index) => {
    const url = [
      `/meetings/verify/${token}`,
      `/meetings/accept/${token}`,
      `/meetings/reject/${token}`,
    ][index];
    try {
      const response = await fetchApi(url, index ? "POST" : "GET");

      if (response.ok) {
        const res = await response.json();
        console.log(res);

        if (!index) setMeeting(res);
        else window.location.href = `/startup_entities/${res.slug}`;
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.log(msg);
      }
    } catch (error) {
      console.error("Error during shedule confirmation:", error);
    }
  };

  useEffect(() => {
    fetchData(0);
  }, []);

  if (!meeting) return <></>;

  return (
    <main className="flex justify-center ">
      <div className="max-w-2xl w-full px-6 py-12">
        <Paper radius="md" withBorder p="xl">
          {notification && <Notification {...notification} />}
          <div className="px-8 pt-6 pb-8 mb-4 flex flex-col">
            <h1 className="text-xl font-bold mb-2">Meeting Verification</h1>

            <div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Meeting Title
                </label>
                <div className="px-3 py-2 border rounded text-gray-700">
                  {meeting.title}
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Description
                </label>
                <div className="px-3 py-2 border rounded text-gray-700">
                  {meeting.description}
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Scheduled Date
                </label>
                <div className="px-3 py-2 border rounded text-gray-700">
                  {new Date(meeting.scheduled_date).toLocaleString()}
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Organizer
                </label>
                <div className="flex items-center">
                  <img
                    src={
                      meeting.organizer.avatar_url ||
                      "https://placehold.co/600x400?text=No Image"
                    }
                    alt={meeting.organizer.first_name}
                    className="w-16 h-16 mr-4 rounded-full"
                  />
                  <span className="px-3 py-2 border rounded text-gray-700">
                    {meeting.organizer.first_name} {meeting.organizer.last_name}
                  </span>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {meeting.startup.entity}
                </label>
                <div className="flex items-center">
                  <img
                    src={meeting.startup.image_url}
                    alt={meeting.startup.name}
                    className="w-16 h-16 mr-4 rounded-full"
                  />
                  <span className="px-3 py-2 border rounded text-gray-700">
                    {meeting.startup.name}
                  </span>
                </div>
              </div>
            </div>

            <Group noWrap>
              <Button
                onClick={() => fetchData(1)}
                variant="default"
                fullWidth
                className="px-12 h-12"
              >
                Accept
              </Button>

              <Button
                onClick={() => fetchData(2)}
                // onClick={() => (window.location.href = "/dashboard")}
                variant="default"
                fullWidth
                className="text-red-700 px-12 h-12"
              >
                Decline
              </Button>
            </Group>
          </div>
        </Paper>
      </div>
    </main>
  );
}
