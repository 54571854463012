import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import {
  createStyles,
  rem,
  getStylesRef,
  Dialog,
  Rating,
  Button,
  Textarea,
  Indicator,
  Badge,
} from "@mantine/core";
import { upperFirst, useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  IconLogout,
  IconHome,
  IconUsersGroup,
  IconMessage,
  IconUserCircle,
  IconHeadset,
  IconSocial,
  IconMessages,
  IconInfoCircle,
  IconStack3,
} from "@tabler/icons-react";
import { UserContext } from "./UserContext";
import fetchApi from "./api";
import CustomNavLink from "./CustomNavLink";
import Notification from "./Notification";

const data = [
  {
    link: { hash: "", href: "/dashboard/#/" },
    label: "Home",
    icon: IconHome,
    className: "home_startups",
  },
  {
    link: { hash: "forums", href: "/dashboard/#/forums" },
    label: "Forums",
    icon: IconMessage,
  },
  {
    link: { hash: "members", href: "/dashboard/#/members" },
    label: "Members",
    icon: IconUsersGroup,
    className: "all_members",
  },
  {
    link: { hash: "messages", href: "/dashboard/#/messages" },
    label: "Messages",
    icon: IconMessages,
  },
  {
    link: { hash: "activity", href: "/dashboard/#/activity" },
    label: "Activity",
    icon: IconSocial,
  },
];

const FooterLink = ({
  href,
  label,
  tooltipLabel,
  icon,
  onClick,
  hide,
  className,
}) => (
  <a
    href={href}
    className={`flex items-center px-3 py-2 rounded-md text-sm font-medium w-full hover:bg-blue-200 hover:text-blue-800 ${className}`}
    onClick={(event) => {
      onClick && onClick();
    }}
  >
    {icon}
    <span>{label}</span>
  </a>
);

const useStyles = createStyles((theme) => ({
  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.dark[6],
    marginRight: theme.spacing.sm,
  },
}));

export const handleLogout = async () => {
  try {
    const response = await fetchApi("/users/sign_out", "DELETE", {});

    if (response) {
      // Redirect using JavaScript after successful logout
      window.location.href = "/users/sign_in"; // Redirect to the homepage
    } else {
      console.error("Logout error:", "Failed to log out");
    }
  } catch (error) {
    console.error("Logout error:", error.message);
  }
};

const NotificationCount = (c) =>
  c ? (
    <span className="inline-flex items-center py-0.5 px-1.5 ml-auto rounded-full text-xs font-medium bg-blue-800 text-white">
      {c}
    </span>
  ) : (
    ""
  );

export default function NavbarSimple({ handleSidebarToggle }) {
  const { pathname } = useLocation();
  const [active, setActive] = useState("Home");
  const {
    user: { user, recent_activities_count, chats_count },
  } = useContext(UserContext);

  const { classes, cx } = useStyles();

  useEffect(() => {
    const parts = pathname.split("/");
    const lastPart = parts[parts.length - 1];

    // Update the active state with the last part
    console.log(lastPart);
    if (lastPart.length > 3) setActive(upperFirst(lastPart));
  }, [pathname]);

  const links = data.map((item) => (
    <CustomNavLink
      to={item.link}
      className={`${
        item.className
      } flex items-center px-3 py-2 rounded-md text-sm font-medium w-full ${
        item.label === active
          ? "bg-blue-800 text-blue-200"
          : "hover:bg-blue-200 hover:text-blue-800"
      }`}
      key={item.label}
      onClick={() => {
        setActive(item.label);
        handleSidebarToggle(false);
      }}
    >
      <item.icon className={classes.linkIcon} stroke={2} />
      <span>{item.label}</span>{" "}
      {["Activity", "Messages"].includes(item.label) && (
        <>
          {item.label === "Activity"
            ? NotificationCount(recent_activities_count)
            : NotificationCount(chats_count)}
        </>
      )}
    </CustomNavLink>
  ));

  return (
    <>
      <a href="/dashboard" className="mb-10 w-full">
        <img
          src={require("./logo2.png")}
          className="mb-0 w-[50%] lg:w-[80%] mx-auto"
        />

        <img src={require("./logo1.png")} />
        <div className="flex justify-end">
          <Badge
            size="lg"
            variant="gradient"
            radius="sm"
            gradient={{ from: "green", to: "indigo", deg: 181 }}
          >
            Beta
          </Badge>
        </div>
      </a>

      <nav className={classes.navbar}>
        <div className={classes.navbarMain}>{links}</div>

        <div>
          <div className={classes.footer}>
            <CustomNavLink
              to={{ hash: "workspace", href: "/dashboard/#/workspace" }}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium w-full ${
                "Workspace" === active
                  ? "bg-blue-800 text-blue-200"
                  : "hover:bg-blue-200 hover:text-blue-800"
              }`}
              key={"Workspace"}
              onClick={() => {
                setActive("Workspace");
                handleSidebarToggle(false);
              }}
            >
              <IconStack3 className={classes.linkIcon} stroke={2} />
              <span>My Workspace</span>
            </CustomNavLink>
            <FooterLink
              className="complete_profile"
              href={user.profile_complete ? "/profile" : "/onboarding"}
              label={user.profile_complete ? "Profile" : "Complete Profile"}
              tooltipLabel={
                user.profile_complete ? "Edit Profile" : "Complete Profile"
              }
              icon={
                <IconUserCircle
                  className={classes.linkIcon}
                  stroke={2}
                  style={{ width: rem(28), height: rem(28) }}
                />
              }
            />
          </div>

          <div className={classes.footer}>
            <FooterLink
              href="#"
              label="Logout"
              tooltipLabel="Logout"
              icon={
                <IconLogout
                  className={classes.linkIcon}
                  stroke={2}
                  style={{ width: rem(28), height: rem(28) }}
                />
              }
              onClick={handleLogout}
            />
            <FooterLink
              href="#"
              label="Help and Support"
              tooltipLabel="Help"
              icon={
                <IconHeadset
                  className={classes.linkIcon}
                  stroke={2}
                  style={{ width: rem(28), height: rem(28) }}
                />
              }
            />
            <Feedback user={user} classes={classes} />
          </div>
        </div>
      </nav>
    </>
  );
}

const Feedback = ({ user, classes }) => {
  const [openModal, { open, close }] = useDisclosure(
    !user.user_submit_feedback
  );
  const [desc, setDesc] = useState("");
  const [rating, setRating] = useState(1);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const submitFeedback = async () => {
    if (desc.length < 10 && rating < 4) {
      setError("Too short! Please write something meaningful.");
      return;
    }
    setLoading(true);
    try {
      const response = await fetchApi("/feedbacks", "POST", {
        feedback: {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          message: desc || "All Good. No Comment.",
          rating,
        },
      });

      if (response.ok) {
        const res = await response.json();
        setNotification({ type: "success", content: res.message });
        setTimeout(() => close(), 2000);
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: res.error });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }

    setLoading(false);
  };
  return (
    <>
      <Dialog
        opened={openModal}
        position={{ bottom: 20, right: 20 }}
        size="xl"
        radius="md"
        p={0}
      >
        <div className="flex w-full flex-col border rounded-lg bg-white p-4">
          {notification && <Notification {...notification} />}
          <h2 className="title-font mb-1 text-center text-lg font-medium text-gray-900">
            Rate your experience
          </h2>
          <p className="m-2 text-sm text-center leading-relaxed text-gray-600">
            We value your feedback, kindly take a moment to share your thoughts
            with us.
          </p>
          <div className="mb-1 flex justify-center">
            <Rating
              color="violet"
              size="lg"
              value={rating}
              onChange={setRating}
            />
          </div>
          <div className="mb-2">
            <Textarea
              onChange={(event) => setDesc(event.currentTarget.value)}
              value={desc}
              id="message"
              name="message"
              placeholder="Tell us about your experience!"
              withAsterisk
              error={error}
            />
          </div>
          <Button
            onClick={submitFeedback}
            loading={loading}
            className="rounded border-0 bg-indigo-500 py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
          >
            Submit
          </Button>
        </div>
      </Dialog>

      <FooterLink
        onClick={open}
        label="Give feedback"
        tooltipLabel="Help"
        icon={
          <IconInfoCircle
            className={classes.linkIcon}
            stroke={2}
            style={{ width: rem(28), height: rem(28) }}
          />
        }
      />
    </>
  );
};
