import React, { useContext, useEffect, useState } from "react";
// const pokemon = require("../../../../public/notification.wav");
import NavbarSimple from "./SideBar";
import {
  ActionIcon,
  Anchor,
  Avatar,
  Group,
  Indicator,
  Popover,
  Progress,
  Text,
  rem,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  IconArrowRight,
  IconMessages,
  IconSocial,
  IconX,
} from "@tabler/icons-react";
import { UserContext } from "../Lib/UserContext";
import fetchApi from "./api";
import moment from "moment";
import parse from "html-react-parser";
import App from "../../channels/consumer";

export default function HeaderAndSidebar({
  sidebar,
  hideDefaultSidebar,
  children,
}) {
  const isMobile = useMediaQuery("(max-width: 1160px)");
  const [opened, { toggle, close }] = useDisclosure(!isMobile);
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);
  const {
    user: { user, recent_activities_count, chats_count, gamification },
  } = useContext(UserContext);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <div className="flex h-screen antialiased text-gray-900 bg-gray-100">
        {/* First Sidebar */}

        <aside
          className={`fixed inset-y-0 z-50 flex-shrink-0 w-full max-w-60 lg:max-w-80 p-5 bg-white  lg:static  ${
            !isSidebarOpen || !isMobile ? "" : "hidden"
          }`}
          tabIndex="-1"
          onKeyDown={(event) =>
            event.key === "Escape" && window.innerWidth <= 1024
              ? setIsSidebarOpen(false)
              : ""
          }
        >
          {!isSidebarOpen && (
            <button
              onClick={handleSidebarToggle}
              type="button"
              className="size-9 ml-auto flex justify-center items-center text-sm font-semibold rounded-lg border border-gray-200 text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none lg:hidden"
              data-hs-overlay="#navbar-secondary-content"
              aria-controls="navbar-secondary-content"
              aria-label="Toggle navigation"
            >
              <IconX />
            </button>
          )}
          <div className="flex flex-col lg:mt-[25%] lg:ml-[30%]">
            {sidebar}
            {hideDefaultSidebar ? (
              <></>
            ) : (
              <NavbarSimple handleSidebarToggle={handleSidebarToggle} />
            )}
          </div>
        </aside>

        <main className="flex-1 overflow-y-scroll">
          <div className="flex flex-col flex-1 min-h-screen  overflow-x-hidden overflow-y-hidden">
            <header className="flex flex-wrap sm:justify-start sm:flex-nowrap z-20 w-full bg-transparent border-b border-gray-200 text-sm  sm:py-1 ">
              <nav
                className="relative max-w-7xl flex flex-wrap basis-full items-center w-full mx-auto px-4 sm:flex sm:items-center sm:justify-end sm:px-6 "
                aria-label="Global"
              >
                <div className="flex items-center justify-end ms-auto sm:ms-0 sm:order-3 min-w-64">
                  <div className="hidden lg:flex gap-2">
                    <GamificationBar
                      gamification={gamification}
                      user_id={user.id}
                    />

                    <MessagesNotification
                      chats_count={chats_count}
                      user_id={user.id}
                    />

                    <ActivitiesNotification
                      recent_activities_count={recent_activities_count}
                      user_id={user.id}
                    />
                  </div>

                  <div className="ps-3 sm:ps-6 sm:ms-6 sm:border-s lg:hidden sm:border-gray-300 dark:border-neutral-700">
                    <button
                      type="button"
                      onClick={handleSidebarToggle}
                      className="size-9 flex justify-center items-center text-sm font-semibold rounded-lg border border-gray-200 text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-neutral-700 dark:hover:bg-neutral-700"
                      data-hs-overlay="#navbar-secondary-content"
                      aria-controls="navbar-secondary-content"
                      aria-label="Toggle navigation"
                    >
                      <svg
                        className="hs-collapse-open:hidden flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <line x1="3" x2="21" y1="6" y2="6" />
                        <line x1="3" x2="21" y1="12" y2="12" />
                        <line x1="3" x2="21" y1="18" y2="18" />
                      </svg>
                      <svg
                        className="hs-collapse-open:block hidden flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M18 6 6 18" />
                        <path d="m6 6 12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </nav>
            </header>
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}

const play = () => {
  const notificationSound = new Audio("/notification.mp3");
  notificationSound.play();
};

const GamificationBar = ({ gamification, user_id }) => {
  const [userGamification, setUserGamification] = useState(gamification);
  useEffect(() => {
    const subscription = App.subscriptions.create(
      { channel: "GamificationChannel", user_id },
      {
        connected() {
          console.log("Successfully connected to Notifications Channel");
        },
        disconnected() {
          console.log("Disconnected from Notifications Channel");
        },
        received(data) {
          console.log("New notification received:", data);
          setUserGamification(data);
          play();
        },
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  return (
    <div
      className="flex w-[200px] my-auto h-4 bg-gray-300 rounded-full overflow-hidden"
      role="progressbar"
      aria-valuenow="75"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div
        className="flex flex-col p-2 justify-center rounded-full overflow-hidden bg-gradient-to-r from-green-400 to-blue-500 text-xs text-white text-center whitespace-nowrap transition duration-500"
        style={{
          width: `${
            (userGamification.current_points /
              userGamification.next_level.points_threshold) *
            100
          }%`,
        }}
      >
        {userGamification.current_points}PX
      </div>
    </div>
  );
};

const MessagesNotification = ({ chats_count, user_id }) => {
  const [unread, setUnread] = useState([]);
  const [read, setRead] = useState([]);
  const [newCount, setNewCount] = useState(0);

  useEffect(() => {
    const subscription = App.subscriptions.create(
      { channel: "MessagesChannel", user_id },
      {
        connected() {
          console.log("Successfully connected to Notifications Channel");
        },
        disconnected() {
          console.log("Disconnected from Notifications Channel");
        },
        received(data) {
          console.log("New notification received:", data);
          setUnread((p) => [data, ...p]);
          setNewCount((p) => ++p);
          play();
        },
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/api/users/fetch_chats`, "GET");

        if (response.ok) {
          const res = await response.json();
          console.log(res);
          setUnread(res.unread_chats);
          setRead(res.next_five_chats);
        } else {
          const msg = (await response.json()).error;
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during fetching activities:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <Popover
      position="bottom-end"
      withArrow
      shadow="md"
      classNames={"lg:min-w-72"}
      width={500}
    >
      <Popover.Target>
        <Indicator
          position="bottom-center"
          inline
          label={chats_count + newCount}
          disabled={!(chats_count + newCount)}
          size={20}
          color="rgba(52, 74, 173, 1)"
        >
          <ActionIcon
            size={42}
            variant="default"
            aria-label="ActionIcon with size as a number"
          >
            <IconMessages style={{ width: rem(24), height: rem(24) }} />
          </ActionIcon>
        </Indicator>
      </Popover.Target>
      <Popover.Dropdown>
        <ol className="mt-3 divide-y divider-gray-200">
          {unread.map(({ recipient, last_message, id }) => (
            <li key={id} className="list-none">
              <Anchor
                href={`/dashboard/#/messages?user=${id}`}
                underline={false}
                className="flex items-center py-2 px-3 lg:py-4 lg:px-6 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
              >
                <div className="lg:mr-4">
                  <Avatar
                    src={recipient.avatar_url}
                    className="rounded-full w-10 h-10"
                    alt=""
                  />
                </div>
                <div className="flex-grow hidden lg:inline">
                  <Group position="apart">
                    <h5 className="text-lg font-semibold">
                      {recipient.first_name} {recipient.last_name}
                    </h5>
                    <div className="text-gray-500 text-xs">
                      {moment(last_message?.created_at).fromNow()}
                    </div>
                  </Group>
                  <p className="text-gray-500 text-sm line-clamp-2">
                    {last_message?.content}
                  </p>
                </div>
              </Anchor>
            </li>
          ))}
        </ol>
        <Anchor href="/dashboard/#/messages">
          <Group className="mb-2" position="center" spacing={4}>
            View all messages
            <IconArrowRight size={18} color="#1c7ed6" />
          </Group>
        </Anchor>
      </Popover.Dropdown>
    </Popover>
  );
};

const ActivitiesNotification = ({ recent_activities_count, user_id }) => {
  const [unread, setUnread] = useState([]);
  const [read, setRead] = useState([]);
  const [newCount, setNewCount] = useState(0);

  useEffect(() => {
    const subscription = App.subscriptions.create(
      { channel: "UserActivitiesChannel", user_id },
      {
        connected() {
          console.log("Connected to Activities Channel");
        },
        disconnected() {
          console.log("Disconnected from Activities Channel");
        },
        received(data) {
          setUnread((p) => [data, ...p]);
          setNewCount((p) => ++p);
          play();
        },
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/api/users/fetch_activities`, "GET");

        if (response.ok) {
          const res = await response.json();
          console.log(res);
          setUnread(res.unread_activities);
          setRead(res.next_five_activities);
        } else {
          const msg = (await response.json()).error;
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during fetching activities:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <Popover position="bottom-end" withArrow shadow="md">
      <Popover.Target>
        <Indicator
          position="bottom-center"
          disabled={!(recent_activities_count + newCount)}
          inline
          label={recent_activities_count + newCount}
          size={20}
          color="rgba(52, 74, 173, 1)"
        >
          <ActionIcon
            size={42}
            variant="default"
            aria-label="ActionIcon with size as a number"
          >
            <IconSocial style={{ width: rem(24), height: rem(24) }} />
          </ActionIcon>
        </Indicator>
      </Popover.Target>
      <Popover.Dropdown>
        {unread.length ? (
          <RenderActivities title={"Recent Activities"} read={unread} />
        ) : (
          <></>
        )}
        <RenderActivities title={"Previous Activities"} read={read} />
        <Anchor href="/dashboard/#/activity">
          <Group className="mb-2" position="center" spacing={4}>
            View all activity
            <IconArrowRight size={18} color="#1c7ed6" />
          </Group>
        </Anchor>
      </Popover.Dropdown>
    </Popover>
  );
};

const RenderActivities = ({ read, title }) => {
  return (
    <>
      <p className="text-lg font-semibold text-gray-900 ">{title}</p>
      <ol className="mt-3 divide-y divider-gray-200">
        {read
          .slice(0, 5)
          .map(
            ({ id, actor, message, description, sub_message, view, url }) => (
              <li className="list-none" key={id}>
                <a
                  href={url || "#"}
                  className="items-center block p-3 sm:flex hover:bg-gray-100 "
                >
                  <Avatar
                    className="w-12 h-12 mb-3 me-3 rounded-full sm:mb-0"
                    src={
                      actor.avatar_url ||
                      "https://placehold.co/600x400?text=No Image"
                    }
                    alt={actor.name}
                  />
                  <div className="text-gray-600 ">
                    <div className="text-base font-normal">
                      {parse(description || message || "")}
                    </div>
                    {sub_message && (
                      <div className="text-sm font-normal">{sub_message}</div>
                    )}
                    {view === "Private" ? (
                      <span className="inline-flex items-center text-xs font-normal text-gray-700 ">
                        <svg
                          className="w-2.5 h-2.5 me-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="m2 13.587 3.055-3.055A4.913 4.913 0 0 1 5 10a5.006 5.006 0 0 1 5-5c.178.008.356.026.532.054l1.744-1.744A8.973 8.973 0 0 0 10 3C4.612 3 0 8.336 0 10a6.49 6.49 0 0 0 2 3.587Z" />
                          <path d="m12.7 8.714 6.007-6.007a1 1 0 1 0-1.414-1.414L11.286 7.3a2.98 2.98 0 0 0-.588-.21l-.035-.01a2.981 2.981 0 0 0-3.584 3.583c0 .012.008.022.01.033.05.204.12.401.211.59l-6.007 6.007a1 1 0 1 0 1.414 1.414L8.714 12.7c.189.091.386.162.59.211.011 0 .021.007.033.01a2.981 2.981 0 0 0 3.584-3.584c0-.012-.008-.023-.011-.035a3.05 3.05 0 0 0-.21-.588Z" />
                          <path d="M17.821 6.593 14.964 9.45a4.952 4.952 0 0 1-5.514 5.514L7.665 16.75c.767.165 1.55.25 2.335.251 6.453 0 10-5.258 10-7 0-1.166-1.637-2.874-2.179-3.407Z" />
                        </svg>
                        Private
                      </span>
                    ) : (
                      <span className="inline-flex items-center text-xs font-normal text-gray-500 dark:text-gray-400">
                        <svg
                          className="w-2.5 h-2.5 me-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19ZM8.374 17.4a7.6 7.6 0 0 1-5.9-7.4c0-.83.137-1.655.406-2.441l.239.019a3.887 3.887 0 0 1 2.082 2.5 4.1 4.1 0 0 0 2.441 2.8c1.148.522 1.389 2.007.732 4.522Zm3.6-8.829a.997.997 0 0 0-.027-.225 5.456 5.456 0 0 0-2.811-3.662c-.832-.527-1.347-.854-1.486-1.89a7.584 7.584 0 0 1 8.364 2.47c-1.387.208-2.14 2.237-2.14 3.307a1.187 1.187 0 0 1-1.9 0Zm1.626 8.053-.671-2.013a1.9 1.9 0 0 1 1.771-1.757l2.032.619a7.553 7.553 0 0 1-3.132 3.151Z" />
                        </svg>
                        Public
                      </span>
                    )}
                  </div>
                </a>
              </li>
            )
          )}
      </ol>
    </>
  );
};
