import React from "react";
import { MantineProvider } from "@mantine/core";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

export default function HashLayout({ children }) {
  return (
    <Router>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          breakpoints: {
            xs: "30em",
            sm: "240px", // Small screens and up
            md: "568px", // Medium screens and up
            lg: "1024px", // Large screens and up
            xl: "1280px", // Extra-large screens and up
            "2xl": "1736px", // 2XL screens and up
          },
        }}
      >
        {children}
      </MantineProvider>
    </Router>
  );
}
