import React, { useState } from "react";
import { Group, createStyles, rem, getStylesRef } from "@mantine/core";
import {
  IconSettings,
  IconLogout,
  IconId,
  IconHome,
  IconSocial,
  IconStack3,
} from "@tabler/icons-react";
import { NavLink } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  header: {
    width: "200px",
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  footer: {
    width: "200px",
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    cursor: "pointer",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 700,
    width: "200px",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,

      [`& .${getStylesRef("icon")}`]: {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
      },
    },
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.dark[6],
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: "green",
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
      [`& .${getStylesRef("icon")}`]: {
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
      },
    },
  },

  user: {
    display: "block",
    width: "100%",
    padding: theme.spacing.md,
    marginBlockEnd: theme.spacing.lg,

    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));

const data = [
  { link: "/", label: "Profile", icon: IconId },
  { link: "/workspace", label: "My Workspace", icon: IconStack3 },
  { link: "/activity", label: "Activity", icon: IconSocial },
  { link: "/settings", label: "Settings", icon: IconSettings },
];

export default function NavbarSimple({ handleSidebarToggle }) {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Profile");

  const links = data.map((item) => (
    <NavLink
      className={`flex items-center px-3 ml-3 py-2 rounded-md text-sm font-medium w-2/3 lg:w-full  ${
        item.label === active
          ? "bg-blue-800 text-blue-200"
          : "hover:bg-blue-200 hover:text-blue-800"
      }`}
      data-active={item.label === active || undefined}
      to={item.link}
      key={item.label}
      onClick={(event) => {
        setActive(item.label);
        handleSidebarToggle(false);
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </NavLink>
  ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarMain}>
        <Group className={classes.header} justify="space-between"></Group>
        <a
          className={`flex items-center px-3 ml-3 py-2 rounded-md text-sm font-medium w-2/3 lg:w-full 
             hover:bg-blue-200 hover:text-blue-800
         `}
          href={"/dashboard/#/"}
          key={"profile"}
        >
          <IconHome className={classes.linkIcon} stroke={1.5} />
          <span>Home</span>
        </a>
        {links}
      </div>

      <div className={classes.footer}>
        <a
          href="#"
          className={classes.link}
          onClick={(event) => event.preventDefault()}
        >
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Sign Out</span>
        </a>
      </div>
    </nav>
  );
}
