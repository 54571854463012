import React, { useState } from "react";
import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  Group,
  Input,
  Loader,
  Modal,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import fetchApi from "../Lib/api";
import Notification from "../Lib/Notification";
import { IconChevronRight, IconSearch } from "@tabler/icons-react";

export function InviteMembers({ canInvite, startup, role }) {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [roleOffered, setRoleOffered] = useState("");
  const [reason, setReason] = useState("");
  const [notification, setNotification] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  const handleChange = async (val) => {
    console.log(val);
    setValue(val);
    setData([]);

    if (!val.trim().length || val.trim().length < 3) {
      setLoading(false);
    } else {
      setLoading(true);
      try {
        const response = await fetch(`/api/users/search?search=${val}`);
        const users = await response.json();

        setData(users);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (email) => {
    try {
      const response = await fetchApi("/invitations", "POST", {
        email: email,
        startup_id: startup.startup_entity.id,
      });

      if (response.ok) {
        const msg = (await response.json()).message;
        setNotification({ type: "success", content: msg });
        console.log("Invited successful!");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Invitation failed.");
      }
    } catch (error) {
      console.error("Error during Invitation", error);
    }
  };

  const handleInvite = async () => {
    try {
      const response = await fetchApi(
        `/startup_entities/${startup.startup_entity.slug}/join_requests`,
        "POST",
        {
          join_request: { reason, role: roleOffered },
        }
      );

      if (response.ok) {
        const msg = (await response.json()).message;
        setNotification({ type: "success", content: msg });
        setDisableButton(true);
        console.log("Request sent successful!");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Request failed.");
      }
    } catch (error) {
      console.error("Error during Sending request", error);
    }
  };

  const sendUserInvite = async () => {
    try {
      const response = await fetchApi("/users/invitation", "POST", {
        user: { email: value },
        startup_id: startup.startup_entity.id,
      });

      if (response.ok) {
        const msg = (await response.json()).message;
        setNotification({ type: "success", content: msg });
        console.log("Request sent successful!");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Request failed.");
      }
    } catch (error) {
      console.error("Error during Sending request", error);
    }
  };

  return (
    <>
      {opened && (
        <Modal.Root
          opened={opened}
          onClose={() => {
            setValue("");
            setNotification(null);
            close();
          }}
          size="lg"
          padding={0}
          withCloseButton={false}
        >
          <Modal.Overlay />
          <Modal.Content className="ml-[10vh]">
            <Modal.Body>
              <Card radius="md" padding={0}>
                {notification && <Notification {...notification} />}
                {canInvite ? (
                  <>
                    <Card.Section withBorder inheritPadding py="xs">
                      <TextInput
                        icon={<IconSearch />}
                        variant="unstyled"
                        placeholder="Search for a user using email or name"
                        // value={value}
                        onChange={(event) =>
                          handleChange(event.currentTarget.value)
                        }
                        rightSection={loading ? <Loader size="1rem" /> : null}
                      />
                    </Card.Section>
                    <ul>
                      {data.map(
                        ({
                          first_name,
                          last_name,
                          job_title,
                          avatar_url,
                          email,
                        }) => (
                          <li className="p-4 hover:bg-gray-200 mb-4 cursor-pointer">
                            <Group noWrap onClick={() => handleSubmit(email)}>
                              <Avatar src={avatar_url} radius="xl" />

                              <div style={{ flex: 1 }}>
                                <Text size="sm" weight={500}>
                                  {first_name} {last_name}
                                </Text>

                                <Text color="dimmed" size="xs">
                                  {job_title}
                                </Text>
                              </div>

                              <IconChevronRight size="1rem" />
                            </Group>
                          </li>
                        )
                      )}
                    </ul>

                    {!data.length &&
                      value &&
                      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && (
                        <>
                          <Card.Section withBorder inheritPadding py="xs">
                            <Text className="text-center">
                              No user with the email {value}. You can send an
                              invite to the user to join and become a member.
                            </Text>
                          </Card.Section>
                          <Button
                            onClick={sendUserInvite}
                            variant="default"
                            fullWidth
                            className="px-12"
                          >
                            Invite {value}
                          </Button>
                        </>
                      )}
                  </>
                ) : (
                  <Stack className="p-4">
                    <Textarea
                      label="Reason for your interest"
                      placeholder="Hello..."
                      autosize
                      minRows={3}
                      maxRows={5}
                      value={reason}
                      onChange={(event) => setReason(event.currentTarget.value)}
                    />
                    <Select
                      label="Status"
                      data={[
                        "Founding Team",
                        "Contributor",
                        "Intern/Volunteer",
                        "Consultant",
                        "Investor",
                      ]}
                      value={roleOffered}
                      description="Indicate the status you are looking for"
                      placeholder="e.g., Co-founder"
                      onChange={setRoleOffered}
                      withinPortal={true}
                    />
                    {!notification ? (
                      <Button
                        onClick={handleInvite}
                        variant="default"
                        fullWidth
                        className="w-full px-12"
                        disabled={!reason}
                      >
                        Request
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setNotification(null);
                          close();
                        }}
                        variant="default"
                        fullWidth
                        className="w-full px-12"
                        disabled={!reason}
                      >
                        Close
                      </Button>
                    )}
                  </Stack>
                )}
              </Card>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      )}
      <Button
        disabled={
          disableButton ||
          (canInvite && !role && startup.startup_entity.members.length < 7) ||
          startup.existing_join_request
        }
        onClick={open}
        variant="default"
        fullWidth
        className="w-fit px-12"
      >
        {canInvite
          ? "Invite to the team"
          : `${
              disableButton || startup.existing_join_request
                ? "Request sent already"
                : "Request to join team"
            }`}
      </Button>
    </>
  );
}
