import {
  Text,
  ActionIcon,
  Tooltip,
  Button,
  Checkbox,
  Group,
  Modal,
  Select,
  Stack,
  TextInput,
  Textarea,
  ThemeIcon,
  Title,
  Grid,
  Collapse,
  rem,
  HoverCard,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import fetchApi from "../../Lib/api";
import { IconCategoryPlus, IconPlus } from "@tabler/icons-react";
import Notification from "../../Lib/Notification";
import { UserContext } from "../../Lib/UserContext";
import { IconChevronDown } from "@tabler/icons-react";
import { emojis } from "../../Onboarding/data";
import ModerationUsers from "./Forums/ModerationUsers";
import ModerationActivity from "./Forums/ModerationActivity";
import ModerationHistory from "./Forums/ModerationHistory";
import RenderTopics from "./Forums/Topics";
import moment from "moment";

function InputWithButton({ placeholder, setSearch }) {
  const handleSearch = async (value) => {
    try {
      const response = await fetchApi(`/hub/?q=${value}`, "GET");

      if (response.ok) {
        const res = await response.json();
        // setNotification({ type: "success", content: msg });
        console.log(res);
        setSearch({ value, result: res.topic_views });
        // setUnreadTopics(res.topic_views);
        // setGeneralBoards(res);
        // window.location.href = "/startup_entities/1";
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };
  return (
    <div className="relative">
      <div className="absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none">
        <svg
          className="w-8 h-8 text-gray-500"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
      </div>
      <input
        type="search"
        id="default-search"
        className="block w-full p-4 ps-16 text-xl text-gray-900 border-none outline-none rounded-lg  focus:ring-red-500 focus:border-red-500"
        placeholder={placeholder}
        required
        onKeyDown={(e) => {
          if (e.key === "Enter" && e.target.value.trim() !== "") {
            handleSearch(e.target.value.trim());
          }
        }}
      />
    </div>
  );
}

function ChannelsGroup({ collection: { group, messageboards }, setToggle }) {
  const [opened, setOpened] = useState(false);

  const items = messageboards.map((mb, i) => (
    <HoverCard
      key={mb.slug || mb.messageboard.slug}
      // width={280}
      shadow="md"
      position="right"
    >
      <HoverCard.Target>
        <Text
          component="a"
          className={` font-medium
        block
        text-decoration-none
        px-3 py-2
        pl-4
        ml-6
        text-sm
        cursor-pointer
        text-gray-700 dark:text-gray-100
        border-l border-gray-300 dark:border-gray-600
        hover:bg-gray-100 dark:hover:bg-gray-700
        hover:text-black dark:hover:text-gray-100`}
          onClick={() =>
            setToggle({
              page: "topics",
              context: {
                channel: group.name,
                slug: mb.slug || mb.messageboard.slug,
                name: mb.name || mb.messageboard.name,
              },
            })
          }
        >
          {mb.name || mb.messageboard.name}
        </Text>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <div className="bg-white w-full rounded-lg shadow-lg p-6">
          <div className="flex items-center mb-4">
            <img
              src="https://flowbite.com/docs/images/examples/image-3@2x.jpg"
              alt="Author Image"
              className="h-14 w-14 rounded-full mr-4"
            />
            <div>
              <span className="font-semibold text-gray-800 hover:text-blue-600 hover:underline">
                {mb.name || mb.messageboard.name}
              </span>
              <div className="text-gray-600 text-sm">
                {mb.description || mb.messageboard.description}
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-between items-center">
            <div className="text-gray-500 text-sm">
              <b>Topics:</b> {mb.topics_count}
            </div>
            <div className="text-gray-500 text-sm">
              <b>Posts:</b> {mb.posts_count}
            </div>
            <div className="text-gray-500 text-sm">
              <b>Last activity:</b>{" "}
              {moment(mb.updated_at || mb.messageboard.updated_at).fromNow()}
            </div>
          </div>
        </div>
      </HoverCard.Dropdown>
    </HoverCard>
  ));

  return (
    <>
      <Group
        position="apart"
        noWrap
        className="px-2 py-2 hover:bg-gray-100 rounded-sm text-sm font-medium leading-tight"
      >
        <button
          onClick={() =>
            setToggle({
              page: "topics",

              context: { id: group.id, channel: group.name },
            })
          }
          key={group.name}
          // className="block px-2 py-2 rounded-sm text-xs font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-dark-600"
        >
          <span className="mr-2 text-sm emoji">{group.emoji}</span>
          {group.name}
        </button>
        <IconChevronDown
          className="cursor-pointer"
          onClick={() => setOpened(!opened)}
          stroke={1.5}
          style={{
            width: rem(16),
            height: rem(16),
            transform: opened ? "rotate(180deg)" : "none",
          }}
        />
      </Group>
      <Collapse in={opened}>{items}</Collapse>
    </>
  );
}

const CreateEditCategory = ({ groups, setChannelsGroup }) => {
  const [openCreateCategory, setOpenCategory] = useState(false);
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [channelDesc, setChannelDesc] = useState("");
  const [channelGroup, setChannelGroup] = useState(null);
  const [checked, setChecked] = useState(false);

  const handleRequest = async () => {
    try {
      const response = await fetchApi("/hub/create_messageboard", "POST", {
        messageboard: {
          name: channelName,
          description: channelDesc,
          messageboard_group_id: channelGroup,
          locked: checked,
        },
      });

      if (response.ok) {
        const res = await response.json();
        setChannelsGroup(res.groups);
        setNotification({
          type: "success",
          content: channelName + " Created Successfully",
        });
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  return (
    <>
      {openCreateCategory && (
        <Modal
          opened={openCreateCategory}
          size="lg"
          onClose={() => setOpenCategory(false)}
          withCloseButton={false}
        >
          {notification && <Notification {...notification} />}
          <Stack spacing={18} className="p-5">
            <Title order={5}>Create a New Channel</Title>
            <TextInput
              label="Name"
              value={channelName}
              onChange={(event) => setChannelName(event.currentTarget.value)}
            />
            <Textarea
              label="Description"
              value={channelDesc}
              onChange={(event) => setChannelDesc(event.currentTarget.value)}
              maxRows={5}
              minRows={3}
            />
            <Select
              data={groups}
              value={channelGroup}
              onChange={(value) => setChannelGroup(value)}
              withinPortal
            />
            <Checkbox
              label="Locked"
              variant="outline"
              checked={checked}
              onChange={(event) => setChecked(event.currentTarget.checked)}
            />

            {notification ? (
              <Button
                fullWidth
                onClick={() => {
                  setOpenCategory(false);
                  setNotification(null);
                }}
              >
                Close
              </Button>
            ) : (
              <Button
                onClick={handleRequest}
                variant="default"
                fullWidth
                className="w-full px-12"
                disabled={!(channelName && channelDesc)}
              >
                Create
              </Button>
            )}
          </Stack>
        </Modal>
      )}

      <Button
        fullWidth
        onClick={() => setOpenCategory(true)}
        loading={openCreateCategory && loading}
      >
        Create a sub channel
      </Button>
    </>
  );
};

const CreateCategoryGroup = ({ setChannelsGroup }) => {
  const [openCreateCategoryGroup, setOpenCategoryGroup] = useState(false);
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [emoji, setEmoji] = useState("");

  const handleRequest = async () => {
    try {
      const response = await fetchApi(
        "/hub/admin/messageboard_groups",
        "POST",
        {
          messageboard_group: {
            name: groupName,
            emoji: emoji.split(" ")[0],
          },
        }
      );

      if (response.ok) {
        const res = await response.json();
        setChannelsGroup(res.groups);
        setNotification({
          type: "success",
          content: groupName + " Created successfully",
        });
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  return (
    <>
      {openCreateCategoryGroup && (
        <Modal
          opened={openCreateCategoryGroup}
          size="lg"
          onClose={() => setOpenCategoryGroup(false)}
          withCloseButton={false}
        >
          {notification && <Notification {...notification} />}
          <Stack spacing={18} className="p-5">
            <Title order={5}>Create a New Channel Group</Title>
            <TextInput
              label="Name"
              value={groupName}
              onChange={(event) => setGroupName(event.currentTarget.value)}
            />

            <Select
              label="Choose an icon that reflects the group"
              placeholder="Pick one"
              value={emoji}
              onChange={setEmoji}
              searchable
              nothingFound="No options"
              data={emojis}
              withinPortal
            />

            {notification ? (
              <Button
                fullWidth
                onClick={() => {
                  setOpenCategoryGroup(false);
                  setNotification(null);
                }}
              >
                Close
              </Button>
            ) : (
              <Button
                onClick={handleRequest}
                variant="default"
                fullWidth
                className="w-full px-12"
                disabled={!groupName || !emoji}
              >
                Create
              </Button>
            )}
          </Stack>
        </Modal>
      )}

      <Button
        fullWidth
        onClick={() => setOpenCategoryGroup(true)}
        loading={openCreateCategoryGroup && loading}
      >
        Create a channel
      </Button>
    </>
  );
};

function SettingsForm({ messageboard }) {
  const [followNewTopics, setFollowNewTopics] = useState(false);
  const [followMentionedTopics, setFollowMentionedTopics] = useState(false);
  const [emailNotificationsForTopics, setEmailNotificationsForTopics] =
    useState([]);
  const [mfollowNewTopics, setMFollowNewTopics] = useState(false);
  const [mfollowMentionedTopics, setMFollowMentionedTopics] = useState(false);
  const [memailNotificationsForTopics, setMEmailNotificationsForTopics] =
    useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      let url;

      if (messageboard.slug) url = `/hub/${messageboard.slug}/preferences/edit`;
      else url = "/hub/preferences/edit";
      try {
        const response = await fetchApi(url, "GET");
        if (response.ok) {
          const res = await response.json();
          console.log(res);
          setFollowNewTopics(res.user_preferences.auto_follow_topics);
          setFollowMentionedTopics(
            res.user_preferences.follow_topics_on_mention
          );
          setEmailNotificationsForTopics(
            res.user_preferences.notifications_for_followed_topics
          );

          if (messageboard.slug) {
            setMFollowNewTopics(res.messageboard_preference.auto_follow_topics);
            setMFollowMentionedTopics(
              res.messageboard_preference.follow_topics_on_mention
            );
            setMEmailNotificationsForTopics(
              res.messageboard_preference.notifications_for_followed_topics
            );
          }
        } else {
          console.error((await response.json()).error);
        }
      } catch (error) {
        console.error("Error during data fetching:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [messageboard]);

  const handleRequest = async () => {
    let url;

    if (messageboard.slug) url = `/hub/${messageboard.slug}/preferences`;
    else url = "/hub/preferences";

    const requestBody = {
      user_preferences_form: {
        auto_follow_topics: +followNewTopics,
        follow_topics_on_mention: +followMentionedTopics,
        notifications_for_followed_topics_attributes:
          emailNotificationsForTopics,
      },
    };

    if (messageboard.slug) {
      requestBody.user_preferences_form.messageboard_auto_follow_topics =
        +mfollowNewTopics;
      requestBody.user_preferences_form.messageboard_follow_topics_on_mention =
        +mfollowMentionedTopics;
      requestBody.user_preferences_form.messageboard_notifications_for_followed_topics_attributes =
        memailNotificationsForTopics;
    }

    try {
      const response = await fetchApi(url, "PATCH", requestBody);

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        // setNotification({
        //   type: "success",
        //   content: groupName + " Created successfully",
        // });
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during preference update:", error);
    }
  };

  if (loading) return <></>;

  return (
    <form onSubmit={handleRequest} className="px-3">
      <Stack mb={20}>
        <Checkbox
          label="Follow all new topics"
          checked={followNewTopics}
          onChange={(event) => setFollowNewTopics(event.currentTarget.checked)}
        />
        <Checkbox
          label="Follow topics you are mentioned in"
          checked={followMentionedTopics}
          onChange={(event) =>
            setFollowMentionedTopics(event.currentTarget.checked)
          }
        />
        <Checkbox
          label="Notifications for followed topics by email"
          checked={
            emailNotificationsForTopics[0] &&
            emailNotificationsForTopics[0].enabled
          }
          onChange={(event) =>
            setEmailNotificationsForTopics((prev) => {
              const { checked } = event.target;
              const newState = [...prev];
              newState[0] = {
                ...newState[0],
                enabled: checked,
              };
              return newState;
            })
          }
        />
      </Stack>

      {messageboard && messageboard.slug && (
        <>
          <Text c="dimmed" className="text-xs mb-4">
            Settings for {messageboard.name}
          </Text>

          <Stack mb={20}>
            <Checkbox
              label="Follow all new topics"
              checked={mfollowNewTopics}
              onChange={(event) =>
                setMFollowNewTopics(event.currentTarget.checked)
              }
            />
            <Checkbox
              label="Follow topics you are mentioned in"
              checked={mfollowMentionedTopics}
              onChange={(event) =>
                setMFollowMentionedTopics(event.currentTarget.checked)
              }
            />
            <Checkbox
              label="Notifications for followed topics by email"
              checked={
                memailNotificationsForTopics[0] &&
                memailNotificationsForTopics[0].enabled
              }
              onChange={(event) =>
                setMEmailNotificationsForTopics((prev) => {
                  const { checked } = event.target;
                  const newState = [...prev];
                  newState[0] = {
                    ...newState[0],
                    enabled: checked,
                  };
                  return newState;
                })
              }
            />
          </Stack>
        </>
      )}

      <Button fullWidth type="submit">
        Update
      </Button>
    </form>
  );
}

export default function Forums() {
  const [channelsGroup, setChannelsGroup] = useState([]);
  const [groups, setGroups] = useState([]);
  const [toggle, setToggle] = useState({
    page: "topics",
    context: {},
  });
  const {
    user: { user },
  } = useContext(UserContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchApi("/hub", "GET");
        if (response.ok) {
          const res = await response.json();
          console.log(res);
          setChannelsGroup(res.groups);
          setGroups(
            res.groups.map((g) => ({
              label: g.group.emoji + "  " + g.group.name,
              value: g.group.id,
            }))
          );
        } else {
          console.error((await response.json()).error);
        }
      } catch (error) {
        console.error("Error during data fetching:", error);
      }
    }
    fetchData();
  }, []);

  const handleToggle = (p) => {
    setToggle(p);
  };

  const collectionLinks = channelsGroup.map((cg, i) => (
    <ChannelsGroup key={i} collection={cg} setToggle={handleToggle} />
  ));

  return (
    <section className="flex justify-center">
      <div className="max-w-[90rem] w-full p-6">
        <Group>
          <ThemeIcon
            variant="gradient"
            size="lg"
            aria-label="Gradient action icon"
            gradient={{ from: "blue", to: "rgba(8, 0, 18, 1)", deg: 90 }}
          >
            <IconCategoryPlus size={20} />
          </ThemeIcon>
          <Title order={3} className="text-gray-600">
            Forum
          </Title>
        </Group>

        <Grid columns={24} mt={20}>
          <Grid.Col span={5}>
            <Stack>
              {user.admin && (
                <nav className="bg-white dark:bg-black  py-2 flex flex-col border-r border-gray-300 dark:border-dark-400">
                  <div className="border-gray-300 dark:border-dark-400">
                    <div className="flex items-center justify-between my-1 px-2">
                      <Text
                        size="xs"
                        fw={500}
                        className="text-xs font-medium text-gray-500 dark:text-gray-400"
                      >
                        Admin Panel
                      </Text>
                    </div>
                    <div className="px-2">
                      <button
                        onClick={() =>
                          setToggle({
                            page: "admin",
                            context: { select: "pending" },
                          })
                        }
                        className="block w-full text-left  px-2 py-2 rounded-sm text-sm font-medium text-gray-700  hover:bg-gray-100 "
                      >
                        <span className="mr-2 text-sm emoji">⏳</span>
                        Pending
                      </button>
                      <button
                        onClick={() =>
                          setToggle({
                            page: "admin",
                            context: { select: "history" },
                          })
                        }
                        className="block w-full text-left  px-2 py-2 rounded-sm text-sm font-medium text-gray-700  hover:bg-gray-100 "
                      >
                        <span className="mr-2 text-sm emoji">📚</span>
                        History
                      </button>
                      <button
                        onClick={() =>
                          setToggle({
                            page: "admin",
                            context: { select: "activity" },
                          })
                        }
                        className="block  w-full text-left  px-2 py-2 rounded-sm text-sm font-medium text-gray-700  hover:bg-gray-100"
                      >
                        <span className="mr-2 text-sm emoji">🔥</span>
                        Activity
                      </button>
                      <button
                        onClick={() =>
                          setToggle({
                            page: "admin",
                            context: { select: "users" },
                          })
                        }
                        className="block  w-full text-left  px-2 py-2 rounded-sm text-sm font-medium text-gray-700  hover:bg-gray-100 "
                      >
                        <span className="mr-2 text-sm emoji">🧑‍🤝‍🧑</span>
                        Users
                      </button>
                    </div>

                    <Stack spacing={4} px={8} mt={8}>
                      <CreateCategoryGroup
                        setChannelsGroup={setChannelsGroup}
                      />
                      <CreateEditCategory
                        groups={groups}
                        setChannelsGroup={setChannelsGroup}
                      />
                    </Stack>
                  </div>
                </nav>
              )}
              <nav className="bg-white dark:bg-black  py-2 flex flex-col border-r border-gray-300 dark:border-dark-400">
                <div className="border-gray-300 dark:border-dark-400">
                  <div className="flex items-center justify-between mb-1 px-2">
                    <Text
                      size="xs"
                      fw={500}
                      className="text-xs font-medium text-gray-500 dark:text-gray-400"
                    >
                      Channels
                    </Text>
                    <Tooltip
                      label="Create collection"
                      withArrow
                      position="right"
                    >
                      <ActionIcon variant="default" className="h-5 w-5">
                        <IconPlus className="h-3 w-3 stroke-1.5" />
                      </ActionIcon>
                    </Tooltip>
                  </div>
                  <div className="px-2">{collectionLinks}</div>
                </div>
              </nav>
            </Stack>
          </Grid.Col>
          <Grid.Col span="auto">
            {toggle.page === "topics" && (
              <RenderTopics
                context={toggle.context}
                userIsBlocked={user.thredded_user_detail.moderation_state}
              />
            )}

            {toggle.page === "admin" && <Moderation context={toggle.context} />}
          </Grid.Col>
          <Grid.Col span={5}>
            <nav className="bg-white dark:bg-black w-[15rem] py-2 flex flex-col border-r border-gray-300 dark:border-dark-400">
              <div className="border-gray-300 dark:border-dark-400">
                <div className="flex items-center justify-between mb-1 px-2">
                  <Text
                    size="xs"
                    fw={500}
                    className="text-xs font-medium text-gray-500 dark:text-gray-400"
                  >
                    Global settings
                  </Text>
                  <Tooltip label="Create collection" withArrow position="right">
                    <ActionIcon variant="default" className="h-5 w-5">
                      <IconPlus className="h-3 w-3 stroke-1.5" />
                    </ActionIcon>
                  </Tooltip>
                </div>
                <SettingsForm messageboard={toggle.context} />
              </div>
            </nav>
          </Grid.Col>
        </Grid>
      </div>
    </section>
  );
}

function Moderation({ context }) {
  return (
    <>
      {context.select === "users" && <ModerationUsers />}{" "}
      {context.select === "activity" && <ModerationActivity />}
      {context.select === "history" && <ModerationHistory />}
    </>
  );
}
