import React from "react";
import parse from "html-react-parser";

const Notification = ({ type, content }) => (
  <div
    className={`bg-${
      type === "failure" ? "red" : "green"
    }-100 border-l-4 border-${type === "failure" ? "red" : "green"}-500 text-${
      type === "failure" ? "red" : "green"
    }-700 p-2 mb-4`}
    role="alert"
  >
    <p className="font-bold">{type === "failure" ? "Error!" : "Success!"}</p>
    {parse(`<p>${content}</p>`)}
  </div>
);

export default Notification;
