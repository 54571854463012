import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../Lib/Layout";
import AuthenticationForm from "../Authentication/LoginRegisterForm";
import ChangePasswordForm from "../Authentication/ChangePasswordForm";
import CreatePasswordForInviteUser from "../Authentication/CreatePasswordForInviteUser";

export default function AuthenticationPage() {
  return (
    <Layout>
      <Routes>
        <Route path="/users/sign_in" element={<AuthenticationForm />} />
        <Route path="/users/password/edit" element={<ChangePasswordForm />} />
        <Route
          path="/users/invitation/accept"
          element={<CreatePasswordForInviteUser />}
        />
      </Routes>
    </Layout>
  );
}
