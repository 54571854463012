import {
  ActionIcon,
  Button,
  Collapse,
  Group,
  Modal,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEye, IconPencilPlus, IconTrash } from "@tabler/icons-react";
import React, { useState } from "react";
import Notification from "../Lib/Notification";
import fetchApi from "../Lib/api";
import Markdown from "../Lib/Markdown";

export default function OpeningRoles({ id, setStartup, openings, canAddRole }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [requirements, setRequirements] = useState("");
  const [notification, setNotification] = useState(null);

  const handleCreate = async () => {
    try {
      const response = await fetchApi(
        `/startup_entities/${id}/openings`,
        "POST",
        {
          opening: {
            role,
            status,
            requirements,
          },
        }
      );

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        setNotification({ type: "success", content: res.message });
        setStartup((prev) => ({
          ...prev,
          startup_entity: {
            ...prev.startup_entity,
            openings: [...prev.startup_entity.openings, res.opening],
          },
        }));
        setRole("");
        setStatus("");
        setRequirements("");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  const handleDelete = async (roleId) => {
    try {
      const response = await fetchApi(
        `/startup_entities/${id}/openings/${roleId}`,
        "Delete"
      );

      if (response.ok) {
        const res = await response.json();
        console.log(res);

        setStartup((prev) => ({
          ...prev,
          startup_entity: {
            ...prev.startup_entity,
            openings: prev.startup_entity.openings.filter(
              (p) => p.id != res.opening.id
            ),
          },
        }));
      } else {
        const msg = (await response.json()).error;

        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };
  return (
    <>
      <Modal opened={opened} size="lg" onClose={close} withCloseButton={false}>
        {notification && <Notification {...notification} />}
        <Stack spacing={18} className="p-3">
          <TextInput
            label="Role"
            value={role}
            description="Specify the role you are looking to fill"
            placeholder="e.g., Backend Developer"
            onChange={(event) => setRole(event.currentTarget.value)}
          />
          <Select
            label="Status"
            data={[
              "Founding Team",
              "Contributor",
              "Intern/Volunteer",
              "Consultant",
              "Investor",
            ]}
            value={status}
            description="Indicate the status being offered for this role"
            placeholder="e.g., Co-founder"
            onChange={setStatus}
          />
          <Stack spacing={4}>
            <Title order={6} fw={300}>
              Requirements
            </Title>
            <Markdown
              value={requirements}
              visibleDragbar={false}
              preview={"edit"}
              hideToolbar={false}
              height={300}
              setValue={(e) => {
                setRequirements(e);
              }}
            />
          </Stack>

          {notification ? (
            <Button
              onClick={() => {
                close();
                setNotification(null);
              }}
              variant="default"
              fullWidth
              className="w-full px-12"
            >
              Continue
            </Button>
          ) : (
            <Button
              onClick={handleCreate}
              variant="default"
              fullWidth
              className="w-full px-12"
              disabled={!(role && status)}
            >
              Add opening
            </Button>
          )}
        </Stack>
      </Modal>

      <div className="w-full p-4 bg-white border border-gray-200 rounded-lg shadow ">
        <Group position="apart" align="start">
          <h6 className="mb-3 text-base font-semibold text-gray-900 md:text-xl">
            Open Roles
          </h6>
          {canAddRole && (
            <ActionIcon
              size={30}
              variant="default"
              aria-label="Edit opening roles"
              onClick={open}
            >
              <IconPencilPlus style={{ width: rem(20), height: rem(20) }} />
            </ActionIcon>
          )}
        </Group>
        <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
          List of available roles being offered
        </p>

        <ul className="my-4 space-y-3">
          {openings.map((op) => (
            <Openings
              key={op.id}
              startupId={id}
              op={op}
              handleDelete={handleDelete}
              canAddRole={canAddRole}
            />
          ))}
        </ul>
      </div>
    </>
  );
}

function Openings({ startupId, op, handleDelete, canAddRole }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [notification, setNotification] = useState(null);

  return (
    <li className="list-none">
      <Modal opened={opened} size="lg" onClose={close} withCloseButton={false}>
        {notification && <Notification {...notification} />}
        <Title order={5} fw={400}>
          Requirements
        </Title>

        <Markdown
          value={op.requirements}
          visibleDragbar={false}
          preview={"preview"}
          hideToolbar={true}
          setValue={(e) => {}}
          height={200}
        />

        {!!canAddRole && (
          <ApplyForRole
            setNotification={setNotification}
            status={op.status}
            openingId={op.id}
            notification={notification}
            startupId={startupId}
          />
        )}
      </Modal>
      <Group
        position="apart"
        className="rounded-lg px-3 bg-gray-50 hover:bg-gray-100 group hover:shadow"
      >
        <div>
          <Text fz="sm" fw={500}>
            {op.role}
          </Text>
          <Text c="dimmed" fz="xs">
            {op.status}
          </Text>
        </div>

        <Group position="right" spacing={2}>
          <ActionIcon
            size={25}
            variant="default"
            aria-label="Edit opening roles"
            onClick={open}
          >
            <IconEye style={{ width: rem(17), height: rem(17) }} />
          </ActionIcon>
          {canAddRole && (
            <ActionIcon
              size={25}
              variant="default"
              aria-label="Edit opening roles"
              onClick={() => handleDelete(op.id)}
            >
              <IconTrash style={{ width: rem(17), height: rem(17) }} />
            </ActionIcon>
          )}
        </Group>
      </Group>
    </li>
  );
}

const ApplyForRole = ({
  startupId,
  openingId,
  setNotification,
  notification,
  status,
}) => {
  const [opened, { toggle }] = useDisclosure(false);
  const [reason, setReason] = useState("");
  const handleInvite = async () => {
    try {
      const response = await fetchApi(
        `/startup_entities/${startupId}/join_requests/create_for_opening`,
        "POST",
        {
          opening_id: openingId,
          join_request: { reason, role: status },
        }
      );

      if (response.ok) {
        const msg = (await response.json()).message;
        setNotification({ type: "success", content: msg });
        console.log("Request sent successful!");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Request failed.");
      }
    } catch (error) {
      console.error("Error during Sending request", error);
    }
  };
  return (
    <>
      <Group position="right" mt={10}>
        <Button variant="outline" onClick={toggle}>
          Apply for this role
        </Button>
      </Group>
      <Collapse in={opened}>
        <Stack className="mt-4">
          <Textarea
            label="Reason for your interest"
            placeholder="Hello..."
            autosize
            minRows={3}
            maxRows={5}
            value={reason}
            onChange={(event) => setReason(event.currentTarget.value)}
          />
          <Select
            label="Role"
            data={[
              "Founding Team",
              "Contributor",
              "Intern/Volunteer",
              "Consultant",
              "Investor",
            ]}
            value={status}
            disabled
            description="Role being offered for this."
            placeholder="e.g., Co-founder"
            withinPortal={true}
          />
          {!notification ? (
            <Button
              onClick={handleInvite}
              variant="default"
              fullWidth
              className="w-full px-12"
              disabled={!reason}
            >
              Request
            </Button>
          ) : (
            <Button
              onClick={() => {
                setNotification(null);
                close();
              }}
              variant="default"
              fullWidth
              className="w-full px-12"
              disabled={!reason}
            >
              Close
            </Button>
          )}
        </Stack>
      </Collapse>
    </>
  );
};
