import { Button, Group } from "@mantine/core";
import React from "react";
import fetchApi from "../Lib/api";

export default function ProgramChangesApproval({
  id,
  setProgram,
  selectedPrograms,
  toggle,
}) {
  const handleApproval = async (approve) => {
    try {
      const response = await fetchApi(
        `/sub_categories/${id}/${approve ? "approve" : "disapprove"}`,
        "PATCH"
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData.sub_category);
        setProgram((prev) => {
          return {
            ...prev,
            categories: prev.categories.map((c, i) =>
              i == selectedPrograms.cid
                ? {
                    ...c,
                    sub_categories: c.sub_categories.map((s, i) =>
                      i == selectedPrograms.sid ? responseData.sub_category : s
                    ),
                  }
                : c
            ),
          };
        });
        toggle();
      } else {
        console.error("HTTP error! Failed to submit form");
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };
  return (
    <Group grow>
      <Button
        onClick={() => handleApproval(true)}
        variant="default"
        fullWidth
        className="w-fit px-12"
      >
        Approve
      </Button>
      <Button
        onClick={() => handleApproval(false)}
        className="text-red-700 px-12"
        variant="default"
        fullWidth
      >
        Disapprove
      </Button>
    </Group>
  );
}
