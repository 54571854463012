import React from "react";
import HashLayout from "../Lib/HashLayout";
import { UserProvider } from "../Lib/UserContext";
import ProfilePage from "../Profile/Profile";

export default function DashbaordPage() {
  return (
    <HashLayout>
      <UserProvider>
        <ProfilePage />
      </UserProvider>
    </HashLayout>
  );
}
