import {
  TextInput,
  ActionIcon,
  rem,
  Paper,
  Title,
  Loader,
} from "@mantine/core";
import React, { useState } from "react";
import { IconSearch, IconArrowRight } from "@tabler/icons-react";
import fetchApi from "../Lib/api";

export default function AiChat({}) {
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  async function handleLLMAIRequest(inputs, parameters) {
    try {
      const requestBody = {
        inputs: inputs,
        parameters: parameters,
      };

      const response = await fetch(
        "https://promoted-suitable-eft.ngrok-free.app/generate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      console.log(response);

      if (response.ok) {
        const responseData = await response.json();
        const generatedText = responseData.generated_text;
        console.log("Generated Text:", generatedText);
        return generatedText;
      } else {
        console.error("AI request failed with status code", response.status);
        throw new Error(
          "AI request failed with status code " + response.status
        );
      }
    } catch (error) {
      console.error("Error during AI request:", error);
      throw error;
    }
  }

  async function handleButtonClick() {
    if (text.length < 5) return;
    setLoading(true);
    setMessages((p) => [{ user: text }, ...p]);
    setText("");
    try {
      const inputs = text;
      const parameters = {
        temperature: 0.1,
        max_tokens: 4096,
      };

      const generatedText = await handleLLMAIRequest(inputs, parameters);

      setMessages((p) => [{ ai: generatedText }, ...p]);
      setLoading(false);

      // Do something with the generated text
      console.log("Generated Text:", generatedText);
    } catch (error) {
      // Handle errors
      console.error("An error occurred:", error.message);
    }
  }

  return (
    <Paper
      shadow="xs"
      radius="md"
      withBorder
      p="lg"
      className="flex flex-col flex-grow mt-auto bg-gray-100"
    >
      <Title order={4} size="h4" fw={700}>
        Chat area
      </Title>
      {/* <Text className="mt-2 px-2 text-base text-gray-600">
        Looking for a guide, Write what you want here.
      </Text> */}

      <ul className="flex flex-col-reverse flex-grow  p-4 bg-gray-100 overflow-y-scroll max-h-[48vh]">
        {messages.map((message, index) => {
          return message.user ? (
            <li className="flex w-full mt-2 space-x-3 " key={index}>
              <div>
                <div className="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                  <p className="text-sm">{message.user}</p>
                </div>
              </div>
            </li>
          ) : (
            <li
              className="flex w-full mt-2 space-x-3  ml-auto justify-end"
              key={index}
            >
              <div>
                <div className="bg-blue-900 text-white p-3 rounded-l-lg rounded-br-lg">
                  <p className="text-sm">{message.ai}</p>
                </div>
              </div>
            </li>
          );
        })}
      </ul>

      <TextInput
        radius="xl"
        size="md"
        placeholder="Type something..."
        className="mt-auto"
        rightSectionWidth={42}
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleButtonClick();
          }
        }}
        rightSection={
          !loading ? (
            <ActionIcon
              size={32}
              radius="xl"
              color="blue"
              className="bg-blue-500 text-white"
              variant="filled"
              onClick={handleButtonClick}
            >
              <IconArrowRight
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </ActionIcon>
          ) : (
            <Loader size={32} color="cyan" type="bars" />
          )
        }
      />
    </Paper>
  );
}
