import { ScrollArea, TextInput, Collapse, Table } from "@mantine/core";
import React, { useEffect, useState } from "react";
import fetchApi from "../../../Lib/api";
import moment from "moment";
import { IconSearch } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";

function UserDetails({ id }) {
  const [details, setDetails] = useState(null);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchApi(
          `/hub/admin/moderation/users/${id}`,
          "GET"
        );
        if (response.ok) {
          const res = await response.json();
          // setUsers(res);
          console.log(res);
          setDetails(res.moderation);
          setTopics(res.posts);
        } else {
          console.error((await response.json()).error);
        }
      } catch (error) {
        console.error("Error during data fetching:", error);
      }
    }
    fetchData();
  }, []);

  const handleModeration = async (state) => {
    try {
      const response = await fetchApi(
        `/hub/admin/moderation/user/${id}`,
        "POST",
        {
          moderation_state: state,
        }
      );
      if (response.ok) {
        const res = await response.json();
        // setUsers(res);
        console.log(res);
        setDetails(res.moderation);
      } else {
        console.error((await response.json()).error);
      }
    } catch (error) {
      console.error("Error during moderating:", error);
    }
  };

  if (!details) return <></>;
  return (
    <div className="flex w-full">
      <div className="w-full md:w-1/3 p-4 border-r border-gray-200">
        {details.created_at && (
          <div className="mb-2">
            <p className="text-sm">
              User since:{" "}
              <span className="font-semibold">
                {moment(details.created_at).format("MMM DD YYYY")}
              </span>
            </p>
          </div>
        )}
        {details.last_seen_at && (
          <div className="mb-2">
            <p className="text-sm">
              Last active:{" "}
              <span className="font-semibold">
                {moment(details.last_seen_at).format("MMM DD YYYY")}
              </span>
            </p>
          </div>
        )}
        <div className="mb-2">
          <p className="text-sm">
            Started topics:{" "}
            <span className="font-semibold">{details.topics_count}</span>
          </p>
        </div>
        <div className="mb-2">
          <p className="text-sm">
            Posts: <span className="font-semibold">{details.posts_count}</span>
          </p>
        </div>
        <div className="flex space-x-2">
          {details.moderation_state === "approved" ? (
            <button
              onClick={() => handleModeration("blocked")}
              className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
            >
              Block
            </button>
          ) : (
            <button
              onClick={() => handleModeration("approved")}
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            >
              Approve
            </button>
          )}
        </div>
      </div>
      <div className="w-full md:w-2/2 p-4">
        <h3 className="text-sm font-semibold mb-2">Recent Posts:</h3>
        <ul className="list-disc list-inside text-sm">
          {topics.map((post, index) => (
            <li key={index}>
              {post.is_topic_starter ? "Started " : "Posted in "}{" "}
              <a
                href={post.postable.url}
                className="text-blue-500 hover:text-blue-600 flex-grow px-4"
              >
                {post.postable.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

const UserRow = ({ user }) => {
  const [opened, { toggle }] = useDisclosure(false);
  return (
    <>
      <tr onClick={toggle} className="hover:bg-gray-100">
        <td className="py-2 px-4 border-b border-gray-200">{user.full_name}</td>
        <td className="py-2 px-4 border-b border-gray-200">
          {user.thredded_user_detail.moderation_state}
        </td>
        <td className="py-2 px-4 border-b border-gray-200">
          {user.thredded_user_detail.moderation_state_changed_at &&
            moment(
              user.thredded_user_detail.moderation_state_changed_at
            ).format("MMM DD YYYY")}
        </td>
      </tr>
      {opened && (
        <tr>
          <td colSpan="3" className="p-4 border-b border-gray-200">
            <Collapse in={opened}>
              <UserDetails id={user.id} />
            </Collapse>
          </td>
        </tr>
      )}
    </>
  );
};

export default function ModerationUsers({}) {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchApi(
          `/hub/admin/moderation/users?q=${search}`,
          "GET"
        );
        if (response.ok) {
          const res = await response.json();
          setUsers(res);
          console.log(res);
        } else {
          console.error((await response.json()).error);
        }
      } catch (error) {
        console.error("Error during data fetching:", error);
      }
    }
    fetchData();
  }, [search]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearch(inputValue);
    }
  };

  return (
    <>
      <ScrollArea>
        <TextInput
          placeholder="Search by any field"
          className="mb-4" // Tailwind class for margin-bottom
          leftSection={<IconSearch className="w-4 h-4" stroke={1.5} />}
          value={inputValue}
          onChange={(event) => setInputValue(event.currentTarget.value)}
          onKeyDown={handleKeyDown}
        />

        <Table
          verticalSpacing="sm"
          className="table-fixed w-full bg-white border border-gray-200 rounded-lg shadow-md"
        >
          <thead>
            <tr className="bg-gray-200">
              <th className="py-2 px-4 border-b border-gray-300 text-left">
                User Name
              </th>
              <th className="py-2 px-4 border-b border-gray-300 text-left">
                Moderation Status
              </th>
              <th className="py-2 px-4 border-b border-gray-300 text-left">
                Created Date
              </th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ? (
              users.map((user, index) => <UserRow key={index} user={user} />)
            ) : (
              <tr>
                <td colSpan={3} className="py-4 text-center font-medium">
                  Nothing found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
    </>
  );
}
