import { Group, Select, Text, TextInput, Title } from "@mantine/core";
import { upperFirst } from "@mantine/hooks";
import React from "react";
import { countriesData } from "./data";

const renderFormField = (fieldName, form, required) => {
  return (
    <TextInput
      required={!required}
      label={upperFirst(fieldName.replace("_", " "))}
      placeholder={fieldName.replace("_", " ")}
      value={form.values[fieldName]}
      onChange={(event) =>
        form.setFieldValue(fieldName, event.currentTarget.value)
      }
      error={form.errors[fieldName]}
      radius="md"
      className="w-full"
    />
  );
};

export default function UserDetails({ form }) {
  return (
    <>
      <Title order={5} className="my-3">
        Personal Information
      </Title>
      <Text size="md" className="my-5">
        Tell us a bit about yourself! Fill in your personal details to enhance
        your experience and allow us to better assist you.
      </Text>
      <Group
        spacing={"md"}
        align="start"
        className="flex-col md:flex-row flex-wrap lg:flex-nowrap  lg:m-3"
      >
        {renderFormField("first_name", form)}

        {renderFormField("last_name", form)}
      </Group>
      {[3].includes(form.values.user_type) ? (
        <Group
          spacing={"md"}
          className="flex-col md:flex-row flex-wrap lg:flex-nowrap  lg:m-3"
          align="start"
        >
          {renderFormField("university", form)}
          {renderFormField("degree", form)}
        </Group>
      ) : (
        <>
          <Group
            spacing={"md"}
            className="flex-col md:flex-row flex-wrap lg:flex-nowrap  lg:m-3"
            align="start"
          >
            {renderFormField("company", form, true)}

            {renderFormField("job_title", form)}
          </Group>
          <Group
            spacing={"md"}
            className="flex-col md:flex-row flex-wrap lg:flex-nowrap  lg:m-3"
            align="start"
          >
            {renderFormField("year_of_experience", form)}

            {renderFormField("university", form)}
          </Group>
        </>
      )}
      <Group
        spacing={"md"}
        className="flex-col md:flex-row flex-wrap lg:flex-nowrap  lg:m-3"
        align="start"
      >
        <Select
          required
          data={countriesData}
          label="Country"
          value={form.values.country}
          onChange={(event) => form.setFieldValue("country", event)}
          error={form.errors.country}
          radius="md"
          searchable
          nothingFound="No options"
          className="w-full"
        />
        {renderFormField("city", form)}
      </Group>
    </>
  );
}
