import {
  Button,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DateTimePicker } from "@mantine/dates";
import React, { useState } from "react";
import fetchApi from "../Lib/api";

export default function Meeting({ canSchedule, slug }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSchedule = async () => {
    try {
      const response = await fetchApi("/meetings", "POST", {
        slug: slug,
        meeting: {
          title,
          description,
          start_time: startTime,
          end_time: endTime,
        },
      });

      if (response.ok) {
        const res = await response.json();
        // setNotification({ type: "success", content: msg });
        console.log(res);
        // window.location.href = `/startup_entities/${res.id}`;
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
    close();
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        size="lg"
        radius="md"
        transitionProps={{ transition: "fade", duration: 200 }}
        withCloseButton={false}
      >
        <Stack spacing={18} className="p-5">
          <Title order={5}>Schedule a Team Meeting</Title>
          <Text size="md">
            Plan your upcoming team meeting by providing the details below.
          </Text>
          <TextInput
            size="md"
            label="Meeting Title"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.currentTarget.value)}
          />
          <Group grow>
            <DateTimePicker
              label="Select Meeting Start Time"
              placeholder="Pick a start date and time"
              valueFormat="ddd MMM YYYY hh:mm A"
              size="md"
              clearable
              minDate={new Date()}
              onChange={(value) => setStartTime(value)}
            />
            <DateTimePicker
              label="Select Meeting End Time"
              placeholder="Pick an end date and time"
              valueFormat="ddd MMM YYYY hh:mm A"
              size="md"
              clearable
              minDate={startTime || new Date()}
              onChange={(value) => setEndTime(value)}
            />
          </Group>
          <Textarea
            size="md"
            radius="md"
            label="Meeting Agenda"
            placeholder="Briefly describe the agenda for the team meeting..."
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
          <Button
            onClick={handleSchedule}
            variant="default"
            fullWidth
            className="w-full px-12"
            disabled={!(title && description && startTime && endTime)}
          >
            {canSchedule ? "Schedule Team Meeting" : "Request a Meeting"}
          </Button>
        </Stack>
      </Modal>
      <Button
        onClick={open}
        variant="default"
        fullWidth
        className="w-fit px-12"
      >
        {canSchedule ? "Schedule Team Meeting" : "Request a Meeting"}
      </Button>
    </>
  );
}
