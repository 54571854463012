import { Slider, rem } from "@mantine/core";
import { IconChecks } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";

const ProgressBar = ({ categories }) => {
  const [needlePosition, setNeedlePosition] = useState(0);

  useEffect(() => {
    let totalSubCategories = 0;
    let filledSubCategories = 0;

    categories?.forEach((category) => {
      totalSubCategories += category.sub_categories.length;
      filledSubCategories += category.sub_categories.filter(
        (subCat) => subCat.response
      ).length;
    });

    // Calculate needle position
    const newPosition = (filledSubCategories / totalSubCategories) * 100;
    setNeedlePosition(newPosition);
  }, [categories]);

  return (
    <Slider
      className="lg:w-2/3 mx-auto"
      thumbChildren={<IconChecks size="1rem" />}
      color="indigo"
      marks={[
        { value: 20, label: "20%" },
        { value: 50, label: "50%" },
        { value: 80, label: "80%" },
      ]}
      value={needlePosition}
      label={null}
      thumbSize={26}
      styles={{ thumb: { borderWidth: rem(2), padding: rem(3) } }}
      mb={28}
    />
  );
};

export default ProgressBar;
