// utils/api.js

const csrfToken = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute("content");

const defaultHeaders = (formData) => {
  return formData
    ? { "X-CSRF-Token": csrfToken, Accept: "application/json" }
    : {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": csrfToken,
      };
};

const fetchBody = (body, formData) => {
  return formData ? body : JSON.stringify(body);
};

const fetchApi = async (
  url,
  method = "GET",
  body = null,
  formData,
  customHeaders = {}
) => {
  const headers = { ...defaultHeaders(formData), ...customHeaders };

  const options = {
    method,
    headers,
    body: body ? fetchBody(body, formData) : null,
  };

  try {
    const response = await fetch(url, options);
    return response;
  } catch (error) {
    console.error("Fetch error:", error.message);
    throw new Error("Failed to fetch data");
  }
};

export default fetchApi;
