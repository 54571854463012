import React from "react";
import { Button } from "@mantine/core";

const handleSignIn = (provider) => {
  const railsButton = document.getElementById(`oauth-sign-in-linkedin`);
  if (railsButton) {
    railsButton.click();
  }
};

function LinkedInIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      style={{ width: "0.9rem", height: "0.9rem" }}
    >
      <path d="M19 0H5C2.239 0 0 2.239 0 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5V5c0-2.761-2.239-5-5-5zM7.653 20.431H4.315V9h3.338v11.431zM5.984 7.75a1.936 1.936 0 1 1-.001-3.872 1.936 1.936 0 0 1 0 3.872zM20.431 20.431h-3.338v-5.956c0-1.419-.025-3.244-1.975-3.244-1.978 0-2.281 1.546-2.281 3.141v6.059h-3.338V9h3.207v1.564h.046c.447-.847 1.539-1.739 3.169-1.739 3.391 0 4.016 2.231 4.016 5.132v6.474z" />
    </svg>
  );
}

export function LinkedInButton(props) {
  return (
    <Button
      leftIcon={<LinkedInIcon />}
      variant="default"
      {...props}
      onClick={handleSignIn}
    />
  );
}
