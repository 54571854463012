function validations(active) {
  if (active === 0) {
    return {
      user_type: (value) => {
        if (value === null) {
          return "User type is required";
        }
        return null;
      },
    };
  } else if (active === 1) {
    return {
      first_name: (value) => {
        if (!value) {
          return "First name is required";
        }
        return null;
      },

      last_name: (value) => {
        if (!value) {
          return "Last name is required";
        }
        return null;
      },

      // company: (value, values) => {
      //   if (![2, 3].includes(values.user_type) && !value) {
      //     return "Company is required";
      //   }
      //   return null;
      // },

      job_title: (value, values) => {
        if (![2, 3].includes(values.user_type) && !value) {
          return "Job title is required";
        }
        return null;
      },

      year_of_experience: (value) => {
        if (value < 0) {
          return "Year of experience should be non-negative";
        }
        return null;
      },

      // university: (value) => {
      //   if (!value) {
      //     return "University is required";
      //   }
      //   return null;
      // },

      // degree: (value, values) => {
      //   if ([2, 3].includes(values.user_type) && !value) {
      //     return "Degree is required for students";
      //   }
      //   return null;
      // },

      country: (value) => {
        if (!value) {
          return "Country is required";
        }
        return null;
      },

      city: (value) => {
        if (!value) {
          return "City is required";
        }
        return null;
      },
    };
  } else {
    return {
      about_me: (value) => {
        if (!value) {
          return "About me is required";
        }
        return null;
      },

      expert_in: (value) => {
        if (value.length === 0) {
          return "Expertise is required";
        }
        return null;
      },

      skills: (value) => {
        if (value.length === 0) {
          return "Skills are required";
        }
        return null;
      },

      industries: (value) => {
        if (value.length === 0) {
          return "Industries are required";
        }
        return null;
      },
    };
  }
}

export { validations };
