import React, { useContext, useRef, useState } from "react";
import {
  Avatar,
  Text,
  Group,
  Button,
  Stack,
  Indicator,
  Tooltip,
} from "@mantine/core";
import { UserContext } from "../Lib/UserContext";
import { IconMapPin, IconPencil } from "@tabler/icons-react";
import { onSubmit } from "../Onboarding/Onboarding";
import fetchApi from "../Lib/api";

export function UserInfoIcons({ isEditMode, setIsEditMode, form }) {
  const fileInputRef = useRef(null);
  const [userImage, setUserImage] = useState(null);
  const {
    user: { user },
    setUser,
  } = useContext(UserContext);

  const handleFileChange = async (event) => {
    // Handle file changes here
    const selectedFile = event.target.files[0];
    console.log(selectedFile);
    setUserImage(URL.createObjectURL(selectedFile));

    try {
      const formData = new FormData();
      formData.append(`user[avatar]`, selectedFile);
      const response = await fetchApi(
        `/api/users/update_image`,
        "PUT",
        formData,
        true
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("Success:", responseData);
      } else {
        console.error("HTTP error! Failed to submit form");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  return (
    <div>
      <Stack wrap="nowrap" className="w-[200px]">
        <Indicator
          className="w-fit mx-auto cursor-pointer"
          position="bottom-end"
          label={<IconPencil size={20} />}
          size={30}
          onClick={() => fileInputRef.current.click()}
        >
          <Avatar
            src={userImage || user?.avatar?.url}
            size={144}
            radius="md"
            mx="auto"
            onClick={() => fileInputRef.current.click()}
          />
        </Indicator>
        <div className="flex flex-col items-center w-[200px]">
          <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
            {user.job_title}
          </Text>

          <Text fz="xl" fw={500} className="font-greycliff font-sans">
            {user.first_name} {user.last_name}
          </Text>

          <Group wrap="nowrap" gap={0} mt={5}>
            <IconMapPin stroke={1.5} size="1rem" className="text-gray-500" />
            <Text fz="sm" c="dimmed">
              {user.address.country} {user.address.city}
            </Text>
          </Group>

          <Group wrap="nowrap" gap={10} mt={6}>
            {!isEditMode ? (
              <Button
                onClick={() => setIsEditMode(true)}
                variant="default"
                fullWidth
                className="w-fit px-12"
              >
                Edit Profile
              </Button>
            ) : (
              <Stack spacing={2}>
                <Button
                  onClick={() => {
                    if (onSubmit(form.values, setUser)) setIsEditMode(false);
                  }}
                  variant="default"
                  fullWidth
                  className="w-[180px] px-12"
                >
                  Save
                </Button>
                <Button
                  onClick={() => setIsEditMode(false)}
                  variant="default"
                  fullWidth
                  className="text-red-700 px-12"
                >
                  Cancel
                </Button>
              </Stack>
            )}
          </Group>
        </div>
      </Stack>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
}
