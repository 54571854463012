import React, { useState } from "react";
import { useForm } from "@mantine/form";
import { PasswordInput, Button, Group, Stack } from "@mantine/core";
import fetchApi from "../Lib/api";
import Notification from "../Lib/Notification";

export default function UpdatePasswordForm() {
  const [notification, setNotification] = useState(null);

  const form = useForm({
    initialValues: {
      old_password: "",
      password: "",
      password_confirmation: "",
    },
    validate: {
      old_password: (val) =>
        val.length >= 6 ? null : "Old password can't be less than 6",
      password: (val) =>
        val.length >= 6
          ? null
          : "Password should include at least 6 characters",
      password_confirmation: (val) =>
        val === form.values.password ? null : "Passwords do not match",
    },
  });

  const handleSubmit = async (e) => {
    try {
      const response = await fetchApi("/api/users/update_password", "PUT", {
        user: {
          new_password: form.values.password,
          old_password: form.values.old_password,
        },
      });

      if (response.ok) {
        const msg = (await response.json()).message;
        setNotification({ type: "success", content: msg });
        console.log("Password change successful!");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Password change failed.");
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  return (
    <form
      onSubmit={form.onSubmit(handleSubmit)}
      className="mx-auto lg:w-2/5 lg:mt-5"
    >
      <Stack>
        {notification && <Notification {...notification} />}

        {["old_password", "password", "password_confirmation"].map(
          (fieldName) => (
            <React.Fragment key={fieldName}>
              <PasswordInput
                required
                size="md"
                label={
                  fieldName === "old_password"
                    ? "Old Password"
                    : fieldName === "password"
                    ? "New Password"
                    : "Confirm New Password"
                }
                placeholder={`Your ${fieldName.replace("_", " ")} ${
                  fieldName === "old_password" ? "" : "8 chars long"
                }`}
                value={form.values[fieldName]}
                onChange={(event) =>
                  form.setFieldValue(fieldName, event.currentTarget.value)
                }
                error={form.errors[fieldName]}
                radius="md"
              />
            </React.Fragment>
          )
        )}

        <Group position="center">
          <Button
            type="submit"
            variant="default"
            fullWidth
            className="w-fit px-12"
          >
            Change Password
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
