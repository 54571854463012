import {
  Avatar,
  Button,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import fetchApi from "../Lib/api";
import { useParams } from "react-router-dom";
import Notification from "../Lib/Notification";

export default function StartupConfirmation() {
  const { token } = useParams();
  const [data, setData] = useState(null);
  const [notification, setNotification] = useState(null);

  const fetchData = async (accept = false) => {
    try {
      const response = await fetchApi(
        `/invitations/${accept ? "accept" : "confirm_invitation"}/${token}`,
        "GET"
      );

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        if (!accept) setData(res);
        else window.location.href = `/startup_entities/${res.slug}`;
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.log(msg);
      }
    } catch (error) {
      console.error("Error during invitation confirmation:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!data) return <></>;

  return (
    <main className="flex justify-center ">
      <div className="max-w-[900px] w-full px-6 py-12">
        <Paper radius="md" withBorder p="xl">
          {notification && <Notification {...notification} />}
          <Stack spacing={50} className="text-center m-24">
            <Avatar
              src={data.inviter_id.avatar}
              size={144}
              radius="md"
              mx="auto"
            />
            <div>
              <Text size={24}>
                You've been invited to the <b>{data.startup_entity.name}</b>{" "}
                startup
              </Text>
              <Text>
                Invited by {data.inviter_id.first_name}{" "}
                {data.inviter_id.last_name}
              </Text>
            </div>
            <Group noWrap>
              <Button
                onClick={() => fetchData(true)}
                variant="default"
                fullWidth
                className="px-12 h-12"
              >
                Accept
              </Button>
              <Button
                onClick={() => (window.location.href = "/dashboard")}
                variant="default"
                fullWidth
                className="text-red-700 px-12 h-12"
              >
                Decline
              </Button>
            </Group>
          </Stack>
        </Paper>
      </div>
    </main>
  );
}
