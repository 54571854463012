import React from "react";
import { useForm } from "@mantine/form";
import {
  PasswordInput,
  Button,
  Group,
  Stack,
  Paper,
  Title,
  Divider,
  Container,
  TextInput,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import fetchApi from "../Lib/api";

const CreatePasswordForInviteUser = () => {
  const invitation_token = new URLSearchParams(window.location.search).get(
    "invitation_token"
  );
  console.log(invitation_token);
  const form = useForm({
    initialValues: {
      first_name: "",
      last_name: "",
      password: "",
      password_confirmation: "",
    },
    validate: {
      password: (val) =>
        val.length >= 6
          ? null
          : "Password should include at least 6 characters",
      password_confirmation: (val) =>
        val === form.values.password ? null : "Passwords do not match",
      first_name: (val) =>
        val.trim() !== "" ? null : "First name is required",
      last_name: (val) => (val.trim() !== "" ? null : "Last name is required"),
    },
  });

  const handleSubmit = async (e) => {
    try {
      const response = await fetchApi("/users/invitation", "PUT", {
        invitation: {
          invitation_token: invitation_token,
          password: form.values.password,
          password_confirmation: form.values.password_confirmation,
          last_name: form.values.last_name,
          first_name: form.values.first_name,
        },
      });

      if (response.ok) {
        // Handle password change success
        const url = (await response.json()).url;
        window.location.href = url;
      } else {
        // Handle password change failure
        console.error("Password change failed.");
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  return (
    <Container size={660} my={30}>
      <Paper
        radius="md"
        p="lg"
        shadow="sm"
        withBorder
        //   {...props}
        className="py-20 m-auto max-w-lg xl:max-w-xl md:px-16"
      >
        <Title order={1} ta="center">
          Set your password
        </Title>

        <Divider mb="xl" mt="xs" variant="dotted" />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <Group grow align="start">
              <TextInput
                required
                label="First Name"
                placeholder="Your first name"
                name="first_name"
                value={form.values.first_name}
                onChange={(event) =>
                  form.setFieldValue("first_name", event.currentTarget.value)
                }
                error={form.errors.first_name}
                radius="md"
              />
              <TextInput
                required
                label="Last Name"
                placeholder="Your last name"
                name="last_name"
                value={form.values.last_name || ""}
                onChange={(event) =>
                  form.setFieldValue("last_name", event.currentTarget.value)
                }
                error={form.errors.last_name}
                radius="md"
              />
            </Group>
            <Group grow align="start">
              <PasswordInput
                required
                label="New Password"
                placeholder="Your new password"
                name="password"
                value={form.values.password}
                onChange={(event) =>
                  form.setFieldValue("password", event.currentTarget.value)
                }
                error={form.errors.password}
                radius="md"
              />

              <PasswordInput
                required
                label="Confirm New Password"
                placeholder="Confirm your new password"
                value={form.values.password_confirmation}
                name="password_confirmation"
                onChange={(event) =>
                  form.setFieldValue(
                    "password_confirmation",
                    event.currentTarget.value
                  )
                }
                error={form.errors.password_confirmation}
                radius="md"
                fullWidth
              />
            </Group>

            <Group position="center">
              <Button
                type="submit"
                radius="xl"
                style={{ backgroundColor: "rgb(55, 96, 146)" }}
              >
                Set Password
              </Button>
            </Group>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};

export default CreatePasswordForInviteUser;
