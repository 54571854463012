import { Button, Menu, Select, Stack } from "@mantine/core";
import { IconPackageExport } from "@tabler/icons-react";
import React, { useState } from "react";

export default function RoadmapPDFGenerator({ slug }) {
  const [opened, setOpened] = useState(false);
  const [format, setFormat] = useState("pdf");
  const [loading, setLoading] = useState(false);
  const fetchPDF = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/startup_entities/${slug}/roadmap_pdf`, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "roadmap.pdf"; // Customize the file name if needed
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);

        // Optionally set a success notification
        // setNotification({ type: "success", content: "PDF downloaded successfully" });
      } else {
        const msg = (await response.json()).error;
        // Optionally set a failure notification
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during PDF fetch:", error);
      // Optionally set a failure notification
      // setNotification({ type: "failure", content: "An error occurred while fetching the PDF" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Menu shadow="md" opened={opened} onChange={setOpened}>
      <Menu.Target>
        <Button
          variant="default"
          className="w-fit"
          loading={loading}
          compact
          leftIcon={<IconPackageExport size="1rem" />}
        >
          Export
        </Button>
      </Menu.Target>
      <Menu.Dropdown p={20} className="max-w-80">
        <Stack>
          <Select
            label="Download Format"
            onChange={setFormat}
            value={format}
            data={[{ value: "pdf", label: "PDF Format" }]}
          />
          <Button
            disabled={loading}
            variant="default"
            fullWidth
            onClick={() => {
              fetchPDF();
              setOpened(false);
            }}
          >
            Download
          </Button>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
}
