import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Alert,
  Anchor,
  Avatar,
  Button,
  Card,
  Collapse,
  Grid,
  Group,
  Indicator,
  List,
  Loader,
  Menu,
  Paper,
  Radio,
  Select,
  Stack,
  Tabs,
  Text,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import { useDisclosure, useToggle } from "@mantine/hooks";
import {
  IconAlertCircle,
  IconArrowRight,
  IconChecks,
  IconChevronUp,
  IconEye,
  IconMessages,
  IconPencil,
  IconThumbUp,
  IconThumbDown,
  IconChevronDown,
  IconEyeOff,
  IconCalendarTime,
} from "@tabler/icons-react";

import fetchApi from "../Lib/api";
import { useParams } from "react-router-dom";
import { InviteMembers } from "./InviteMember";
import Meeting from "./Meeting";
import TimelineList from "./Timeline";
import { IconArrowLeft } from "@tabler/icons-react";
import ProgramChangesApproval from "./ProgramUpdateApproval";
import ManageRequestToJoinTeam from "./ManageRequestToJoinTeam";
import Funding from "./Funding";
import ContactMessage from "./ContactMessage";
import ChatComponent from "./StartupChat";
import OpeningRoles from "./OpeningRoles";
import Markdown from "../Lib/Markdown";
import ProgressBar from "./Stat";
import HeaderAndSidebar from "../Lib/HeaderAndSidebar";
import { DatePicker } from "@mantine/dates";
import moment from "moment";

const MemoStartupShow = memo(StartupShow);
const MemoTimelineList = memo(
  TimelineList,
  (prevProps, nextProps) =>
    prevProps.programs === nextProps.programs &&
    prevProps.programs.roadmap_duration ===
      nextProps.programs.roadmap_duration &&
    prevProps.opened === nextProps.opened &&
    prevProps.startActivity === nextProps.startActivity &&
    prevProps.selectedPrograms.cid === nextProps.selectedPrograms.cid
);
const MemoContactMessage = memo(ContactMessage);
const MemoChatComponent = memo(ChatComponent);

export default function StartupContainer({ project }) {
  return (
    <HeaderAndSidebar sidebar={<></>}>
      <MemoStartupShow project={project} />
    </HeaderAndSidebar>
  );
}

export const handleFollowUnfollow = async (isFollowing, slug, setStartup) => {
  try {
    const endpoint = !isFollowing
      ? `/startup_entities/${slug}/followings`
      : `/startup_entities/${slug}/followings/${slug}`;
    const method = !isFollowing ? "POST" : "DELETE";
    const response = await fetchApi(endpoint, method);

    if (response.ok) {
      const res = await response.json();
      if (setStartup) setStartup((p) => ({ ...p, is_following: !isFollowing }));
    } else {
      const { error } = await response.json();
      console.error(error);
    }
  } catch (error) {
    console.error("Error during follow/unfollow:", error);
  }

  if (!setStartup) return !isFollowing;
};

function StartupShow({ project }) {
  const { slug } = useParams();
  const fileInputRef = useRef(null);
  const [startup, setStartup] = useState(null);
  const [program, setProgram] = useState(null);
  const [startupImage, setStartupImage] = useState(null);
  const [selectedPrograms, setSelectedPrograms] = useState({
    switch: false,
    cid: 0,
    sid: 0,
  });
  const [opened, { open, close }] = useDisclosure(false);
  const [startActivity, setStartActivity] = useState(false);
  const [showFunding, setShowFunding] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    // Function to log time spent on the page
    const logTime = async () => {
      const endTime = Date.now();
      const timeSpentInMinutes = (endTime - startTime) / 1000 / 60;

      try {
        const response = await fetchApi(
          `/startup_entities/${slug}/log_time`,
          "POST",
          {
            time_spent: timeSpentInMinutes, // Corrected variable name here
          }
        );

        if (response.ok) {
          const res = await response.json();
          console.log("Time logged successfully", res);
        } else {
          const msg = (await response.json()).error;
          console.error("Failed to log time:", msg);
        }
      } catch (error) {
        console.error("Error logging time:", error);
      }
    };

    // Set event to log time on unload
    window.addEventListener("beforeunload", logTime);

    return () => {
      // Cleanup function to remove event listener and log time if the component unmounts before page unload
      window.removeEventListener("beforeunload", logTime);
      logTime(); // Call log time manually on component unmount
    };
  }, [slug, startTime]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/startup_entities/${slug}`, "GET");

        if (response.ok) {
          const res = await response.json();
          // setNotification({ type: "success", content: msg });
          console.log(res);
          setStartup(res);
          setProgram(res.startup_entity.program);
          // window.location.href = "/startup_entities/1";
        } else {
          const msg = (await response.json()).error;
          // setNotification({ type: "failure", content: msg });
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during password change:", error);
      }
    };

    fetchData();
  }, []);

  const handleImageClick = () => {
    if (startup.is_member || startup.is_owner) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    // Handle file changes here
    const selectedFile = event.target.files[0];
    console.log(selectedFile);
    setStartupImage(URL.createObjectURL(selectedFile));

    try {
      const formData = new FormData();
      formData.append(`startup_entity[image]`, selectedFile);
      const response = await fetchApi(
        `/startup_entities/${slug}`,
        "PATCH",
        formData,
        true
      );

      if (response.ok) {
        console.log("Success:", response.user);

        const responseData = await response.json();
        console.log("Success:", responseData);
      } else {
        console.error("HTTP error! Failed to submit form");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  if (!startup)
    return (
      <Loader
        className="mx-auto mt-10"
        color="indigo"
        size="lg"
        variant="dots"
      />
    );

  return (
    <main className="flex justify-center min-h-screen">
      <div className=" w-full lg:p-6 max-w-[90rem]">
        <Anchor onClick={() => window.history.back()}>
          <Group className="mb-2" spacing={4}>
            <IconArrowLeft size={18} color="#1c7ed6" />
            Go back
          </Group>
        </Anchor>

        <Group align="end" className="mb-5">
          <Title order={2} className="text-gray-700 ">
            {startup.startup_entity?.name}
          </Title>
          {(startup.is_owner || startup.role < 1) && (
            <>
              <ActionIcon
                component="a"
                href={`/startup_entities/${slug}/edit`}
                size={42}
                variant="default"
                aria-label="Edit page link"
              >
                <IconPencil style={{ width: rem(24), height: rem(24) }} />
              </ActionIcon>
              <StartupPrivacy startup={startup} setStartup={setStartup} />
            </>
          )}
        </Group>

        <ProgressBar categories={program?.categories} />

        <Grid>
          <Grid.Col lg={8} order={1} orderSm={2} orderLg={1}>
            {!selectedPrograms.switch && (
              <Stack spacing={28} className="lg:px-10">
                <div>
                  <Group align="center" spacing={30} className="lg:flex-nowrap">
                    <Indicator
                      label={<IconPencil size={20} />}
                      className="h-auto w-full rounded-lg cursor-pointer"
                      size={30}
                      onClick={handleImageClick}
                      disabled={!(startup.is_member || startup.is_owner)}
                    >
                      <img
                        className="h-auto rounded-lg"
                        src={
                          startupImage ||
                          startup.startup_entity?.image?.url ||
                          "https://flowbite.com/docs/images/examples/image-3@2x.jpg"
                        }
                        alt="image description"
                        onClick={handleImageClick}
                      />
                    </Indicator>

                    <Group className="overflow-x-scroll flex-nowrap lg:overflow-x-hidden lg:flex-wrap">
                      <Stack spacing={20}>
                        <div>
                          <Text fz="lg" fw={700}>
                            {startup.startup_entity.country}{" "}
                            {startup.startup_entity.city}
                          </Text>
                          <Text fz="xs" c="dimmed">
                            Location
                          </Text>
                        </div>
                        <div>
                          <Text fz="lg" fw={700}>
                            {startup.startup_entity.stage ||
                              startup.startup_entity.project_type}
                          </Text>
                          <Text fz="xs" c="dimmed">
                            {project ? "Project type" : "Stage"}
                          </Text>
                        </div>
                        <div>
                          <Text fz="lg" fw={700}>
                            {startup.startup_entity.industry}
                          </Text>
                          <Text fz="xs" c="dimmed">
                            Industry
                          </Text>
                        </div>
                      </Stack>

                      <Stack spacing={20}>
                        <Button
                          onClick={() =>
                            handleFollowUnfollow(
                              startup.is_following,
                              startup.startup_entity.slug,
                              setStartup
                            )
                          }
                          leftIcon={
                            startup.is_following ? (
                              <IconThumbDown size="1rem" />
                            ) : (
                              <IconThumbUp size="1rem" />
                            )
                          }
                          variant="default"
                          fullWidth
                          className="w-full px-12"
                        >
                          {startup.is_following ? "Unfollow" : "Follow"}
                        </Button>

                        <MemoContactMessage
                          slug={startup.startup_entity.slug}
                          isMember={startup.is_owner || startup.is_member}
                          role={startup.role}
                        />

                        {(startup.is_owner || startup.role < 1) && (
                          <Funding
                            slug={startup.startup_entity.slug}
                            funding={startup.startup_entity.funding}
                            setStartup={setStartup}
                          />
                        )}
                      </Stack>
                    </Group>
                  </Group>
                  {startup.startup_entity.funding && (
                    <Alert
                      variant="light"
                      className="bg-white"
                      icon={<IconAlertCircle />}
                      p={10}
                      mt={10}
                    >
                      <Stack>
                        <Group position="apart" mr={10}>
                          {startup.startup_entity.funding && (
                            <Text className="px-2 text-base text-gray-600">
                              Ask for ${startup.startup_entity.funding.ask} for
                              a {startup.startup_entity.funding.equity}% equity
                              stake at a valuation of $
                              {startup.startup_entity.funding.valuation}.
                            </Text>
                          )}

                          <p className="">
                            <button
                              onClick={() => setShowFunding((p) => !p)}
                              type="button"
                              className=" inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                            >
                              <span>See {false ? "less" : "more"} details</span>
                              {showFunding ? (
                                <IconChevronUp
                                  style={{ width: rem(18), height: rem(18) }}
                                />
                              ) : (
                                <IconChevronDown
                                  style={{ width: rem(18), height: rem(18) }}
                                />
                              )}
                            </button>
                          </p>
                        </Group>

                        <Collapse in={showFunding}>
                          <Markdown
                            value={
                              startup.startup_entity.funding.investors_details
                            }
                            visibleDragbar={false}
                            preview={"preview"}
                            hideToolbar={true}
                          />
                        </Collapse>
                      </Stack>
                    </Alert>
                  )}
                </div>

                <Paper radius="md" withBorder p="md">
                  <Stack spacing={20}>
                    <Group position="apart">
                      <Title order={4} size="h4" fw={700}>
                        Team
                      </Title>
                      <Group>
                        <ManageRequestToJoinTeam
                          slug={startup.startup_entity.slug}
                          entity={startup.startup_entity.entity_type}
                          setStartup={setStartup}
                          canManage={startup.is_owner || startup.role < 1}
                          isMember={startup.is_member}
                          isOwner={startup.is_owner}
                        />

                        {startup.is_member && (
                          <Button
                            onClick={() => setOpenChat((p) => !p)}
                            variant="default"
                            fullWidth
                            className="w-fit"
                            leftIcon={<IconMessages size="1rem" />}
                          >
                            {openChat ? "Close" : "Open"} chat
                          </Button>
                        )}
                      </Group>
                    </Group>
                    <Group
                      spacing={10}
                      align="start"
                      className="overflow-x-scroll lg:overflow-x-hidden flex-nowrap"
                    >
                      {startup.startup_entity.members.map((member) => {
                        return (
                          <Anchor
                            key={member.id}
                            href={`/profile/user/${member.id}`}
                            className="text-gray-500"
                          >
                            <Stack
                              spacing={0}
                              ml={12}
                              key={member.id}
                              align="center"
                            >
                              <Avatar
                                size={45}
                                src={
                                  member.avatar_url ||
                                  "https://placehold.co/600x400?text=No Image"
                                }
                                radius={40}
                              />
                              <div>
                                <Text fz="sm" fw={500}>
                                  {member.first_name}
                                </Text>
                              </div>
                            </Stack>
                          </Anchor>
                        );
                      })}
                    </Group>
                    <Group grow>
                      <InviteMembers
                        canInvite={startup.is_owner || startup.is_member}
                        role={startup.role < 2 || startup.is_owner}
                        startup={startup}
                      />
                      <Meeting
                        canSchedule={startup.is_owner || startup.is_member}
                        slug={startup.startup_entity.slug}
                      />
                    </Group>
                  </Stack>
                </Paper>
                <Paper radius="md" withBorder p="md">
                  <Title order={4} size="h4" fw={700}>
                    Description
                  </Title>
                  <Text className="mt-2 px-2 text-base text-gray-600">
                    {startup.startup_entity.description}
                  </Text>
                </Paper>
              </Stack>
            )}

            {program && (
              <div className="lg:px-10 mt-8">
                {selectedPrograms.switch && (
                  <Group className="mb-2" spacing={4}>
                    <IconArrowLeft size={18} color="#1c7ed6" />
                    <Anchor
                      onClick={() =>
                        setSelectedPrograms((p) => ({ ...p, switch: false }))
                      }
                      target="_blank"
                    >
                      Startup page
                    </Anchor>
                  </Group>
                )}
                <DisplayPrograms
                  startup={startup}
                  program={program}
                  selectedPrograms={selectedPrograms}
                  setSelectedPrograms={setSelectedPrograms}
                  setProgram={setProgram}
                  open={open}
                  setStartActivity={setStartActivity}
                />
              </div>
            )}

            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Grid.Col>
          <Grid.Col lg={4} order={1} orderSm={1} orderLg={2}>
            <Stack>
              <div>
                {program ? (
                  <MemoTimelineList
                    canEdit={startup.is_owner || startup.is_member}
                    role={startup.role}
                    owner={startup.is_owner}
                    member={startup.is_member}
                    members={startup.startup_entity.members}
                    slug={slug}
                    programs={program}
                    setProgram={setProgram}
                    setStartup={setStartup}
                    selectedPrograms={selectedPrograms}
                    setSelectedPrograms={setSelectedPrograms}
                    open={open}
                    opened={opened}
                    close={close}
                    startActivity={startActivity}
                    setStartActivity={setStartActivity}
                  >
                    {(startup.is_owner || !startup.role) &&
                    startup.startup_entity.entity_type !== "Project" ? (
                      <RoadmapCompletionDuration
                        slug={startup.startup_entity.slug}
                        setProgram={setProgram}
                        programs={program}
                      />
                    ) : (
                      <></>
                    )}
                  </MemoTimelineList>
                ) : (
                  <>
                    {startup.is_owner || !startup.role ? (
                      <Stack>
                        <RoadmapCompletionDuration
                          slug={startup.startup_entity.slug}
                          setProgram={setProgram}
                          programs={program}
                        />
                        <Alert
                          icon={<IconAlertCircle size="1rem" />}
                          title="Program Duration"
                          color="indigo"
                        >
                          Please select a target duration for completing the
                          program. We will create a personalized roadmap
                          tailored to the duration you specify.
                        </Alert>
                      </Stack>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
              <OpeningRoles
                canAddRole={startup.is_owner || startup.role < 2}
                id={startup.startup_entity.slug}
                openings={startup.startup_entity.openings}
                setStartup={setStartup}
              />
            </Stack>
          </Grid.Col>
        </Grid>
      </div>

      {openChat && (
        <MemoChatComponent
          startupId={startup.startup_entity.slug}
          setOpenChat={setOpenChat}
        />
      )}
    </main>
  );
}

const DisplayPrograms = ({
  startup,
  program,
  selectedPrograms,
  open,
  setStartActivity,
  setProgram,
  setSelectedPrograms,
}) => {
  const handleCardLeftClick = useCallback(() => {
    if (program?.categories[selectedPrograms.cid - 1])
      setSelectedPrograms((p) => ({
        ...p,
        cid: p.cid - 1,
        sid: 0,
      }));
  }, [startup, selectedPrograms, setSelectedPrograms]);

  const handleCardRightClick = useCallback(() => {
    if (program?.categories[selectedPrograms.cid + 1])
      setSelectedPrograms((p) => ({ ...p, cid: p.cid + 1, sid: 0 }));
  }, [program, selectedPrograms, setSelectedPrograms]);

  return (
    <Paper radius="md" withBorder py="md" px="sm" className="h-auto">
      {(startup.is_owner || startup.is_member) && (
        <Group position="apart" align="start">
          <Title order={4} className="text-gray-900">
            {program?.categories[selectedPrograms.cid].name}
          </Title>
          <Tooltip.Group openDelay={300} closeDelay={100}>
            <Avatar.Group spacing="sm">
              {program?.categories[selectedPrograms.cid].assigned_users.map(
                ({ user }) => (
                  <Tooltip
                    key={user.id}
                    label={`${user.first_name} ${user.last_name}`}
                    withArrow
                  >
                    <Avatar src={user.avatar_url} radius="xl" size={30} />
                  </Tooltip>
                )
              )}
            </Avatar.Group>
          </Tooltip.Group>
          <Button
            onClick={() => {
              setStartActivity(true);
              open();
            }}
            variant="default"
            fullWidth
            className="w-fit"
            disabled={
              !program?.categories[selectedPrograms.cid].is_active ||
              program?.categories[selectedPrograms.cid].sub_categories[
                selectedPrograms.sid
              ].waiting_for_approval
            }
          >
            {selectedPrograms.edit ? "Edit " : "Create "}
            {program?.categories[selectedPrograms.cid].name}
          </Button>
        </Group>
      )}

      <Stack>
        <StyledTabs
          variant="pills"
          className="w-full"
          key={`${
            !selectedPrograms.switch
              ? ""
              : program?.categories[selectedPrograms.cid].sub_categories[
                  selectedPrograms.sid
                ].name
          }`}
          defaultValue={
            program?.categories[selectedPrograms.cid].sub_categories[
              selectedPrograms.sid
            ].name
          }
        >
          <Tabs.List pr={10} pt={10} grow>
            {program?.categories[selectedPrograms.cid].sub_categories.map(
              (tab, i) => {
                // if (tab.response || startup.is_member)
                if (
                  tab.is_active &&
                  (startup.is_owner ||
                    (tab.privacy !== "Only me" && startup.is_member) ||
                    (tab.privacy === "Everyone" && tab.response))
                )
                  return (
                    <Tabs.Tab
                      icon={tab.response ? <IconChecks /> : <></>}
                      key={tab.name}
                      value={tab.name}
                      className="font-bold"
                      onClick={() =>
                        setSelectedPrograms((p) => ({ ...p, sid: i }))
                      }
                    >
                      {tab.name}
                    </Tabs.Tab>
                  );
                else return <></>;
              }
            )}
          </Tabs.List>
          {program?.categories[selectedPrograms.cid].sub_categories.map(
            (tab, index) => {
              if (
                tab.is_active &&
                (startup.is_owner ||
                  (tab.privacy !== "Only me" && startup.is_member) ||
                  (tab.privacy === "Everyone" && tab.response))
              )
                return (
                  <Tabs.Panel key={tab.id} value={tab.name}>
                    {tab.start_day && tab.end_day && (
                      <div className="text-sm text-gray-500 flex space-x-4 mt-3 w-full">
                        <div className="mx-auto">
                          <span>
                            Start Date:
                            <span className="font-medium">{tab.start_day}</span>
                          </span>
                          <span className="ml-4">
                            End Date:
                            <span className="font-medium">{tab.end_day}</span>
                          </span>
                        </div>
                      </div>
                    )}

                    <ShowProgramCategory
                      tab={tab}
                      index={index}
                      startup={startup}
                      program={program}
                      setProgram={setProgram}
                      selectedPrograms={selectedPrograms}
                    />
                  </Tabs.Panel>
                );
              else return <></>;
            }
          )}
        </StyledTabs>

        <Group position="apart" className=" mt-5" grow>
          {program?.categories[selectedPrograms.cid - 1] && (
            <Card
              withBorder
              className="hover:bg-gray-100 cursor-pointer self-end max-w-lg mr-auto"
              onClick={handleCardLeftClick}
            >
              <Group position="apart">
                <IconArrowLeft />
                <Stack spacing={0}>
                  <Title order={5} className=" text-gray-600">
                    {program?.categories[selectedPrograms.cid - 1].name}
                  </Title>
                </Stack>
              </Group>
            </Card>
          )}

          {program?.categories[selectedPrograms.cid + 1] && (
            <Card
              withBorder
              className="hover:bg-gray-100 cursor-pointer self-end max-w-lg ml-auto"
              onClick={handleCardRightClick}
            >
              <Group position="apart">
                <Stack spacing={0}>
                  <Title order={5} className=" text-gray-600">
                    {program?.categories[selectedPrograms.cid + 1].name}
                  </Title>
                </Stack>
                <IconArrowRight />
              </Group>
            </Card>
          )}
        </Group>
      </Stack>
    </Paper>
  );
};

const ShowProgramCategory = ({
  tab,
  index,
  startup,
  program,
  setProgram,
  selectedPrograms,
}) => {
  const [value, toggle] = useToggle([false, true]);

  return (
    <>
      {tab.waiting_for_approval && startup.is_member && (
        <Group position="right" key={tab.name}>
          <Button
            variant="default"
            className="w-fit mt-4 mr-4 px-4"
            onClick={toggle}
          >
            {value ? "Close changes" : "View changes"}
          </Button>
        </Group>
      )}
      <div className="flex flex-col bg-white shadow-sm rounded-xl">
        {!value ? (
          <div className="">
            <Markdown
              value={
                program?.categories[selectedPrograms.cid]?.sub_categories[index]
                  ?.response
              }
              visibleDragbar={true}
              preview={"preview"}
              hideToolbar={true}
            />
            {tab.files?.length ? (
              <>
                <List type="ordered" size="sm" mt={2}>
                  {tab.files.map((file, index) => (
                    <List.Item key={index}>
                      <Anchor href={file.url} target="blank">
                        <Text className="text-sm text-blue-500 hover:underline">
                          {file.url.split("/").pop()}
                        </Text>
                      </Anchor>
                    </List.Item>
                  ))}
                </List>
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <>
            {startup.is_owner && (
              <ProgramChangesApproval
                id={tab.id}
                toggle={toggle}
                setProgram={setProgram}
                selectedPrograms={{
                  ...selectedPrograms,
                  sid: index,
                }}
              />
            )}
            <Markdown
              value={tab.diff_proposed_response}
              visibleDragbar={true}
              preview={"preview"}
              hideToolbar={true}
            />
          </>
        )}
      </div>
    </>
  );
};

export const StartupPrivacy = ({ startup, setStartup }) => {
  const handleSubmit = async (val) => {
    if (startup.startup_entity.public_listed === val) return;

    try {
      const response = await fetchApi(
        `/startup_entities/${startup.startup_entity.slug}`,
        "PATCH",
        {
          startup_entity: { public_listed: val },
        }
      );

      if (response.ok) {
        const res = (await response.json()).startup_entity;

        setStartup((p) => ({
          ...p,
          startup_entity: {
            ...p.startup_entity,
            public_listed: res.public_listed,
          },
        }));
      } else {
        const msg = (await response.json()).error;
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  return (
    <Menu shadow="md">
      <Menu.Target>
        <Button
          variant="default"
          className="w-fit"
          size="md"
          leftIcon={
            startup.startup_entity.public_listed ? (
              <IconEye size="1.3rem" />
            ) : (
              <IconEyeOff size="1.3rem" />
            )
          }
        >
          Privacy
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <div className="relative flex items-start py-2 px-3 rounded-lg hover:bg-gray-100 ">
          <div className="flex items-center h-5 mt-1">
            <input
              id="public"
              type="radio"
              className="shrink-0 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
              onChange={() => handleSubmit(true)}
              checked={startup.startup_entity.public_listed === true}
            />
          </div>
          <label htmlFor="public" className="ms-3.5">
            <span className="block text-sm font-semibold text-gray-800 dark:text-gray-300">
              Public
            </span>
            <span className="block text-sm text-gray-600 dark:text-gray-500">
              Make it available to everyone.
            </span>
          </label>
        </div>
        <div className="relative flex items-start py-2 px-3 rounded-lg hover:bg-gray-100 ">
          <div className="flex items-center h-5 mt-1">
            <input
              id="private"
              type="radio"
              onChange={() => handleSubmit(false)}
              checked={startup.startup_entity.public_listed === false}
              className="shrink-0 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
            />
          </div>
          <label htmlFor="private" className="ms-3.5">
            <span className="block text-sm font-semibold text-gray-800 ">
              Unlisted
            </span>
            <span className="block text-sm text-gray-600 ">
              Make it private from public view.
            </span>
          </label>
        </div>
      </Menu.Dropdown>
    </Menu>
  );
};

const RoadmapCompletionDuration = ({ programs, slug, setProgram }) => {
  const [date, setDate] = useState(null);
  const [duration, setDuration] = useState("30");
  const [opened, setOpened] = useState(false);

  const handleUpdate = async () => {
    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/update_roadmap_duration`,
        "PATCH", // Use PUT method to update the role
        { roadmap_duration: duration, start_date: date }
      );

      if (response.ok) {
        const res = await response.json();
        // setNotification({ type: "success", content: res.message });
        setProgram(res.program);
        console.log(res.program);
      } else {
        const { error } = await response.json();
        // setNotification({ type: "failure", content: error });
        console.error(error);
      }
    } catch (error) {
      console.error("Error during role update:", error);
    }
  };

  const dateDiff = programs?.last_target_update
    ? moment().diff(moment(programs?.last_target_update), "days")
    : 7;

  return (
    <Menu shadow="md" opened={opened} onChange={setOpened}>
      <Menu.Target>
        <Button
          variant="default"
          className="w-fit"
          compact
          leftIcon={<IconCalendarTime size="1rem" />}
        >
          Target Date
        </Button>
      </Menu.Target>
      <Menu.Dropdown p={20} className="max-w-80">
        {dateDiff >= 7 || true ? (
          <>
            <Stack>
              <Select
                label="Select a duration"
                description="No of days to complete the roadmap"
                onChange={setDuration}
                value={duration}
                data={[
                  { value: "30", label: "STARTER (30 days)" },
                  { value: "60", label: "GROWTH (60 days)" },
                  { value: "100", label: "PRO (100 days)" },
                ]}
              />
              <div>
                <p className="text-sm mb-0 text-gray-900 ">
                  Select a start date
                </p>
                <DatePicker
                  value={date}
                  onChange={setDate}
                  minDate={new Date()}
                />
              </div>
              <Button
                disabled={!date || !duration}
                variant="default"
                fullWidth
                onClick={() => {
                  handleUpdate();
                  setOpened(false);
                }}
              >
                Finish
              </Button>
            </Stack>
          </>
        ) : (
          <p className="text-sm">
            Roadmap targets can be updated once every 7 days. Next update
            possible in {7 - dateDiff} days.
          </p>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export function StyledTabs(props) {
  const styles = {
    tab: {
      backgroundColor: "#FFFFFF", // White background
      color: "#333333", // Dark gray text color
      border: "1px solid #CCCCCC", // Light gray border
      padding: "4px 12px", // Padding
      cursor: "pointer", // Pointer cursor
      fontSize: "14px", // Font size
      width: "max-content",

      "&:disabled": {
        opacity: 0.5, // Reduced opacity for disabled tabs
        cursor: "not-allowed", // Not-allowed cursor for disabled tabs
      },

      "&:first-of-type": {
        borderTopLeftRadius: "4px", // Rounded top-left corner for the first tab
        borderBottomLeftRadius: "4px", // Rounded bottom-left corner for the first tab
      },

      "&:last-of-type": {
        borderTopRightRadius: "4px", // Rounded top-right corner for the last tab
        borderBottomRightRadius: "4px", // Rounded bottom-right corner for the last tab
      },

      "&[data-active]": {
        backgroundColor: "#007BFF", // Blue background for active tab
        borderColor: "#007BFF", // Blue border for active tab
        color: "#FFFFFF", // White text color for active tab
      },
    },

    tabIcon: {
      marginRight: "4px", // Margin to the right of the tab icon
      // display: "flex", // Flex display
      // alignItems: "center", // Centered vertically
    },

    // tabsList: {
    //   display: "flex", // Flex display for the tabs list
    //   flex: 1,
    // },
  };

  return <Tabs styles={styles} {...props} />;
}
