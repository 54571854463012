import {} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import fetchApi from "../../../Lib/api";

export default function ModerationHistory({}) {
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchApi(`/hub/admin/moderation/history`, "GET");
        if (response.ok) {
          const res = await response.json();

          console.log(res);
        } else {
          console.error((await response.json()).error);
        }
      } catch (error) {
        console.error("Error during data fetching:", error);
      }
    }
    fetchData();
  }, []);
  return <>Coming Soon</>;
}
