import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useCallback,
} from "react";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { getCodeString } from "rehype-rewrite";
import mermaid from "mermaid";

const randomid = () => parseInt(String(Math.random() * 1e15), 10).toString(36);
const Code = ({ inline, children = [], className, ...props }) => {
  const demoid = useRef(`dome${randomid()}`);
  const [container, setContainer] = useState(null);
  const isMermaid =
    className && /^language-mermaid/.test(className.toLocaleLowerCase());
  const code = children
    ? getCodeString(props.node.children)
    : children[0] || "";

  useEffect(() => {
    if (container && isMermaid && demoid.current && code) {
      mermaid
        .render(demoid.current, code)
        .then(({ svg, bindFunctions }) => {
          container.innerHTML = svg;
          if (bindFunctions) {
            bindFunctions(container);
          }
        })
        .catch((error) => {
          console.log("error:", error);
        });
    }
  }, [container, isMermaid, code, demoid]);

  const refElement = useCallback((node) => {
    if (node !== null) {
      setContainer(node);
    }
  }, []);

  if (isMermaid) {
    return (
      <Fragment>
        <code id={demoid.current} style={{ display: "none" }} />
        <code className={className} ref={refElement} data-name="mermaid" />
      </Fragment>
    );
  }
  return <code className={className}>{children}</code>;
};

export default function Markdown({
  value,
  setValue,
  visibleDragbar,
  hideToolbar,
  preview,
  height,
  textareaProps,
}) {
  //   const [value, setValue] = React.useState(mdMermaid);
  return (
    <div className="h-full mt-3">
      {!hideToolbar || setValue ? (
        <MDEditor
          value={value}
          height={height}
          onChange={setValue}
          defaultTabEnable={false}
          autoFocus={true}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
            components: {
              code: Code,
            },
          }}
          textareaProps={{
            placeholder: "Why so empty? Please write...",
            ...textareaProps,
          }}
          visibleDragbar={visibleDragbar}
          preview={preview}
          hideToolbar={hideToolbar}
        />
      ) : (
        <MDEditor.Markdown source={value} />
      )}
    </div>
  );
}
