import { Avatar, Badge, Group, Image, Stack } from "@mantine/core";
import React, { useState } from "react";
import { handleFollowUnfollow } from "../../Startups/StartupShow";

export default function StartupCard({
  startup: { id, description, name, image_url, image, entity_type, slug },
  isFollowing,
  roles,
  members,
}) {
  const [follow, setFollow] = useState(isFollowing);
  const backgroundImageStyle = {
    backgroundImage: `url('${
      image_url ||
      (image && image.url) ||
      "https://flowbite.com/docs/images/examples/image-3@2x.jpg"
    }')`,
    backgroundSize: "cover",
  };
  return (
    <div className="group flex flex-col h-full bg-white border border-gray-200 shadow-sm rounded-xl ">
      <a
        href={`${
          entity_type === "Project" ? "/project_entities" : "/startup_entities"
        }/${slug}`}
      >
        <div
          className="h-52 flex flex-col justify-center items-center rounded-t-xl"
          style={backgroundImageStyle}
        ></div>
        <div className="p-4 md:p-6">
          <Group position="apart" align="flex-end">
            {entity_type === "Project" ? (
              <span className="block mb-1 text-xs font-semibold uppercase text-amber-500  ">
                Project
              </span>
            ) : (
              <span className="block mb-1 text-xs font-semibold uppercase text-blue-600">
                Startup
              </span>
            )}
            {roles ? (
              <button
                type="button"
                className="py-1 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50"
              >
                Open roles
                <span className="inline-flex items-center py-0.5 px-1.5 rounded-full text-xs font-medium bg-blue-500 text-white">
                  {roles}
                </span>
              </button>
            ) : (
              <></>
            )}
          </Group>
          <h3 className="text-xl font-semibold text-gray-800 line-clamp-2">
            {name}
          </h3>
          <p className="mt-3 text-gray-500 line-clamp-2">{description}</p>
        </div>
      </a>
      <Avatar.Group spacing="sm" className="mt-auto mx-auto">
        {members.map((m) => (
          <Avatar
            key={`user_${m.id}_${m.first_name}`}
            src={m.avatar_url}
            radius="xl"
            color="indigo"
          >
            {m.first_name[0]}
            {m.last_name[0]}
          </Avatar>
        ))}
        {Array.from({ length: 7 - members.length }, (_, index) => (
          <Avatar src={null} key={`user_${index}`} radius="xl" />
        ))}
      </Avatar.Group>
      <div className="mt-auto flex border-t border-gray-200 divide-x divide-gray-200 ">
        {isFollowing !== null && (
          <button
            onClick={() => {
              console.log(follow);
              handleFollowUnfollow(follow, slug)
                .then((value) => {
                  setFollow(value);
                })
                .catch((error) => {
                  // Handle error here
                });
            }}
            className="w-full  py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-es-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
          >
            {follow ? "Following" : "Follow"}
          </button>
        )}

        <a
          className="w-full flex-1 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-ee-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
          href="#"
        >
          Share
        </a>
      </div>
    </div>
  );
}
