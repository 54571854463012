import React, { useContext, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { IconMenu2, IconX } from "@tabler/icons-react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import NavbarSimple from "./ProfileNavbar";
import { UserInfoIcons } from "./UserInfo";
import { useForm } from "@mantine/form";
import { UserContext } from "../Lib/UserContext";
import Profile from "./Pages/Profile";
import Settings from "./Pages/Settings";
import Workspace from "../Dashbaord/DashboardPages/Workspace";
import Activity from "../Dashbaord/DashboardPages/activities";
import HeaderAndSidebar from "../Lib/HeaderAndSidebar";

export default function ProfilePage() {
  const isMobile = useMediaQuery("(max-width: 1160px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);
  const [isEditMode, setIsEditMode] = useState(false);
  const {
    user: { user },
  } = useContext(UserContext);
  const form = useForm({
    initialValues: {
      avatar: null,
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      user_type: user.user_type || 0,
      company: user.company || "",
      job_title: user.job_title || "",
      year_of_experience: user.year_of_experience || 0,
      work_type: user.work_type || "",
      university: user.university || "",
      degree: user.degree || "",
      country: user.address?.country || "",
      city: user.address?.city || "",
      about_me: user.about_me || "",
      expert_in: user.expert_in || "",
      skills: user.skills || "",
      industries: user.industries || "",
      github: user.github || "",
      twitter: user.twitter || "",
      linkedin: user.linkedin || "",
      public_email: user.public_email || "",
      public_phone: user.public_phone || "",
      display_email: user.display_email,
      display_phone: user.display_phone,
    },

    validate: {},
  });

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <HeaderAndSidebar
      sidebar={
        <>
          <UserInfoIcons
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            form={form}
          />
          <NavbarSimple handleSidebarToggle={handleSidebarToggle} />
        </>
      }
      hideDefaultSidebar={true}
    >
      <Routes>
        <Route
          path="/"
          element={
            <Profile
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              form={form}
            />
          }
        />
        <Route path="/workspace" element={<Workspace />} />
        <Route path="/activity" element={<Activity />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </HeaderAndSidebar>
  );
}
