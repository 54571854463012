import {
  UnstyledButton,
  Menu,
  Image,
  rem,
  Button,
  Group,
  Stack,
  createStyles,
  Paper,
  Tabs,
  Title,
  Center,
  Text,
  PasswordInput,
  TextInput,
} from "@mantine/core";
import {
  IconLanguage,
  IconPassword,
  IconUser,
  IconChevronDown,
} from "@tabler/icons-react";
import React, { useState } from "react";
import UpdatePasswordForm from "../../Authentication/UpdatePasswordForm";
import { useForm } from "@mantine/form";
import fetchApi from "../../Lib/api";
import Notification from "../../Lib/Notification";

const data = [
  { label: "English" },
  { label: "German" },
  { label: "Italian" },
  { label: "French" },
  { label: "Polishgsegdgdfd" },
];

const useStyles = createStyles((theme) => ({
  control: {
    width: rem(200),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: [theme.spacing.xs, theme.spacing.md],
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[4]}`,
    transition: "background-color 150ms ease",
    backgroundColor: theme.colors.gray[0],
    "&[data-expanded]": {
      backgroundColor: theme.colors.gray[0],
    },
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  label: {
    fontWeight: 600,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[6],
    marginRight: theme.spacing.md,
  },
  icon: {
    transition: "transform 150ms ease",
    transform: "rotate(0deg)",
    "[data-expanded] &": {
      transform: "rotate(180deg)",
    },
  },
}));

export function DropDownSelect({}) {
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState(data[0]);
  const { classes, cx } = useStyles();
  const items = data.map((item) => (
    <Menu.Item
      leftSection={<Image src={item.image} width={18} height={18} />}
      onClick={() => setSelected(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="target"
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton
          className={classes.control}
          data-expanded={opened || undefined}
        >
          <Group gap="xs">
            {/* <Image src={selected.image} width={22} height={22} /> */}
            <span className={classes.label}>{selected.label}</span>
          </Group>
          <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  );
}

export function ChangeEmail() {
  const [notification, setNotification] = useState(null);
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      password: (val) =>
        val.length >= 6
          ? null
          : "Password should include at least 6 characters",
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
    },
  });

  const handleSubmit = async (e) => {
    try {
      const response = await fetchApi("/api/users/update_email", "PUT", {
        user: { password: form.values.password, email: form.values.email },
      });

      if (response.ok) {
        const msg = (await response.json()).message;
        setNotification({ type: "success", content: msg });
        console.log("Email change successful!");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Email change failed.");
      }
    } catch (error) {
      console.error("Error during email change:", error);
    }
  };

  return (
    <form
      onSubmit={form.onSubmit((e) => handleSubmit())}
      className="mx-auto lg:w-2/5 lg:mt-5"
    >
      <Stack p={0}>
        {notification && <Notification {...notification} />}
        <TextInput
          required
          label="New Email"
          placeholder="Your new Email"
          value={form.values.email}
          onChange={(event) =>
            form.setFieldValue("email", event.currentTarget.value)
          }
          error={form.errors.email}
          radius="md"
          size="md"
        />
        <PasswordInput
          required
          label="Password"
          placeholder="Your password"
          value={form.values.password}
          onChange={(event) =>
            form.setFieldValue("password", event.currentTarget.value)
          }
          error={form.errors.password}
          radius="md"
          size="md"
        />
        <Center>
          <Button
            type="submit"
            variant="default"
            fullWidth
            className="w-fit lg:px-12"
          >
            Change Email
          </Button>
        </Center>
      </Stack>
    </form>
  );
}

function DeleteAccount() {
  const [notification, setNotification] = useState(null);
  const form = useForm({
    initialValues: {
      password: "",
    },
    validate: {
      password: (val) =>
        val.length >= 6
          ? null
          : "Password should include at least 6 characters",
    },
  });
  const handleSubmit = async (e) => {
    try {
      const response = await fetchApi("/api/users/delete_account", "DELETE", {
        user: { password: form.values.password },
      });

      if (response.ok) {
        const msg = (await response.json()).message;
        setNotification({ type: "success", content: msg });
        window.location.href = "/";
        console.log("Account Deleted successful!");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Account Deletion failed.");
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };
  return (
    <form
      onSubmit={form.onSubmit((e) => handleSubmit())}
      className="mx-auto lg:w-2/5 lg:mt-5"
    >
      <Stack>
        <Text className="mt-2 px-2 text-base text-gray-600">
          Once you delete an account, there is no going back. Please be certain.
        </Text>
        <Stack className="">
          <PasswordInput
            required
            label="Password"
            placeholder="Your password"
            value={form.values.password}
            onChange={(event) =>
              form.setFieldValue("password", event.currentTarget.value)
            }
            error={form.errors.password}
            radius="md"
            size="md"
            className="w-full"
          />
          <Button
            type="submit"
            variant="default"
            fullWidth
            className="w-fit mx-auto lg:px-12 text-red-700"
          >
            Delete this account
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default function Settings() {
  const [firstTab, setFirstTab] = useState("password");
  return (
    <main className="flex justify-center ">
      <div className="max-w-5xl w-full lg:p-6">
        <Tabs
          color="lime"
          radius="md"
          defaultValue="personal"
          onTabChange={setFirstTab}
          value={firstTab}
        >
          <Tabs.List grow>
            <Tabs.Tab value="password" icon={<IconPassword size="1.5rem" />}>
              Password
            </Tabs.Tab>
            <Tabs.Tab value="language" icon={<IconLanguage size="1.5rem" />}>
              Language
            </Tabs.Tab>
            <Tabs.Tab value="account" icon={<IconUser size="1.5rem" />}>
              Account
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="password" pt="xs">
            <Stack p="xl" spacing={10}>
              <Title order={5} className="text-gray-600">
                Password Change
              </Title>
              <Paper shadow="xs" radius="md" withBorder p="xl" className="">
                <UpdatePasswordForm />
              </Paper>
            </Stack>
          </Tabs.Panel>
          <Tabs.Panel value="language" pt="xs">
            <Stack p="xl">
              <Title order={5} className="text-gray-600">
                Language
              </Title>
              <Paper shadow="xs" radius="md" withBorder p="xl" className="">
                <Center>
                  <Stack>
                    <Text className="mt-2 text-base text-gray-600">
                      Select a language of your choice
                    </Text>
                    <DropDownSelect />
                  </Stack>
                </Center>
              </Paper>
            </Stack>
          </Tabs.Panel>
          <Tabs.Panel value="account" pt="xs">
            <Stack p="xl" spacing={10}>
              <Title order={5} className="text-gray-600">
                Change your email
              </Title>
              <Paper shadow="xs" radius="md" withBorder p="xl" className="">
                <ChangeEmail />
              </Paper>
              <Title order={5} className="text-gray-600">
                Delete Account
              </Title>
              <Paper
                shadow="xs"
                radius="md"
                withBorder
                p="xl"
                className="border-red-500"
              >
                <DeleteAccount />
              </Paper>
            </Stack>
          </Tabs.Panel>
        </Tabs>
      </div>
    </main>
  );
}
