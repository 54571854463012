import { Grid, Group, Stack, Text, Textarea, Title } from "@mantine/core";
import { industries, skills } from "./data";
import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

export default function AboutMe({ form, showError }) {
  const [selectedExperts, setSelectedExperts] = useState(
    form.values.expert_in.map((m) => ({ label: m, value: m }))
  );
  const [selectedSkills, setSelectedSkills] = useState(
    form.values.skills.map((m) => ({ label: m, value: m }))
  );
  const [selectedIndustries, setSelectedIndustries] = useState(
    form.values.industries.map((m) => ({ label: m, value: m }))
  );
  return (
    <>
      <Grid className="lg:mt-6">
        <Grid.Col lg={4} order={1} orderSm={1} orderLg={1}>
          <Stack spacing={0}>
            <Title order={5} className="mt-4">
              About Me
            </Title>
            <Text size="md">
              Use this section to share your personality, interests, or anything
              else you'd like others to know.
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col lg={8} order={1} orderSm={2} orderLg={2}>
          <Group spacing={"md"} grow className="lg:my-6">
            <Stack spacing={0}>
              <Text align="right" mr={3} fw={700}>
                {form.values.about_me.length}/500
              </Text>
              <Textarea
                radius="md"
                size="md"
                defaultValue={form.values.about_me}
                onChange={(event) => {
                  const updatedValue = event.currentTarget.value;
                  form.setFieldValue("about_me", updatedValue.slice(0, 500));
                }}
                error={form.errors.about_me}
                minRows={3}
                maxLength={500}
              />
            </Stack>
          </Group>
        </Grid.Col>
      </Grid>

      <Grid className="lg:mt-6">
        <Grid.Col lg={4} order={1} orderSm={1} orderLg={1}>
          <Stack spacing={0}>
            <Title order={5} className="mt-4">
              Expert In
            </Title>
            <Text size="md">
              Choose and showcase your specialized skills and knowledge.
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col lg={8} order={1} orderSm={2} orderLg={2}>
          <Group spacing={"md"} grow className="lg:my-6">
            <Stack spacing={0}>
              <Text align="right" mr={3} fw={600}>
                {form.values.expert_in.length}/5
              </Text>
              <MultiSelect
                isCreatable={true}
                placeholder="Pick value"
                options={skills}
                hasSelectAll={false}
                value={selectedExperts}
                onChange={(e) => {
                  if (e.length > 5) return;
                  setSelectedExperts(e);
                  form.setFieldValue(
                    "expert_in",
                    e.map((m) => m.label)
                  );
                }}
                className="max-w-full"
              />
              {showError && !selectedExperts.length && (
                <Text fz="sm" c="red" className="mt-1 ml-auto">
                  Select at least one field
                </Text>
              )}
            </Stack>
          </Group>
        </Grid.Col>
      </Grid>

      <Grid className="lg:mt-6">
        <Grid.Col lg={4} order={1} orderSm={1} orderLg={1}>
          <Stack spacing={0}>
            <Title order={5} className="mt-4">
              Industries
            </Title>
            <Text size="md">
              Pick your industry focus to emphasize professional experience and
              sectors of interest.
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col lg={8} order={1} orderSm={2} orderLg={2}>
          <Group spacing={"md"} grow className="lg:my-6">
            <Stack spacing={0}>
              <Text align="right" mr={3} fw={700}>
                {form.values.industries.length}/5
              </Text>
              <MultiSelect
                isCreatable={true}
                placeholder="Pick value"
                options={industries}
                hasSelectAll={false}
                value={selectedIndustries}
                onChange={(e) => {
                  if (e.length > 5) return;
                  setSelectedIndustries(e);
                  form.setFieldValue(
                    "industries",
                    e.map((m) => m.label)
                  );
                }}
                className="max-w-full"
              />
              {showError && !selectedIndustries.length && (
                <Text fz="sm" c="red" className="mt-1 ml-auto">
                  Select at least one industry
                </Text>
              )}
            </Stack>
          </Group>
        </Grid.Col>
      </Grid>

      <Grid className="lg:mt-6">
        <Grid.Col lg={4} order={1} orderSm={1} orderLg={1}>
          <Stack spacing={0}>
            <Title order={5} className="mt-4">
              Skill
            </Title>
            <Text size="md">
              Select your specific talents and proficiencies.
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col lg={8} order={1} orderSm={2} orderLg={2}>
          <Group spacing={"md"} grow className="lg:my-6">
            <Stack spacing={0}>
              <Text align="right" mr={3} fw={700}>
                {form.values.skills.length}/15
              </Text>
              <MultiSelect
                isCreatable={true}
                placeholder="Pick value"
                options={skills}
                hasSelectAll={false}
                value={selectedSkills}
                onChange={(e) => {
                  if (e.length > 15) return;
                  setSelectedSkills(e);
                  form.setFieldValue(
                    "skills",
                    e.map((m) => m.label)
                  );
                }}
                className="max-w-full"
              />
              {showError && !selectedSkills.length && (
                <Text fz="sm" c="red" className="mt-1 ml-auto">
                  Select at least one skill
                </Text>
              )}
            </Stack>
          </Group>
        </Grid.Col>
      </Grid>
    </>
  );
}
