import {
  UnstyledButton,
  Menu,
  Image,
  rem,
  Button,
  Group,
  Stack,
  Title,
  createStyles,
  ScrollArea,
  SimpleGrid,
  ThemeIcon,
  Modal,
} from "@mantine/core";
import {
  IconAward,
  IconBrain,
  IconBulb,
  IconChevronDown,
  IconExternalLink,
  IconRocket,
} from "@tabler/icons-react";

import React, { useContext, useEffect, useMemo, useState } from "react";

import StartupCard from "./Card";
import fetchApi from "../../Lib/api";
import {
  countries,
  industries,
  sorting,
  stages,
  tags,
} from "../../Onboarding/data";
import { UserContext } from "../../Lib/UserContext";
import { useDisclosure } from "@mantine/hooks";
import { DatePicker } from "@mantine/dates";
import moment from "moment";

const useStyles = createStyles((theme) => ({
  control: {
    minWidth: rem(150),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: [theme.spacing.xs, theme.spacing.md],
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[4]}`,
    transition: "background-color 150ms ease",
    backgroundColor: "white",
    "&[data-expanded]": {
      backgroundColor: theme.colors.gray[2],
    },
    "&:hover": {
      backgroundColor: theme.colors.gray[2],
    },
  },
  label: {
    fontWeight: 600,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[6],
    marginRight: theme.spacing.md,
  },
  icon: {
    transition: "transform 150ms ease",
    transform: "rotate(0deg)",
    "[data-expanded] &": {
      transform: "rotate(180deg)",
    },
  },
}));

export function DropDownSelect({
  selectType,
  selectData,
  setFilterParams,
  filterKey,
  date,
}) {
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState(selectType);
  const { classes, cx } = useStyles();

  const handleSelect = (item) => {
    if (item.label === "All") {
      setSelected(selectType);
      setFilterParams((prevParams) => {
        const { [filterKey]: _, ...updatedParams } = prevParams;

        return updatedParams;
      });
      return;
    }
    setSelected(item);
    setFilterParams((p) => ({ ...p, [filterKey]: item.value || item.label }));
    console.log({ [filterKey]: item.label });
  };

  const items = selectData.map((item, index) => (
    <Menu.Item onClick={() => handleSelect(item)} key={item.label + index}>
      {item.label}
    </Menu.Item>
  ));

  return (
    <Menu
      style={{ backgroundColor: "white", minWidth: "200px" }}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width={date ? "unset" : "target"}
      withArrow
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton
          className={classes.control}
          style={{ backgroundColor: "white" }}
        >
          <Group gap="xs">
            <span className={classes.label}>{selected.label}</span>
          </Group>
          <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown w={500}>
        {!date ? (
          <>
            <Menu.Label>{selected.label}</Menu.Label>
            <ScrollArea h={200}> {items}</ScrollArea>
          </>
        ) : (
          <Stack className="text-center">
            <Button
              variant="light"
              compact
              onClick={(d) => handleSelect({ label: "All" })}
            >
              Reset
            </Button>
            <DatePicker
              onChange={(d) =>
                handleSelect({ label: moment(d).format("YYYY-MM-DD") })
              }
            />
          </Stack>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}

export function InputWithButton({ placeholder, setFilterParams }) {
  const [value, setValue] = useState("");
  return (
    <div className="relative w-full lg:w-2/3 ">
      <div className="absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none">
        <svg
          className="w-8 h-8 text-gray-500"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
      </div>
      <input
        type="search"
        id="default-search"
        className="block w-full p-4 ps-16 text-xl text-gray-900 border-none outline-none rounded-lg  focus:ring-red-500 focus:border-red-500"
        placeholder={placeholder}
        required
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.target.value.trim() === "") {
            setFilterParams((prevParams) => {
              const { search_term: _, ...updatedParams } = prevParams;

              return updatedParams;
            });
            return;
          }
          if (e.key === "Enter" && e.target.value.trim() !== "") {
            setFilterParams((p) => ({
              ...p,
              search_term: e.target.value.trim(),
            }));
          }
        }}
      />
      <button
        onClick={(e) => {
          if (value.trim() === "") {
            setFilterParams((prevParams) => {
              const { search_term: _, ...updatedParams } = prevParams;

              return updatedParams;
            });
            return;
          }
          setFilterParams((p) => ({
            ...p,
            search_term: value.trim(),
          }));
        }}
        className="text-white absolute end-2.5 inset-y-2.5 bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 "
      >
        Search
      </button>
    </div>
  );
}

export default function Startups() {
  const [startups, setStartups] = useState([]);
  const [similarStartups, setSimilarStartups] = useState([]);
  const [searchStartups, setSearchStartups] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [opened, { close, open }] = useDisclosure(false);
  const [fetching, setFetching] = useState(true);
  const {
    user: { user },
  } = useContext(UserContext);

  const memoizedSelectData = useMemo(
    () => ({
      countries: [{ label: "All", value: "All" }, ...countries],
      industries: [{ label: "All", value: "All" }, ...industries],
      tags: [{ label: "All", value: "All" }, ...tags],
      stages: [{ label: "All", value: "All" }, ...stages],
      sorting: [{ label: "All", value: "All" }, ...sorting],
    }),
    []
  );

  useEffect(() => {
    const params = new URLSearchParams();
    const fetchData = async () => {
      for (const key in filterParams) {
        params.append(`q[${key}]`, filterParams[key]);
      }
      const queryString = params.toString();
      try {
        const response = await fetchApi(
          `/startup_entities?${queryString}`,
          "GET"
        );

        if (response.ok) {
          const res = await response.json();

          setFetching(false);
          setStartups(res["All Startups"] || []);
          setSimilarStartups(res["Similar Startups"] || []);
          setSearchStartups(res["Exact Matches"] || []);
        } else {
          const msg = (await response.json()).error;
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during fetching users", error);
      }
    };

    fetchData();
  }, [filterParams]);

  return (
    <>
      <main className="flex justify-center ">
        <div className="max-w-7xl w-full p-6">
          <Modal
            opened={opened}
            onClose={close}
            size="lg"
            withCloseButton={false}
          >
            <div class="p-4 overflow-y-auto">
              <p class="mt-1 text-gray-8000">
                You will need to complete your profile to have access to this
                feature.
              </p>
            </div>
            <div class="flex justify-end items-center gap-x-2 py-3 px-4">
              <Button onClick={close} variant="default" className=" px-12">
                Cancel
              </Button>
              <Button
                href="/onboarding"
                component="a"
                variant="light"
                color="indigo"
                className="text-blue-700 px-12"
              >
                Complete Profile
              </Button>
            </div>
          </Modal>
          <Stack spacing={12}>
            <Group>
              <ThemeIcon
                variant="gradient"
                size="lg"
                aria-label="Gradient action icon"
                gradient={{ from: "blue", to: "rgba(8, 0, 18, 1)", deg: 90 }}
              >
                <IconBulb />
              </ThemeIcon>
              <Title order={3} className="text-slate-900">
                Startups
              </Title>
            </Group>
            <Group className="flex-wrap lg:flex-nowrap">
              <InputWithButton
                placeholder="Search for startups"
                setFilterParams={setFilterParams}
              />

              <Button
                component="a"
                href="/dashboard/#/members?q=expert"
                variant="default"
                fullWidth
                className="w-full lg:w-1/3 flex-grow-0 font-light rounded-lg border border-transparent bg-green-800 text-white hover:bg-green-600 disabled:opacity-50 disabled:pointer-events-none expert"
                leftIcon={<IconAward size="1.2rem" />}
                size="xl"
              >
                Looking for an expert?
              </Button>
            </Group>
            <Group className="overflow-x-auto flex-nowrap lg:flex-wrap">
              <DropDownSelect
                filterKey="country_matches"
                selectType={{ label: "Country" }}
                selectData={memoizedSelectData.countries}
                setFilterParams={setFilterParams}
              />
              <DropDownSelect
                filterKey="industry_matches"
                selectType={{ label: "Industry" }}
                selectData={memoizedSelectData.industries}
                setFilterParams={setFilterParams}
              />
              <DropDownSelect
                filterKey="openings_status_matches"
                selectType={{ label: "Collab Type" }}
                selectData={memoizedSelectData.tags}
                setFilterParams={setFilterParams}
              />
              <DropDownSelect
                filterKey="stage_matches"
                selectType={{ label: "Stage" }}
                selectData={memoizedSelectData.stages}
                setFilterParams={setFilterParams}
              />
              <DropDownSelect
                filterKey="sorting"
                selectType={{ label: "More Filters" }}
                selectData={memoizedSelectData.sorting}
                setFilterParams={setFilterParams}
              />
            </Group>
            <Group className="flex-wrap lg:flex-nowrap">
              <Button
                component={
                  user.profile_complete || !user.validated_once ? "a" : "button"
                }
                href={
                  user.profile_complete || !user.validated_once
                    ? "/ai_validation_tool"
                    : undefined
                }
                onClick={
                  !user.profile_complete && user.validated_once
                    ? open
                    : undefined
                }
                variant="outline"
                fullWidth
                className="w-full font-light rounded-lg border border-transparent bg-blue-800 text-white hover:bg-blue-600 disabled:opacity-50 disabled:pointer-events-none create-startup"
                leftIcon={<IconBrain size="1.5rem" />}
                size="xl"
              >
                Validate your Idea & Create
              </Button>
              {/* <Menu
                transitionProps={{ transition: "pop-top-right" }}
                width="target"
                withinPortal
              >
                <Menu.Target>
                  <Button
                    variant="default"
                    fullWidth
                    className="w-full font-light rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-800 disabled:opacity-50 disabled:pointer-events-none"
                    leftIcon={<IconRocket size="1.5rem" />}
                    size="xl"
                  >
                    Create
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    component={user.profile_complete ? "a" : "button"}
                    href={
                      user.profile_complete
                        ? "/startup_entities/new"
                        : undefined
                    }
                    onClick={!user.profile_complete ? open : undefined}
                    icon={<IconBulb size="1.5rem" />}
                    rightSection={<IconExternalLink size="1.5rem" />}
                    className="w-full font-light mb-1 border border-transparent bg-blue-800 hover:bg-blue-800 text-white disabled:opacity-50 disabled:pointer-events-none create-startup"
                  >
                    Startup
                  </Menu.Item>
                  <Menu.Item
                    component={user.profile_complete ? "a" : "button"}
                    href={
                      user.profile_complete
                        ? "/project_entities/new"
                        : undefined
                    }
                    onClick={!user.profile_complete ? open : undefined}
                    icon={<IconRocket size="1.5rem" />}
                    rightSection={<IconExternalLink size="1.5rem" />}
                    className="w-full font-light border border-transparent bg-blue-600 text-white hover:bg-blue-600 disabled:opacity-50 disabled:pointer-events-none"
                  >
                    Project
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu> */}
            </Group>
          </Stack>
        </div>
      </main>
      {!fetching && (
        <main className="flex justify-center">
          <div className="max-w-7xl w-full p-6 all_startups">
            {!searchStartups.length && !similarStartups.length && (
              <Stack spacing={12}>
                <Title order={4} className="text-slate-900">
                  All Startups
                </Title>
                <SimpleGrid
                  spacing="lg"
                  verticalSpacing="lg"
                  breakpoints={[
                    { minWidth: "0rem", cols: 1 },
                    { minWidth: "50rem", cols: 2 },
                    { minWidth: "90rem", cols: 3 },
                  ]}
                >
                  {startups.map(
                    ({
                      is_following,
                      startup_entity,
                      openings_length,
                      members,
                    }) => (
                      <StartupCard
                        key={startup_entity.id}
                        startup={startup_entity}
                        isFollowing={is_following}
                        roles={openings_length}
                        members={members}
                      />
                    )
                  )}
                </SimpleGrid>
              </Stack>
            )}
            {!startups.length && (
              <Stack spacing={52}>
                <Stack spacing={12}>
                  <Title order={4} className="text-slate-900">
                    Search and Filter Results
                  </Title>
                  <SimpleGrid
                    spacing="lg"
                    verticalSpacing="lg"
                    breakpoints={[
                      { minWidth: "0rem", cols: 1 },
                      { minWidth: "50rem", cols: 2 },
                      { minWidth: "90rem", cols: 3 },
                    ]}
                  >
                    {searchStartups.map(
                      ({
                        is_following,
                        startup_entity,
                        openings_length,
                        members,
                      }) => (
                        <StartupCard
                          key={startup_entity.id}
                          startup={startup_entity}
                          isFollowing={is_following}
                          roles={openings_length}
                          members={members}
                        />
                      )
                    )}
                  </SimpleGrid>
                </Stack>

                <Stack spacing={12}>
                  <Title
                    order={4}
                    className="text-slate-900 text-center underline underline-offset-4"
                  >
                    Similar Startups you might be interested
                  </Title>
                  <SimpleGrid
                    spacing="lg"
                    verticalSpacing="lg"
                    breakpoints={[
                      { minWidth: "0rem", cols: 1 },
                      { minWidth: "50rem", cols: 2 },
                      { minWidth: "90rem", cols: 3 },
                    ]}
                  >
                    {similarStartups.map(
                      ({
                        is_following,
                        startup_entity,
                        openings_length,
                        members,
                      }) => (
                        <StartupCard
                          key={startup_entity.id}
                          startup={startup_entity}
                          isFollowing={is_following}
                          roles={openings_length}
                          members={members}
                        />
                      )
                    )}
                  </SimpleGrid>
                </Stack>
              </Stack>
            )}
          </div>
        </main>
      )}
    </>
  );
}
