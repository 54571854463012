import React, { useContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../Lib/Layout";
import { UserContext, UserProvider } from "../Lib/UserContext";
import Markdown from "../Lib/Markdown";
import {
  Alert,
  Anchor,
  Button,
  Checkbox,
  Group,
  Menu,
  Modal,
  Stack,
  Tooltip,
} from "@mantine/core";
import {
  IconAlertCircle,
  IconArrowLeft,
  IconBulb,
  IconExternalLink,
  IconRocket,
} from "@tabler/icons-react";
import fetchApi from "../Lib/api";
import { useDisclosure } from "@mantine/hooks";

function IdeaValidation({}) {
  const [idea, setIdea] = useState("");
  const [loading, setLoading] = useState(false);
  const [opened, { close, open }] = useDisclosure(false);
  const [ideaShort, setIdeaShort] = useState("");
  const [checked, setChecked] = useState(true);
  const {
    user: { user },
  } = useContext(UserContext);
  const [evaluation, setEvaluation] = useState({
    marketDemand: 0,
    competitiveAdvantage: 0,
    feasibility: 0,
    scalability: 0,
    revenueModel: 0,
    overallScore: NaN,
    overallFeedback: "",
    message: "",
  });
  const onSubmit = async () => {
    if (idea.length < 10) {
      setIdeaShort("Idea empty or too short to evaluate.");
      return;
    }
    try {
      setLoading(true);
      const res = await fetchApi("/chat", "POST", {
        message: idea,
        temporary: !checked,
      });

      if (res.ok) {
        const responseData = await res.json();
        const response = JSON.parse(responseData.response);
        const overallScore =
          response["market demand"] +
          response["competitive advantage"] +
          response["feasibility"] +
          response["scalability"] +
          response["revenue model"];
        setEvaluation({
          marketDemand: response["market demand"] || 0,
          competitiveAdvantage: response["competitive advantage"] || 0,
          feasibility: response["feasibility"] || 0,
          scalability: response["scalability"] || 0,
          revenueModel: response["revenue model"] || 0,
          overallFeedback: response["Overall feedback"] || "",
          improvementPoints: response["Improvement points"] || "",
          overallScore: overallScore,
          message: response["message"],
        });
        console.log(res);
      } else {
        console.error("HTTP error! Failed to validate idea");
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal opened={opened} onClose={close} size="lg" withCloseButton={false}>
        <div class="p-4 overflow-y-auto">
          <p class="mt-1 text-gray-8000">
            You will need to complete your profile to have access to this
            feature.
          </p>
        </div>
        <div class="flex justify-end items-center gap-x-2 py-3 px-4">
          <Button onClick={close} variant="default" className=" px-12">
            Cancel
          </Button>
          <Button
            href="/onboarding"
            component="a"
            variant="light"
            color="indigo"
            className="text-blue-700 px-12"
          >
            Complete Profile
          </Button>
        </div>
      </Modal>
      <main className="max-w-[900px] lg:pt-10 p-4 pt-6 mx-2  sm:mx-auto  space-x-2 text-sm font-mediums sm:space-x-4">
        <Anchor onClick={() => window.history.back()} target="_blank">
          <Group className="mb-2" spacing={4} h>
            <IconArrowLeft size={18} color="#1c7ed6" />
            Go back
          </Group>
        </Anchor>
        <Stack>
          {" "}
          <div>
            <h2 className="group relative text-xl font-bold text-gray-800 ">
              Explore the potential of your business ideas with our Idea
              Validation Tool!
            </h2>
            <p className="mt-1 text-gray-600 text-lg">
              Designed to objectively assess the viability of your concepts in
              today's competitive market, this tool evaluates key factors such
              as market demand, competitive advantage, feasibility, scalability,
              and revenue potential. Each idea is scored out of 100, with
              detailed feedback provided to help refine your strategy. Embrace
              this opportunity to innovate and enhance your business ideas,
              guided by clear, actionable insights.
            </p>
          </div>
          <Stack>
            <div>
              <Alert icon={<IconAlertCircle size="1rem" />} color="green">
                An ideal score is 80 and above.
              </Alert>
              <Markdown
                value={idea}
                visibleDragbar={false}
                preview={"edit"}
                hideToolbar={true}
                height={300}
                setValue={(e) => {
                  setIdeaShort("");
                  setIdea(e.slice(0, 400));
                }}
              />

              <Group position="apart">
                <Checkbox
                  checked={checked}
                  onChange={(event) => setChecked(event.currentTarget.checked)}
                  label="You agree to all our terms and conditions "
                />

                <div className="flex justify-between">
                  <p className="mt-1 w-full text-end text-gray-600 text-lg">
                    ({idea.length}/400)
                  </p>
                </div>
              </Group>
            </div>
            {!isNaN(evaluation.overallScore) ? (
              <p className="mt-1  text-lg text-center text-green-500">
                Your Idea have a total validation score of{" "}
                {evaluation.overallScore}/100
              </p>
            ) : (
              <p className="mt-1  text-lg text-center text-red-500">
                {evaluation.message}
              </p>
            )}
            <Group noWrap>
              <Stack spacing={2} className="w-full lg:w-1/3 ">
                <Button
                  onClick={onSubmit}
                  variant="default"
                  fullWidth
                  className="flex-grow-0 font-light rounded-lg border border-transparent bg-green-800 text-white hover:bg-green-600 disabled:opacity-50 disabled:pointer-events-none expert"
                  leftIcon={<IconBulb size="1.5rem" />}
                  loading={loading}
                  size="xl"
                >
                  Validate Idea
                </Button>
                <p className="mt-1 w-full text-red-600 text-sm">{ideaShort}</p>
              </Stack>
              {!isNaN(evaluation.overallScore) && (
                <Menu
                  transitionProps={{ transition: "pop-top-right" }}
                  width="target"
                  withinPortal
                  disabled={evaluation.overallScore < 75}
                >
                  <Menu.Target>
                    <Tooltip
                      label="Your Idea score needs to be above 75 to be able to create."
                      disabled={evaluation.overallScore >= 75}
                      withinPortal
                    >
                      <Button
                        variant="default"
                        fullWidth
                        className="w-full lg:w-1/3 flex-grow-0 font-light rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-800 disabled:opacity-50 disabled:pointer-events-none"
                        leftIcon={<IconRocket size="1.5rem" />}
                        size="xl"
                      >
                        Create
                      </Button>
                    </Tooltip>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      component={user.profile_complete ? "a" : "button"}
                      href={
                        user.profile_complete
                          ? "/startup_entities/new"
                          : undefined
                      }
                      onClick={!user.profile_complete ? open : undefined}
                      icon={<IconBulb size="1.5rem" />}
                      rightSection={<IconExternalLink size="1.5rem" />}
                      className="w-full font-light mb-1 border border-transparent bg-blue-800 hover:bg-blue-800 text-white disabled:opacity-50 disabled:pointer-events-none create-startup"
                    >
                      Startup
                    </Menu.Item>
                    <Menu.Item
                      component={user.profile_complete ? "a" : "button"}
                      href={
                        user.profile_complete
                          ? "project_entities/new"
                          : undefined
                      }
                      onClick={!user.profile_complete ? open : undefined}
                      icon={<IconRocket size="1.5rem" />}
                      rightSection={<IconExternalLink size="1.5rem" />}
                      className="w-full font-light border border-transparent bg-blue-600 text-white hover:bg-blue-600 disabled:opacity-50 disabled:pointer-events-none"
                    >
                      Project
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
            </Group>
          </Stack>
          <Stack>
            <h2 className="group relative text-xl font-bold text-gray-800 ">
              Summary
            </h2>
            {true && (
              <>
                <Stack spacing={0}>
                  <div className="flex gap-4 items-center">
                    <h4 className="group relative text-lg font-bold text-gray-800 ">
                      Idea Overall Score:
                    </h4>{" "}
                    <span className="mt-1 text-gray-600 ">
                      {isNaN(evaluation.overallScore)
                        ? 0
                        : evaluation.overallScore}
                      /100
                    </span>
                  </div>

                  <div className="flex gap-4 items-center">
                    <h4 className="group relative text-lg font-bold text-gray-800 ">
                      Feedback:
                    </h4>{" "}
                    <span className="mt-1 text-lg text-gray-600 ">
                      {evaluation.overallFeedback}
                    </span>
                  </div>
                </Stack>

                <h2 className="group relative text-xl font-bold text-gray-800 ">
                  Details
                </h2>

                <Stack spacing={0}>
                  <div className="flex gap-4 items-center">
                    <h4 className="group relative text-lg font-semibold text-gray-800 ">
                      Market Demand:
                    </h4>{" "}
                    <span className="mt-1 text-gray-600 ">
                      {evaluation.marketDemand}/20
                    </span>
                  </div>

                  <div className="flex gap-4 items-center">
                    <h4 className="group relative text-lg font-bold text-gray-800 ">
                      Competitive Advantage:
                    </h4>{" "}
                    <span className="mt-1 text-gray-600 ">
                      {evaluation.competitiveAdvantage}/20
                    </span>
                  </div>

                  <div className="flex gap-4 items-center">
                    <h4 className="group relative text-lg font-bold text-gray-800 ">
                      Feasibility:
                    </h4>{" "}
                    <span className="mt-1 text-gray-600 ">
                      {evaluation.feasibility}/20
                    </span>
                  </div>

                  <div className="flex gap-4 items-center">
                    <h4 className="group relative text-lg font-bold text-gray-800 ">
                      Scalability:
                    </h4>{" "}
                    <span className="mt-1 text-gray-600 ">
                      {evaluation.scalability}/20
                    </span>
                  </div>

                  <div className="flex gap-4 items-center">
                    <h4 className="group relative text-lg font-bold text-gray-800 ">
                      Revenue Model:
                    </h4>{" "}
                    <span className="mt-1 text-gray-600 ">
                      {evaluation.revenueModel}/20
                    </span>
                  </div>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </main>
    </>
  );
}

export default function AuthenticationPage() {
  return (
    <Layout>
      <UserProvider>
        <Routes>
          <Route path="/ai_validation_tool" element={<IdeaValidation />} />
        </Routes>
      </UserProvider>
    </Layout>
  );
}
