import React, { useState } from "react";
import { useToggle, upperFirst } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Group,
  Button,
  Divider,
  Checkbox,
  Anchor,
  Stack,
  Center,
  Box,
  rem,
} from "@mantine/core";
import { GoogleButton } from "./GoogleButton";
import { IconArrowLeft } from "@tabler/icons-react";
import fetchApi from "../Lib/api";
import Notification from "../Lib/Notification";
import { LinkedInButton } from "./LinkedInButton";

const getValidationRules = (type) => {
  const commonRules = {
    email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
    password: (val) =>
      val.length >= 6 ? null : "Password should include at least 6 characters",
  };

  const typeRules = {
    login: {},
    register: {
      first_name: (val) =>
        val.trim() !== "" ? null : "First name is required",
      last_name: (val) => (val.trim() !== "" ? null : "Last name is required"),
      confirm_password: (val, values) =>
        val === values.password ? null : "Passwords do not match",
      terms: (val) => (val ? null : "You must accept the terms"),
    },
    reset: { password: (val) => null },
  };

  return { ...commonRules, ...typeRules[type] };
};

export default function AuthenticationForm(props) {
  const [type, toggle] = useToggle(["login", "register"]);
  const [resetMode, setResetMode] = useState(false);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      terms: false,
    },
    validate: getValidationRules(resetMode ? "reset" : type),
  });

  const handleSubmit = async (e) => {
    setNotification(false);
    setLoading(true);
    let endpoint, userData;

    try {
      if (resetMode) {
        endpoint = "/users/password";
        userData = { email: form.values.email };
      } else if (type === "login") {
        endpoint = "/users/sign_in";
        userData = { email: form.values.email, password: form.values.password };
      } else if (type === "register") {
        endpoint = "/users";
        userData = (({ confirm_password, terms, ...userData }) => userData)(
          form.values
        );
      }

      const response = await fetchApi(endpoint, "POST", { user: userData });

      if (response.ok) {
        const data = await response.json();
        const msg = data.message;
        setNotification({ type: "success", content: msg });

        if (resetMode) {
          console.log("Password reset request sent!");
        } else if (type === "login") {
          console.log("Login successful!");
          window.location.href = data.redirect_to;
        } else if (type === "register") {
          console.log("Registration successful!");
        }
      } else {
        const msg = (await response.json()).error;
        console.log(msg);
        setNotification({ type: "failure", content: msg });
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setLoading(false);
  };

  const renderFormField = (fieldName) => {
    return (
      <React.Fragment key={fieldName}>
        {!(fieldName === "password" || fieldName === "confirm_password") && (
          <TextInput
            required
            label={upperFirst(fieldName.replace("_", " "))}
            placeholder={fieldName.replace("_", " ")}
            value={form.values[fieldName]}
            onChange={(event) =>
              form.setFieldValue(fieldName, event.currentTarget.value)
            }
            error={form.errors[fieldName]}
            radius="md"
            className="w-full"
          />
        )}

        {(fieldName === "password" || fieldName === "confirm_password") && (
          <PasswordInput
            key={fieldName}
            label={upperFirst(fieldName.replace("_", " "))}
            placeholder={fieldName.replace("_", " ")}
            value={form.values[fieldName]}
            onChange={(event) =>
              form.setFieldValue(fieldName, event.currentTarget.value)
            }
            error={form.errors[fieldName]}
            radius="md"
            className="w-full"
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      {notification && <Notification {...notification} />}

      <form onSubmit={form.onSubmit(handleSubmit)}>
        {resetMode ? (
          <>
            <div>
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <div className="flex rounded-full shadow-sm">
                <button
                  type="button"
                  className="w-[2.875rem] h-[2.875rem] flex-shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-s-md border-r-[4px] border-blue-600 bg-white text-white disabled:opacity-50 disabled:pointer-events-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2.0"
                    stroke="#4f46e5"
                    className="w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                    />
                  </svg>
                </button>
                <input
                  type="text"
                  value={form.values["email"]}
                  onChange={(event) =>
                    form.setFieldValue("email", event.currentTarget.value)
                  }
                  id="email"
                  name="email"
                  placeholder="Email"
                  className="py-3 px-4 block w-full border-gray-200 shadow-sm rounded-e-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                />
              </div>
            </div>

            <Group
              spacing={"md"}
              align="start"
              className=" md:flex-row flex-wrap lg:flex-nowrap  lg:m-3 flex-col-reverse mt-5 lg:mt-6 items-center justify-center"
            >
              <Anchor
                className="text-teal-100"
                size="sm"
                onClick={() => {
                  setNotification(null);
                  setResetMode(false);
                }}
              >
                <Center inline>
                  <IconArrowLeft
                    style={{ width: rem(12), height: rem(12) }}
                    stroke={1.5}
                  />
                  <Box ml={5}>Login</Box>
                </Center>
              </Anchor>
              <Button
                type="submit"
                radius="xl"
                className="mx-auto"
                style={{ backgroundColor: "rgb(55, 96, 146)" }}
              >
                Reset Password
              </Button>
            </Group>
          </>
        ) : (
          <>
            <Stack>
              {type === "register" && (
                <Group
                  spacing={"md"}
                  align="start"
                  className="flex-col md:flex-row flex-wrap lg:flex-nowrap lg:mt-4"
                >
                  <div>
                    <label htmlFor="first_name" className="sr-only">
                      First Name
                    </label>
                    <div className="flex rounded-full shadow-sm">
                      <button
                        type="button"
                        className="w-[2.875rem] h-[2.875rem] flex-shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-s-md border-r-[4px] border-blue-600 bg-white text-white disabled:opacity-50 disabled:pointer-events-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2.0"
                          stroke="#4f46e5"
                          className="w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                          />
                        </svg>
                      </button>
                      <input
                        type="text"
                        value={form.values["first_name"]}
                        onChange={(event) =>
                          form.setFieldValue(
                            "first_name",
                            event.currentTarget.value
                          )
                        }
                        id="first_name"
                        name="first_name"
                        placeholder="First Name"
                        className="py-3 px-4 block w-full border-gray-200 shadow-sm rounded-e-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="last_name" className="sr-only">
                      Last Name
                    </label>
                    <div className="flex rounded-full shadow-sm">
                      <button
                        type="button"
                        className="w-[2.875rem] h-[2.875rem] flex-shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-s-md border-r-[4px] border-blue-600 bg-white text-white disabled:opacity-50 disabled:pointer-events-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2.0"
                          stroke="#4f46e5"
                          className="w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                          />
                        </svg>
                      </button>
                      <input
                        type="text"
                        value={form.values["last_name"]}
                        onChange={(event) =>
                          form.setFieldValue(
                            "last_name",
                            event.currentTarget.value
                          )
                        }
                        id="last_name"
                        name="last_name"
                        placeholder="Last Name"
                        className="py-3 px-4 block w-full border-gray-200 shadow-sm rounded-e-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      />
                    </div>
                  </div>
                </Group>
              )}

              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <div className="flex rounded-full shadow-sm">
                  <button
                    type="button"
                    className="w-[2.875rem] h-[2.875rem] flex-shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-s-md border-r-[4px] border-blue-600 bg-white text-white disabled:opacity-50 disabled:pointer-events-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2.0"
                      stroke="#4f46e5"
                      className="w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                      />
                    </svg>
                  </button>
                  <input
                    type="text"
                    value={form.values["email"]}
                    onChange={(event) =>
                      form.setFieldValue("email", event.currentTarget.value)
                    }
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="py-3 px-4 block w-full border-gray-200 shadow-sm rounded-e-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  />
                </div>
              </div>

              <Group
                spacing={"md"}
                align="start"
                className="flex-col md:flex-row flex-wrap lg:flex-nowrap"
              >
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <div className="flex rounded-full shadow-sm">
                    <button
                      type="button"
                      className="w-[2.875rem] h-[2.875rem] flex-shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-s-md border-r-[4px] border-blue-600 bg-white text-white disabled:opacity-50 disabled:pointer-events-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2.0"
                        stroke="#4f46e5"
                        className="w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                        />
                      </svg>
                    </button>
                    <input
                      type="password"
                      value={form.values["passeord"]}
                      onChange={(event) =>
                        form.setFieldValue(
                          "password",
                          event.currentTarget.value
                        )
                      }
                      id="password"
                      name="password"
                      placeholder="Password"
                      className="py-3 px-4 block w-full border-gray-200 shadow-sm rounded-e-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    />
                  </div>
                </div>

                {type === "register" && (
                  <div>
                    <label htmlFor="confirm_password" className="sr-only">
                      Confirm Password
                    </label>
                    <div className="flex rounded-full shadow-sm">
                      <button
                        type="button"
                        className="w-[2.875rem] h-[2.875rem] flex-shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-s-md border-r-[4px] border-blue-600 bg-white text-white disabled:opacity-50 disabled:pointer-events-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2.0"
                          stroke="#4f46e5"
                          className="w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                          />
                        </svg>
                      </button>
                      <input
                        type="password"
                        value={form.values["confirm_password"]}
                        onChange={(event) =>
                          form.setFieldValue(
                            "confirm_password",
                            event.currentTarget.value
                          )
                        }
                        id="confirm_password"
                        name="confirm_password"
                        placeholder="Confirm Password"
                        className="py-3 px-4 block w-full border-gray-200 shadow-sm rounded-e-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      />
                    </div>
                  </div>
                )}
              </Group>

              {type === "register" && (
                <Checkbox
                  className="text-white"
                  styles={{
                    label: { color: "white" },
                  }}
                  label={
                    <>
                      I accept nowcollab{" "}
                      <Anchor
                        href="/welcome/terms_and_agreements"
                        target="_blank"
                        underline
                        c="green"
                      >
                        terms and conditions
                      </Anchor>
                    </>
                  }
                  checked={form.values.terms}
                  onChange={(event) =>
                    form.setFieldValue("terms", event.currentTarget.checked)
                  }
                />
              )}

              <Group position="center">
                <Button
                  type="submit"
                  radius="xl"
                  style={{ backgroundColor: "rgb(55, 96, 146)" }}
                  loading={loading}
                >
                  {type === "register" ? "Create account" : "Log in"}
                </Button>
              </Group>
            </Stack>

            <Divider
              label="Or continue with"
              labelPosition="center"
              my="lg"
              variant="dotted"
            />

            <Group grow mb="md" mt="md" className="w-full">
              <GoogleButton radius="xl">Google</GoogleButton>
              <LinkedInButton radius="xl">LinkedIn</LinkedInButton>
            </Group>

            <Group position="center" mt="xl">
              <Anchor
                component="button"
                type="button"
                className="text-teal-100"
                onClick={() => {
                  setNotification(null);
                  toggle();
                }}
              >
                {type === "register" ? "Login" : "Signup"}
              </Anchor>

              {type === "login" && (
                <Anchor
                  component="button"
                  type="button"
                  className="text-teal-100"
                  onClick={() => {
                    setNotification(null);
                    setResetMode(true);
                  }}
                >
                  Reset password
                </Anchor>
              )}
            </Group>
          </>
        )}
      </form>
    </>
  );
}
