import {
  ActionIcon,
  Avatar,
  Button,
  Card,
  Group,
  Modal,
  NumberInput,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  Title,
  rem,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import fetchApi from "../Lib/api";
import { useDisclosure } from "@mantine/hooks";
import { IconUserDollar } from "@tabler/icons-react";
import Notification from "../Lib/Notification";
import Markdown from "../Lib/Markdown";

export default function Funding({ funding, slug, setStartup }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [equity, setEquity] = useState(funding?.equity || 0);
  const [valuation, setValuation] = useState(funding?.valuation || 0);
  const [investors_details, setInvestorDetails] = useState(
    funding?.investors_details || ""
  );
  const [ask, setAsk] = useState(funding?.ask || 0);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (ask && equity) {
      console.log("Ask:", ask);
      console.log("Equity:", equity);

      const equityDecimal = equity / 100;
      const valuation = ask / equityDecimal;
      console.log("Valuation:", valuation);

      const valuationFixed = parseFloat(valuation.toFixed(2));
      console.log("Valuation (Fixed):", valuationFixed);

      setValuation(valuationFixed);
    }
  }, [ask, equity]);

  const handleRequest = async () => {
    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/fundings/add_funding_details`,
        "POST",
        {
          funding: {
            equity,
            ask,
            valuation,
            investors_details,
          },
        }
      );

      if (response.ok) {
        const res = await response.json();
        setNotification({ type: "success", content: res.message });
        setStartup((prev) => ({
          ...prev,
          startup_entity: { ...prev.startup_entity, funding: res.funding },
        }));
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  return (
    <>
      {opened && (
        <Modal
          opened={opened}
          size="lg"
          onClose={close}
          withCloseButton={false}
        >
          {notification && <Notification {...notification} />}
          <Stack spacing={18} className="p-3">
            <Title order={5}>Provide a funding bid</Title>
            <NumberInput
              label="Asking Price ($)"
              value={ask}
              onChange={setAsk}
            />
            <NumberInput
              label="Provided Equity (%)"
              onChange={setEquity}
              value={equity}
              min={1}
              max={100}
            />
            <TextInput
              label="Valuation"
              onChange={setValuation}
              disabled
              value={valuation}
            />

            <Markdown
              value={investors_details}
              visibleDragbar={false}
              preview={"edit"}
              hideToolbar={false}
              height={300}
              setValue={(e) => {
                setInvestorDetails(e);
              }}
            />

            <Button
              onClick={handleRequest}
              variant="default"
              fullWidth
              className="w-full px-12"
              disabled={!(ask && equity && valuation)}
            >
              Add Funding details
            </Button>
          </Stack>
        </Modal>
      )}
      <Button
        leftIcon={<IconUserDollar size="1rem" />}
        onClick={open}
        variant="default"
        fullWidth
        loading={loading}
      >
        Manage Funding
      </Button>
    </>
  );
}
