import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Card,
  Group,
  HoverCard,
  Menu,
  Modal,
  Paper,
  Radio,
  ScrollArea,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Timeline,
  Title,
  rem,
} from "@mantine/core";

import {
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconCalendarTime,
  IconChecks,
  IconChevronDown,
  IconChevronUp,
  IconInfoCircle,
  IconPackageExport,
  IconPencilDown,
  IconPlus,
  IconQuestionMark,
  IconReport,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
  useCallback,
} from "react";
import diff_match_patch from "diff-match-patch";

import Editor from "./TextEditor";
import fetchApi from "../Lib/api";
import { UserContext } from "../Lib/UserContext";
import { IconPencil } from "@tabler/icons-react";
import { IconCheck } from "@tabler/icons-react";
import Markdown from "../Lib/Markdown";
import moment from "moment";
import RoadmapPDFGenerator from "./RoadmapPDFGenerator";
import { cleanText } from "../Lib/profanityCleaner";
import { DatePicker } from "@mantine/dates";

function PremiumAlert() {
  return (
    <HoverCard width={280} shadow="md">
      <HoverCard.Target>
        <IconInfoCircle size="1rem" color="green" />
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text size="sm">
          These field is only available to premium members.{" "}
          <Anchor href="#">visit here for more information.</Anchor>
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

function computeDiff(oldHtml, newHtml) {
  const dmp = new diff_match_patch();
  const diffs = dmp.diff_main(oldHtml, newHtml);
  dmp.diff_cleanupSemantic(diffs);
  dmp.diff_cleanupEfficiency(diffs); // Ignore whitespace
  return diffs;
}

function formatDiffAsMarkdown(diffs, user) {
  let markdown = "";

  diffs.forEach(([op, text]) => {
    switch (op) {
      case 0: // Unchanged
        markdown += `${text}\n`;
        break;
      case -1: // Deletion
        markdown += `${"```diff\n"}- ${text.replace(
          /\n/g,
          "\n- "
        )} ${"\n```\n"}`;
        break;
      case 1: // Insertion
        markdown += `${"```diff\n"}+ ${text.replace(/\n/g, "\n+ ")} ${
          "\n```\n[@" + user.first_name + "](/profile/user/" + user.id + ")\n"
        }`;
        break;
    }
  });

  markdown += "\n";
  return markdown;
}

export default function TimelineList({
  programs,
  canEdit,
  owner,
  role,
  member,
  members,
  setProgram,
  selectedPrograms,
  setSelectedPrograms,
  open,
  opened,
  close,
  startActivity,
  setStartActivity,
  slug,
  children,
}) {
  const [fullPrograms, setFullPrograms] = useState(3);
  const [guideActivity, setGuideActivity] = useState("Guide");
  const [content, setContent] = useState([{}]);
  const [privacy, setPrivacy] = useState([]);
  const [files, setFiles] = useState([]);
  const [enableEdit, setEnableEDit] = useState(false);
  const {
    user: { user },
  } = useContext(UserContext);

  useEffect(() => {
    setContent(
      programs.categories[selectedPrograms.cid].sub_categories.map((s) => ({
        response: s.response === null ? "" : s.response,
        touched: false,
      }))
    );

    setFiles(
      Array(
        programs.categories[selectedPrograms.cid].sub_categories.length
      ).fill([])
    );

    setPrivacy(
      programs.categories[selectedPrograms.cid].sub_categories.map(
        (s) => s.privacy
      )
    );
  }, [selectedPrograms]);

  const handleSave = async (index, setLoading) => {
    setLoading(true);

    if (!content[index].touched && !files[index].length) return;

    const id =
      programs.categories[selectedPrograms.cid].sub_categories[index].id;

    const oldHtml =
      programs.categories[selectedPrograms.cid].sub_categories[index].response;

    const newHtml = cleanText(content[index].response);

    const diffs = computeDiff(oldHtml || "", newHtml || "");

    const markdownDiff = formatDiffAsMarkdown(diffs, user);

    try {
      const formData = new FormData();
      files[index].forEach((file, index) => {
        formData.append(`sub_category[files][]`, file);
      });
      formData.append("sub_category[privacy]", privacy[index]);
      if (owner) formData.append("sub_category[response]", newHtml);
      else {
        formData.append("sub_category[proposed_response]", newHtml);
        formData.append("sub_category[diff_proposed_response]", markdownDiff);
      }

      const response = await fetchApi(
        `/sub_categories/${id}`,
        "PATCH",
        formData,
        true
      );

      if (response.ok) {
        const responseData = await response.json();

        setProgram(responseData.program);
        close();
      } else {
        console.error("HTTP error! Failed to submit form");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    setLoading(false);
  };

  const TimelineItems = useCallback(
    (prog) => {
      let timelineItems = [];

      prog.forEach((program, index) => {
        if (program && program.is_active) {
          timelineItems.push(
            <Timeline.Item
              key={program.name}
              bullet={
                <ThemeIcon
                  size={20}
                  variant="gradient"
                  style={{ marginTop: "10px", cursor: "pointer" }}
                  gradient={{ from: "rgba(40, 0, 189, 1)", to: "blue" }}
                >
                  <IconReport size="0.9rem" />
                </ThemeIcon>
              }
            >
              <CategoryWithSubcategories
                key={`category-${index}`}
                category={program}
                index={index}
                setSelectedPrograms={setSelectedPrograms}
                owner={owner}
                member={member}
                enableEdit={enableEdit}
                setProgram={setProgram}
              />
            </Timeline.Item>
          );
        }
      });

      return timelineItems;
    },
    [enableEdit]
  );

  const memoizedTimelineItems = useMemo(
    () => TimelineItems(programs.categories.slice(0, fullPrograms)),
    [programs, fullPrograms, enableEdit]
  );

  const handleCardLeftClick = useCallback(() => {
    if (guideActivity !== "Guide") setGuideActivity("Guide");
    if (
      programs.categories[selectedPrograms.cid].sub_categories[
        selectedPrograms.sid - 1
      ]
    )
      setSelectedPrograms((p) => ({ ...p, sid: p.sid - 1 }));
    else {
      if (programs.categories[selectedPrograms.cid - 1])
        setSelectedPrograms((p) => ({
          cid: p.cid - 1,
          sid: programs.categories[p.cid - 1].sub_categories.length - 1,
        }));
    }
  }, [guideActivity, programs, selectedPrograms, setSelectedPrograms]);

  const handleCardRightClick = useCallback(() => {
    console.log(selectedPrograms);
    if (guideActivity !== "Guide") setGuideActivity("Guide");
    if (
      programs.categories[selectedPrograms.cid].sub_categories[
        selectedPrograms.sid + 1
      ]
    )
      setSelectedPrograms((p) => ({ ...p, sid: p.sid + 1 }));
    else setSelectedPrograms((p) => ({ cid: p.cid + 1, sid: 0 }));
  }, [guideActivity, programs, selectedPrograms, setSelectedPrograms]);

  return (
    <>
      {opened && (
        <Modal.Root
          opened={opened}
          onClose={() => {
            setStartActivity(false);
            setGuideActivity("Guide");
            close();
          }}
          yOffset="1vh"
          p={0}
          xOffset="0"
          size="calc(100vw - 15%)"
          className="ml-auto"
          transitionProps={{
            transition: "fade",
            duration: 600,
            timingFunction: "linear",
          }}
        >
          <Modal.Overlay />
          <Modal.Content className="lg:ml-[10vh]">
            <Modal.Body>
              <main className="flex mx-auto" style={{ minHeight: "94vh" }}>
                {!startActivity && selectedPrograms ? (
                  <div className="max-w-7xl w-full lg:p-6 mx-auto">
                    <Title order={3} className="text-gray-700">
                      {`${selectedPrograms.cid}. ${
                        programs.categories[selectedPrograms.cid].name
                      }`}
                    </Title>
                    <Stack spacing={4} className="pt-10 h-[90%]">
                      <Title order={2} className="text-gray-700 mb-5">
                        {
                          programs.categories[selectedPrograms.cid]
                            .sub_categories[selectedPrograms.sid].name
                        }
                      </Title>
                      <Card radius="md" px={0}>
                        <Card.Section inheritPadding p="xs">
                          <SegmentedControl
                            className="w-2/5 ml-5"
                            size="md"
                            key={selectedPrograms.sid}
                            value={guideActivity}
                            radius="xl"
                            onChange={(e) => setGuideActivity(e)}
                            data={
                              programs.categories[selectedPrograms.cid]
                                .sub_categories[selectedPrograms.sid].response
                                ? ["Activity", "Guide"]
                                : ["Guide"]
                            }
                          />
                        </Card.Section>

                        {guideActivity === "Guide" && (
                          <Markdown
                            value={
                              programs.categories[selectedPrograms.cid]
                                .sub_categories[selectedPrograms.sid].guide ||
                              ""
                            }
                            visibleDragbar={false}
                            preview={"preview"}
                            hideToolbar={true}
                            setValue={(e) => {}}
                            height={500}
                          />
                        )}
                      </Card>
                      <Group
                        position="right"
                        align="start"
                        spacing={20}
                        my={15}
                      >
                        {guideActivity !== "Guide" && (
                          <Card radius="md" withBorder className="flex-grow">
                            <Title order={4} size="h4" fw={700}>
                              Attached Files
                            </Title>
                            <ScrollArea h={120} type="auto">
                              {
                                <ul>
                                  {programs.categories[
                                    selectedPrograms.cid
                                  ].sub_categories[
                                    selectedPrograms.sid
                                  ].files.map((file, index) => (
                                    <li key={index}>
                                      <Anchor href={file.url} target="blank">
                                        <Text className="mt-2 px-2 text-base text-gray-600 hover:underline">
                                          {file.url.split("/").pop()}
                                        </Text>
                                      </Anchor>
                                    </li>
                                  ))}
                                </ul>
                              }
                            </ScrollArea>
                          </Card>
                        )}
                        <Button
                          onClick={() => setStartActivity(true)}
                          variant="default"
                          fullWidth
                          className="w-fit px-12 mb-5"
                        >
                          {programs.categories[selectedPrograms.cid]
                            .sub_categories[selectedPrograms.sid].response
                            ? "Edit Activity"
                            : " Start Activity"}
                        </Button>
                      </Group>
                      <Group position="apart" className="mt-auto" grow>
                        {(programs.categories[selectedPrograms.cid - 1] ||
                          programs.categories[selectedPrograms.cid]
                            .sub_categories[selectedPrograms.sid - 1]) && (
                          <Card
                            withBorder
                            className="hover:bg-gray-100 cursor-pointer self-end max-w-lg mr-auto"
                            onClick={handleCardLeftClick}
                          >
                            <Group position="apart">
                              <IconArrowLeft />
                              <Stack spacing={0}>
                                {programs.categories[selectedPrograms.cid]
                                  .sub_categories[selectedPrograms.sid - 1] ? (
                                  <>
                                    <Title order={5} className="text-gray-700">
                                      {`${selectedPrograms.cid}. ${
                                        programs.categories[
                                          selectedPrograms.cid
                                        ].name
                                      }`}
                                    </Title>
                                    <Title order={3} className=" text-gray-600">
                                      {
                                        programs.categories[
                                          selectedPrograms.cid
                                        ].sub_categories[
                                          selectedPrograms.sid - 1
                                        ].name
                                      }
                                    </Title>
                                  </>
                                ) : (
                                  <>
                                    <Title order={5} className="text-gray-700">
                                      {`${selectedPrograms.cid - 1}. ${
                                        programs.categories[
                                          selectedPrograms.cid - 1
                                        ].name
                                      }`}
                                    </Title>
                                    <Title order={3} className=" text-gray-600">
                                      {
                                        programs.categories[
                                          selectedPrograms.cid - 1
                                        ].sub_categories[
                                          programs.categories[
                                            selectedPrograms.cid - 1
                                          ].sub_categories.length - 1
                                        ].name
                                      }
                                    </Title>
                                  </>
                                )}
                              </Stack>
                            </Group>
                          </Card>
                        )}

                        {(programs.categories[selectedPrograms.cid + 1] ||
                          programs.categories[selectedPrograms.cid]
                            .sub_categories[selectedPrograms.sid + 1]) && (
                          <Card
                            withBorder
                            className="hover:bg-gray-100 cursor-pointer self-end max-w-lg ml-auto"
                            onClick={handleCardRightClick}
                          >
                            <Group position="apart">
                              <Stack spacing={0}>
                                {programs.categories[selectedPrograms.cid]
                                  .sub_categories[selectedPrograms.sid + 1] ? (
                                  <>
                                    <Title order={5} className="text-gray-700">
                                      {`${selectedPrograms.cid}. ${
                                        programs.categories[
                                          selectedPrograms.cid
                                        ].name
                                      }`}
                                    </Title>
                                    <Title order={3} className=" text-gray-600">
                                      {
                                        programs.categories[
                                          selectedPrograms.cid
                                        ].sub_categories[
                                          selectedPrograms.sid + 1
                                        ].name
                                      }
                                    </Title>
                                  </>
                                ) : (
                                  <>
                                    <Title order={5} className="text-gray-700">
                                      {`${selectedPrograms.cid + 1}. ${
                                        programs.categories[
                                          selectedPrograms.cid + 1
                                        ].name
                                      }`}
                                    </Title>
                                    <Title order={3} className=" text-gray-600">
                                      {
                                        programs.categories[
                                          selectedPrograms.cid + 1
                                        ].sub_categories[0].name
                                      }
                                    </Title>
                                  </>
                                )}
                              </Stack>
                              <IconArrowRight />
                            </Group>
                          </Card>
                        )}
                      </Group>
                    </Stack>
                  </div>
                ) : (
                  <>
                    <Editor
                      role={role}
                      owner={owner}
                      member={member}
                      programs={programs}
                      members={members}
                      selectedPrograms={selectedPrograms}
                      close={close}
                      setContent={setContent}
                      content={content}
                      privacy={privacy}
                      setPrivacy={setPrivacy}
                      files={files}
                      setFiles={setFiles}
                      handleSave={handleSave}
                    />
                  </>
                )}
              </main>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      )}
      <Group align="center" className="mb-5" grow>
        <Group position="left" spacing={1} align="end">
          <Title order={4} className="text-gray-700">
            Roadmap
          </Title>
          {owner && (
            <ActionIcon
              component="a"
              href={`#`}
              size={28}
              variant="default"
              aria-label="Edit page link"
              onClick={() => setEnableEDit(!enableEdit)}
            >
              {enableEdit ? (
                <IconX
                  style={{ width: rem(18), height: rem(18) }}
                  stroke={2.5}
                />
              ) : (
                <IconPencil
                  style={{ width: rem(18), height: rem(18) }}
                  stroke={2.5}
                />
              )}
            </ActionIcon>
          )}
        </Group>
        {owner && <>{children}</>}
        {member && <RoadmapPDFGenerator slug={slug} />}
      </Group>
      <ScrollArea.Autosize
        mah={400}
        scrollHideDelay={3000}
        offsetScrollbars={15}
      >
        <Timeline
          color="rgba(40, 0, 189, 1)"
          radius="sm"
          active={1000}
          bulletSize={10}
          lineWidth={1}
          className="w-fit ml-10 mt-5"
        >
          {memoizedTimelineItems}
        </Timeline>
      </ScrollArea.Autosize>
      <Paper radius="md" withBorder mt={10} p="md">
        {canEdit && (
          <>
            <Stack spacing={0}>
              <Text fz="sm" c="dimmed">
                current activity
              </Text>
              <Group spacing={10}>
                <ThemeIcon
                  size={20}
                  variant="gradient"
                  gradient={{ from: "rgba(40, 0, 189, 1)", to: "red" }}
                >
                  <IconQuestionMark size="0.9rem" />
                </ThemeIcon>
                <Title order={4} className="text-gray-600">
                  {
                    programs.categories[programs.current_category_id]
                      .sub_categories[programs.current_subcategory_id]?.name
                  }
                </Title>
              </Group>
            </Stack>
            <Button
              onClick={() => {
                setSelectedPrograms({
                  sid: programs.current_subcategory_id,
                  cid: programs.current_category_id,
                  switch: false,
                });
                open();
              }}
              variant="default"
              fullWidth
              className="w-fit mx-auto my-5"
            >
              Start activity
            </Button>
          </>
        )}
        <Group
          spacing={5}
          align="center"
          position="right"
          className="w-fit mx-auto"
        >
          {fullPrograms == 3 ? (
            <>
              {" "}
              <Anchor
                onClick={() => setFullPrograms(programs.categories.length)}
                target="_blank"
              >
                Expand the programs
              </Anchor>
              <IconArrowDown size={18} color="#1c7ed6" />
            </>
          ) : (
            <>
              {" "}
              <Anchor onClick={() => setFullPrograms(3)} target="_blank">
                Collapse the programs
              </Anchor>
              <IconArrowUp size={18} color="#1c7ed6" />
            </>
          )}
        </Group>
      </Paper>
    </>
  );
}

const CategoryDate = ({ start_date, cId }) => {
  const [opened, setOpened] = useState(false);
  const [date, setDate] = useState(start_date);
  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);

  const handleUpdate = async () => {
    try {
      const response = await fetchApi(
        `/categories/update_dates/${cId}`,
        "PATCH",
        { category: { start_date: newStartDate, end_date: newEndDate } }
      );
      if (response.ok) {
        const res = await response.json();
        setDate(newStartDate);
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during date change:", error);
    }
  };

  return (
    <Menu
      shadow="md"
      opened={opened}
      onChange={setOpened}
      withinPortal
      position="right-start"
    >
      <Menu.Target>
        <Badge
          variant="gradient"
          gradient={{ from: "indigo", to: "cyan" }}
          rightSection={<IconPencilDown size={rem(10)} stroke={1.5} />}
        >
          {moment(date).format("Do MMM")}
        </Badge>
      </Menu.Target>
      <Menu.Dropdown p={20} className="max-w-80">
        <Stack>
          <div>
            <p className="text-sm mb-0 text-gray-900 ">Start date</p>
            <DatePicker
              value={newStartDate}
              onChange={setNewStartDate}
              minDate={new Date()}
            />
          </div>
          <div>
            <p className="text-sm mb-0 text-gray-900 ">End date</p>
            <DatePicker
              value={newEndDate}
              disabled={!newStartDate}
              onChange={setNewEndDate}
              minDate={newStartDate || new Date()}
            />
          </div>
          <Button
            disabled={!newStartDate || !newEndDate}
            variant="default"
            fullWidth
            onClick={() => {
              handleUpdate();
              setOpened(false);
            }}
          >
            Update
          </Button>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
};

function CategoryWithSubcategories({
  category,
  index,
  setSelectedPrograms,
  owner,
  member,
  enableEdit,
  setProgram,
}) {
  const [showSubcategories, setShowSubcategories] = useState(false);
  const [switchToEdit, setSwitchToEdit] = useState(false);
  const [categoryName, setCategoryName] = useState(category.name);
  const [newSubcategoryNames, setNewSubcategoryNames] = useState([]);
  const [subcategoriesToDelete, setSubcategoriesToDelete] = useState([]);
  const [subcategoryNames, setSubcategoryNames] = useState([]);

  useEffect(() => {
    setSubcategoryNames(
      category.sub_categories.map((s) => ({
        name: s.name,
        response: s.response,
        id: s.id,
        privacy: s.privacy,
        is_active: s.is_active,
        is_editable: s.is_editable,
      }))
    );
  }, [category]);

  const handleAddNewField = () => {
    setNewSubcategoryNames((prevNewSubcategoryNames) => [
      ...prevNewSubcategoryNames,
      "",
    ]);
  };

  const handleRemoveNewField = (indexToRemove) => {
    setNewSubcategoryNames((prevNewSubcategoryNames) =>
      prevNewSubcategoryNames.filter((_, index) => index !== indexToRemove)
    );
  };

  const toggleSubcategories = () => {
    setSwitchToEdit(false);
    setShowSubcategories(!showSubcategories);
  };

  const handleSwitchingToEdit = () => {
    setShowSubcategories(true);
    setSwitchToEdit(!switchToEdit);
  };

  const handleCategoryNameChange = (event) => {
    console.log(event.target.value);
    setCategoryName(event.target.value);
  };

  const handleSubcategoryNameChange = useCallback(
    (index, event) => {
      console.log(index);
      setSubcategoryNames((prevSubcategoryNames) => {
        const newSubcategoryNames = [...prevSubcategoryNames];
        newSubcategoryNames[index] = {
          ...newSubcategoryNames[index],
          name: event.target.value,
        };
        return newSubcategoryNames;
      });
    },
    [subcategoryNames]
  );

  const handleNewSubcategoryNameChange = useCallback(
    (index, event) => {
      setNewSubcategoryNames((prevNewSubcategoryNames) => {
        const newSubcategoryNames = [...prevNewSubcategoryNames];
        newSubcategoryNames[index] = event.target.value;
        return newSubcategoryNames;
      });
    },
    [newSubcategoryNames]
  );

  const handleSubcategoryDelete = useCallback(
    (index) => {
      setSubcategoriesToDelete((prevSubcategoriesToDelete) => [
        ...prevSubcategoriesToDelete,
        subcategoryNames[index].id,
      ]);

      console.log(subcategoriesToDelete);

      setSubcategoryNames((prevSubcategoryNames) =>
        prevSubcategoryNames.filter((_, idx) => idx !== index)
      );
    },
    [subcategoryNames, setSubcategoriesToDelete, setSubcategoryNames]
  );

  const handleUpdate = async () => {
    const updatedData = {
      category: {
        name: categoryName,
        sub_categories_attributes: subcategoryNames.map(
          ({ is_active, is_editable, ...rest }) => ({
            ...rest,
          })
        ),
        new_sub_categories_attributes: newSubcategoryNames
          .filter((name) => name.trim() !== "")
          .map((name) => ({
            name,
          })),
        deleted_sub_category_ids: subcategoriesToDelete,
      },
    };

    try {
      const response = await fetchApi(
        `/categories/update_names/${category.id}`,
        "PATCH",
        updatedData
      );

      if (response.ok) {
        const res = await response.json();
        setSwitchToEdit(false);
        const updatedCategory = res.category;
        setProgram((prev) => {
          return {
            ...prev,
            categories: prev.categories.map((c) =>
              c.id == updatedCategory.id ? updatedCategory : c
            ),
          };
        });
        setNewSubcategoryNames([]);
        console.log(res);
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  const timelineItems = useMemo(() => {
    const items = [];

    if (showSubcategories) {
      subcategoryNames.forEach((subcategory, idx) => {
        if (
          subcategory.is_active &&
          (owner ||
            (subcategory.privacy !== "Only me" && member) ||
            (subcategory.privacy === "Everyone" && subcategory.response))
        ) {
          items.push(
            <Timeline.Item
              key={subcategory.id + "subCategory"}
              bullet={
                subcategory.response ? (
                  <ThemeIcon
                    size={18}
                    variant="gradient"
                    style={{ marginTop: "8px", cursor: "pointer" }}
                    gradient={{ from: "rgba(40, 0, 189, 1)", to: "blue" }}
                  >
                    <IconChecks size="0.8rem" />
                  </ThemeIcon>
                ) : (
                  <ThemeIcon
                    size={18}
                    variant="gradient"
                    style={{ marginTop: "8px", cursor: "pointer" }}
                    gradient={{ from: "rgba(40, 0, 189, 1)", to: "red" }}
                  >
                    <IconQuestionMark size="0.8rem" />
                  </ThemeIcon>
                )
              }
            >
              {enableEdit && switchToEdit ? (
                <div
                  className={`${enableEdit ? "flex items-end gap-3" : "mb-4"}`}
                >
                  <TextInput
                    defaultValue={subcategoryNames[idx].name}
                    size="xs"
                    onChange={(event) =>
                      handleSubcategoryNameChange(idx, event)
                    }
                  />
                  <ActionIcon
                    href={`#`}
                    size={24}
                    variant="default"
                    aria-label="Delete subcategory"
                    onClick={() => handleSubcategoryDelete(idx)}
                  >
                    <IconTrash
                      color="red"
                      style={{ width: rem(13), height: rem(13) }}
                    />
                  </ActionIcon>
                </div>
              ) : (
                <Text
                  key={subcategory.id}
                  size="sm"
                  c={subcategory.is_active ? "" : "dimmed"}
                  style={{ marginTop: "-15px", cursor: "pointer" }}
                  onClick={() =>
                    setSelectedPrograms((p) => ({
                      ...p,
                      cid: index,
                      sid: idx,
                      switch: true,
                    }))
                  }
                >
                  <Group spacing={1} noWrap align="center">
                    {subcategory.name}
                    {!subcategory.is_active && <PremiumAlert />}
                  </Group>
                </Text>
              )}
            </Timeline.Item>
          );
        }
      });

      newSubcategoryNames.forEach((name, index) => {
        items.push(
          <Timeline.Item
            key={"new-field" + index}
            bullet={
              <ThemeIcon
                size={18}
                variant="gradient"
                style={{ marginTop: "8px", cursor: "pointer" }}
                gradient={{ from: "rgba(40, 0, 189, 1)", to: "red" }}
              >
                <IconQuestionMark size="0.8rem" />
              </ThemeIcon>
            }
          >
            <div className={`${enableEdit ? "flex items-end gap-3" : "mb-4"}`}>
              <TextInput
                // defaultValue={name}
                value={name}
                size="xs"
                onChange={(event) =>
                  handleNewSubcategoryNameChange(index, event)
                }
              />
              <ActionIcon
                href={`#`}
                size={24}
                variant="default"
                aria-label="Delete subcategory"
                onClick={() => handleRemoveNewField(index)}
              >
                <IconTrash
                  color="red"
                  style={{ width: rem(13), height: rem(13) }}
                />
              </ActionIcon>
            </div>
          </Timeline.Item>
        );
      });

      if (
        enableEdit &&
        switchToEdit &&
        newSubcategoryNames.length + subcategoryNames.length < 5
      )
        items.push(
          <Timeline.Item
            key={"newfield-create"}
            bullet={
              <ThemeIcon
                size={18}
                variant="gradient"
                style={{ marginTop: "8px", cursor: "pointer" }}
                // gradient={{ from: "rgba(40, 0, 189, 1)", to: "blue" }}
              >
                <IconPlus size="0.8rem" />
              </ThemeIcon>
            }
          >
            {" "}
            <Text
              key={category.id}
              size="sm"
              style={{ marginTop: "-15px", cursor: "pointer" }}
              onClick={handleAddNewField}
            >
              Create new field
            </Text>
          </Timeline.Item>
        );
    }

    return items;
  }, [
    newSubcategoryNames,
    subcategoryNames,
    category,
    enableEdit,
    showSubcategories,
    switchToEdit,
  ]);

  return (
    <div>
      <div className={`${enableEdit ? "flex items-end gap-3" : "mb-4"}`}>
        {enableEdit && switchToEdit ? (
          <TextInput
            value={categoryName}
            size="xs"
            onChange={handleCategoryNameChange}
          />
        ) : (
          <Group
            style={{ marginTop: "-15px", cursor: "pointer" }}
            position="apart"
            spacing="xl"
          >
            <Text
              key={`category-${category.id}`}
              className="flex items-center gap-x-1"
              onClick={() => {
                setSelectedPrograms({
                  cid: index,
                  sid: 0,
                  switch: true,
                  edit:
                    category.sub_categories.find((sc) => sc.response) !==
                    undefined,
                });
                toggleSubcategories();
              }}
            >
              {category.name} {!category.is_active && <PremiumAlert />}
              {showSubcategories ? (
                <IconChevronUp style={{ width: rem(15), height: rem(15) }} />
              ) : (
                <IconChevronDown style={{ width: rem(15), height: rem(15) }} />
              )}
            </Text>
            {category.start_date && (
              <CategoryDate
                key={category.start_date}
                start_date={category.start_date}
                cId={category.id}
              />
            )}
          </Group>
        )}

        {enableEdit && (
          <>
            {switchToEdit ? (
              <ActionIcon
                href={`#`}
                size={28}
                variant="default"
                aria-label="Edit page link"
                onClick={handleUpdate}
              >
                <IconCheck
                  style={{ width: rem(18), height: rem(18) }}
                  stroke={2.5}
                />
              </ActionIcon>
            ) : (
              <ActionIcon
                href={`#`}
                size={28}
                variant="default"
                aria-label="Edit page link"
                onClick={handleSwitchingToEdit}
              >
                <IconPencil style={{ width: rem(15), height: rem(15) }} />
              </ActionIcon>
            )}
          </>
        )}
      </div>

      <Timeline
        color="grape"
        radius="sm"
        active={0}
        bulletSize={10}
        lineWidth={1}
        className="w-fit mt-5 my-auto"
      >
        {showSubcategories && timelineItems}
      </Timeline>
    </div>
  );
}
