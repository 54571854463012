import {
  Avatar,
  Button,
  Center,
  FileButton,
  Stack,
  Text,
  Title,
  UnstyledButton,
  Checkbox,
} from "@mantine/core";
import React, { useEffect, useState } from "react";

const SelectComponent = ({ label, selected, onChange }) => {
  return (
    <UnstyledButton
      onClick={() => onChange(label)}
      className={`${"flex w-[300px] border border-solid border-gray-300  rounded-sm p-4 bg-white hover:bg-gray-100 "} ${
        selected ? "selected" : ""
      }`}
    >
      <Checkbox
        checked={selected}
        onChange={onChange}
        tabIndex={-1}
        size="md"
        mr="xl"
        styles={{ input: { cursor: "pointer" } }}
        aria-hidden
      />

      <div>
        <Text fw={700} mb={2} lh={1}>
          {label}
        </Text>
      </div>
    </UnstyledButton>
  );
};

export default function Category({ form }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const selects = ["Professional", "Freelance", "Investor", "Student"];

  useEffect(() => {
    if (form.values.avatar)
      setSelectedImage(URL.createObjectURL(form.values.avatar));
  }, []);

  const handleImageUpload = (file) => {
    if (file) {
      form.setFieldValue("avatar", file);
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  return (
    <>
      <Title order={5} className="my-3">
        Add a profile picture
      </Title>
      <Center>
        <Stack>
          <Avatar
            variant="outline"
            radius="lg"
            size="200px"
            // className="bg-red-500"
            color="rgb(55, 96, 146)"
            src={selectedImage}
          />
          <FileButton
            variant="default"
            color="green"
            onChange={handleImageUpload}
            size="sm"
            defaultValue={""}
            accept="image/png,image/jpeg,image/jpg"
            errors={form.errors.avatar}
          >
            {function (props) {
              return <Button {...props}>Upload image</Button>;
            }}
          </FileButton>
        </Stack>
      </Center>

      <Title order={5} className="my-3">
        Who are you?
      </Title>
      <Text size="md" className="my-3">
        Choose your user type to tailor your experience. Whether you're a
        student, freelance enthusiast, or seasoned professional, your selection
        helps us provide personalized content and services just for you.
      </Text>
      <Center>
        <Stack>
          {selects.map((select, index) => (
            <SelectComponent
              key={index}
              label={select}
              selected={selects[form.values.user_type] === select}
              onChange={(event) => {
                form.setFieldValue("user_type", index);
              }}
            />
          ))}
          <Text color="red" size="md" align="center">
            {form.errors.user_type}
          </Text>
        </Stack>
      </Center>
    </>
  );
}
