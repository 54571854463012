import React, { createContext, useState, useEffect, useRef } from "react";
import fetchApi from "./api";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const intervalIdRef = useRef(null);
  const isFetched = useRef(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch("/api/users/current");

        if (response.status === 403) {
          // Handle unauthorized here, e.g., show a message or redirect
          console.error("Unauthorized access.");
          return; // Return or handle as appropriate
        }

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setUser(data);

          isFetched.current = true;
        } else {
          console.error("Error fetching user:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    // Fetch user details only if not already fetched
    if (!isFetched.current) {
      fetchUser();
    }
  }, []);

  const updateLastSeen = async () => {
    try {
      const response = await fetchApi(
        "/api/users/update_last_seen",
        "POST",
        {}
      );

      if (response.ok) {
        const msg = (await response.json()).message;
        console.log(msg);
      } else {
        const msg = (await response.json()).error;
        console.log(msg);
      }
    } catch (error) {
      console.error("Error during Invitation", error);
    }
  };

  useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      updateLastSeen();
    }, 10 * 60 * 1000); // 5 minutes in milliseconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalIdRef.current);
  }, []);

  return (
    <UserContext.Provider
      value={{ user, setUser, selectedUser, setSelectedUser }}
    >
      {user && children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
