import React, { memo, useEffect, useState } from "react";

import { useDisclosure } from "@mantine/hooks";

import fetchApi from "../../Lib/api";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import {
  Avatar,
  Button,
  Checkbox,
  Collapse,
  Grid,
  Group,
  Skeleton,
  Spoiler,
  Stack,
  TextInput,
} from "@mantine/core";

import HeaderAndSidebar from "../../Lib/HeaderAndSidebar";
import Markdown from "../../Lib/Markdown";

const MemoTopics = memo(Topics);

export default function TopicsContainer({ project }) {
  return (
    <HeaderAndSidebar sidebar={<></>}>
      <MemoTopics />
    </HeaderAndSidebar>
  );
}

function Topics() {
  const { channel_type } = useParams();
  const location = useLocation();
  const [topics, setTopics] = useState([]);
  const [opened, { toggle, open }] = useDisclosure(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [locked, setLocked] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [channelName, setChannelName] = useState("");

  useEffect(() => {
    // Parse query parameters from the location object
    const searchParams = new URLSearchParams(location.search);
    const param1 = searchParams.get("channel");
    setChannelName(param1);

    // Use param1 and param2 as needed...
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/hub/${channel_type}`, "GET");

        if (response.ok) {
          const res = await response.json();
          // setNotification({ type: "success", content: msg });
          console.log(res);
          setTopics(res.topic_views);
          //   setMessageBoards(res);
          // window.location.href = "/startup_entities/1";
        } else {
          const msg = (await response.json()).error;
          // setNotification({ type: "failure", content: msg });
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during password change:", error);
      }
    };

    fetchData(); // Call the async function immediately
  }, []);

  const handleTopicCreate = async (postId) => {
    try {
      const response = await fetchApi(
        `/hub/${channel_type}/create_topic`,
        "POST",
        {
          // messageboard_id: channel_type,
          topic: { title, content, locked, sticky },
        }
      );

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        setTopics(res.topic_views);
        toggle();
        setTitle("");
        setContent("");
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  return (
    <section className="flex justify-center">
      <div className="max-w-[85rem] w-full p-6">
        <ol
          className="flex items-center whitespace-nowrap mb-6"
          aria-label="Breadcrumb"
        >
          <li className="inline-flex items-center">
            <a
              className="flex items-center text-sm text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 dark:focus:text-blue-500"
              href="/dashboard/#/forums"
            >
              Channels
            </a>
            <svg
              className="flex-shrink-0 mx-2 overflow-visible size-4 text-gray-400 dark:text-neutral-600"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m9 18 6-6-6-6" />
            </svg>
          </li>

          <li className="inline-flex items-center">
            <a
              className="flex items-center text-sm text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 dark:focus:text-blue-500"
              href="/dashboard/#/forums"
            >
              {channelName}
              <svg
                className="flex-shrink-0 mx-2 overflow-visible size-4 text-gray-400 dark:text-neutral-600"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </a>
          </li>

          <li
            className="inline-flex items-center text-sm font-semibold text-gray-800 truncate dark:text-gray-200"
            aria-current="page"
          >
            {channel_type.replaceAll("-", " ")}
          </li>
        </ol>

        <Grid className="mx-auto">
          <Grid.Col span={8}>
            <Stack className="max-w-3xl mb-4">
              <TextInput
                onClick={open}
                onChange={(event) => setTitle(event.currentTarget.value)}
                size="md"
                withAsterisk
                value={title}
                label={opened ? "Title" : ""}
                placeholder="Start a new topic"
              />
              <Collapse in={opened}>
                <Stack>
                  <Markdown
                    value={content}
                    visibleDragbar={true}
                    preview={"edit"}
                    hideToolbar={false}
                    height={200}
                    setValue={(e) => {
                      setContent(e);
                    }}
                  />

                  <Group>
                    <Checkbox
                      value={locked}
                      onChange={(event) =>
                        setLocked(event.currentTarget.checked)
                      }
                      label="Locked"
                    />
                    <Checkbox
                      value={sticky}
                      onChange={(event) =>
                        setSticky(event.currentTarget.checked)
                      }
                      label="Sticky"
                    />
                  </Group>

                  <Group>
                    <Button
                      onClick={handleTopicCreate}
                      variant="default"
                      fullWidth
                      className="w-fit"
                      disabled={!title || !content}
                      // leftIcon={<IconMessage size="1rem" />}
                    >
                      Create new topic
                    </Button>{" "}
                    <Button
                      onClick={toggle}
                      variant="default"
                      fullWidth
                      className="w-fit"
                      // leftIcon={<IconMessage size="1rem" />}
                    >
                      Cancel
                    </Button>
                  </Group>
                </Stack>
              </Collapse>
            </Stack>
            <ul className="flex flex-col gap-4 max-w-3xl">
              {topics.map(({ topic }) => (
                <TopicListItem
                  topic={topic}
                  channel_type={channel_type}
                  key={topic.id}
                  channelName={channelName}
                />
              ))}
            </ul>
          </Grid.Col>
          <Grid.Col span={4}>
            <Skeleton
              visible={true}
              height={600}
              width="80%"
              radius="xl"
              animate={false}
            >
              Lorem ipsum dolor sit amet...
              {/* other content */}
            </Skeleton>
          </Grid.Col>
        </Grid>
      </div>
    </section>
  );
}

const RenderPost = ({ post, channel_type }) => {
  return (
    <Spoiler
      maxHeight={channel_type ? 100 : 100000}
      showLabel="Show more"
      hideLabel="Hide"
    >
      <Markdown
        value={post}
        visibleDragbar={false}
        preview={"preview"}
        hideToolbar={true}
        height={300}
        setValue={(e) => {}}
      />
    </Spoiler>
  );
};

export function TopicListItem({ topic, channel_type, channelName }) {
  const {
    title,
    id,
    follow,
    read_state,
    user,
    first_post,
    last_post_at,
    posts_count,
    last_user,
    slug,
  } = topic;

  return (
    <li
      className="flex flex-col bg-white border shadow-sm rounded-xl "
      key={id}
    >
      <div className="flex justify-between items-center border-b rounded-t-xl py-3 px-4 md:px-5 ">
        <h3 className="text-lg font-bold text-gray-800 dark:text-white">
          {title}
        </h3>

        {read_state.unread_posts_count ? (
          <button
            type="button"
            className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          >
            Unread posts
            <span className="inline-flex items-center py-0.5 px-1.5 rounded-full text-xs font-medium bg-red-500 text-white">
              {read_state.unread_posts_count}
            </span>
          </button>
        ) : (
          <></>
        )}
      </div>
      <div className="p-1 md:p-4">
        <Group position="apart" mx={10}>
          <a href={`/profile/user/${user.id}`}>
            <div className="flex items-center space-x-4 font-roboto">
              <Avatar src={user.avatar_url} size={40} radius="md" />
              <div>
                <p className="text-sm font-semibold">
                  {user.first_name} {user.last_name}
                </p>
                <p className="text-xs text-gray-500">{user.job_title}</p>
              </div>
            </div>
          </a>

          <button
            type="button"
            className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:bg-blue-100 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:bg-blue-800/30 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          >
            {follow ? "UnFollow" : "Follow"}
          </button>
        </Group>

        <RenderPost post={first_post.content} channel_type={true} />
      </div>
      {channel_type && (
        <div className="bg-gray-200 border-t rounded-b-xl py-1 px-4 md:py-2 md:px-5 dark:bg-slate-900 dark:border-gray-700">
          <Group position="apart">
            <a
              href={`/channel/${channel_type}/${slug}?channel=${channelName}`}
              className="inline-flex items-center text-sm font-medium text-blue-600 dark:text-blue-500 hover:underline"
            >
              View Replies ({posts_count - 1})
              <svg
                className="w-4 h-4 ms-2 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
            <Group spacing={2} align="center">
              <a href={`/profile/user/${last_user.id}`}>
                <div className="inline-flex flex-nowrap items-center bg-white border border-gray-200 rounded-full p-1.5 pe-3 ">
                  <Avatar
                    src={last_user.avatar_url}
                    size={25}
                    radius="lg"
                    mr={5}
                  />
                  <div className="mt-1 text-sm text-gray-500 dark:text-gray-500">
                    {last_user.first_name} reply{" "}
                    {moment(last_post_at).fromNow()}
                  </div>
                </div>
              </a>
            </Group>
          </Group>
        </div>
      )}
    </li>
  );
}
