import React, { useContext, useEffect, useState, useCallback } from "react";
import { Avatar, Group, Stack, ThemeIcon, Title } from "@mantine/core";
import { IconMessages } from "@tabler/icons-react";
import moment from "moment";
import fetchApi from "../../Lib/api";
import App from "../../../channels/consumer";
import { UserContext } from "../../Lib/UserContext";
import { useLocation } from "react-router-dom";

export default function Messages() {
  const [contacts, setContacts] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [typingStatus, setTypingStatus] = useState({});
  const [presenceStatus, setPresenceStatus] = useState({});
  const location = useLocation();

  const updateTypingStatus = (userId, isTyping) => {
    setTypingStatus((prev) => ({
      ...prev,
      [userId]: isTyping,
    }));
  };

  const updatePresenceStatus = (userId, presence) => {
    setPresenceStatus((prev) => ({
      ...prev,
      [userId]: presence,
    }));
  };

  useEffect(() => {
    const chatParams = new URLSearchParams(location.search);
    const chatId = chatParams.get("user");
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/chats`, "GET");
        if (response.ok) {
          const res = await response.json();
          setContacts(res);
          console.log(res);
          if (res.length) setSelectedChat(+chatId || res[0].id);
        } else {
          const { error } = await response.json();
          console.error(error);
        }
      } catch (error) {
        console.error("Error during fetching chats:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <main className="flex justify-center">
      <div className="max-w-7xl w-full p-3 lg:p-6">
        <Stack spacing={20}>
          <Group>
            <ThemeIcon
              variant="gradient"
              size="lg"
              aria-label="Gradient action icon"
              gradient={{ from: "blue", to: "rgba(8, 0, 18, 1)", deg: 90 }}
            >
              <IconMessages size={20} />
            </ThemeIcon>
            <Title order={3} className="text-gray-600">
              Messages
            </Title>
          </Group>
          {contacts.length ? (
            <div className="flex gap-1 lg:gap-4">
              <div className="lg:w-2/3 mt-4">
                <h5 className="hidden lg:flex px-6 mb-4 text-16 font-semibold">
                  Recent Messages
                </h5>
                <ContactList
                  contacts={contacts}
                  selectedChat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  typingStatus={typingStatus}
                  presenceStatus={presenceStatus}
                />
              </div>
              <DisplayMessages
                chatId={selectedChat}
                updateTypingStatus={updateTypingStatus}
                updatePresenceStatus={updatePresenceStatus}
              />
            </div>
          ) : (
            <NoChatsFound />
          )}
        </Stack>
      </div>
    </main>
  );
}

function ContactList({
  contacts,
  selectedChat,
  setSelectedChat,
  typingStatus,
  presenceStatus,
}) {
  return (
    <ul className="divide-y divide-gray-200">
      {contacts.map(({ recipient, last_message, id }) => (
        <ContactItem
          key={id}
          recipient={recipient}
          lastMessage={last_message}
          id={id}
          setSelectedChat={setSelectedChat}
          selectedChat={selectedChat}
          isTyping={typingStatus[recipient.id]}
          presenceStatus={presenceStatus[recipient.id]}
        />
      ))}
    </ul>
  );
}

function getPresenceClasses(presenceStatus) {
  switch (presenceStatus) {
    case "online":
      return "bg-green-500";
    case "away":
      return "bg-yellow-500";
    case "offline":
    default:
      return "bg-gray-500";
  }
}

function ContactItem({
  recipient,
  lastMessage,
  id,
  setSelectedChat,
  selectedChat,
  isTyping,
  presenceStatus,
}) {
  const handleClick = () => {
    setSelectedChat(id);
  };

  if (!selectedChat) return <></>;

  return (
    <li
      className={`flex items-center py-2 px-3 lg:py-4 lg:px-6 ${
        id === selectedChat ? "text-white bg-gray-700" : ""
      } transition duration-300 ease-in-out cursor-pointer`}
      onClick={handleClick}
    >
      <div className="lg:mr-4 relative">
        <Avatar
          src={recipient.avatar_url}
          className="rounded-full w-10 h-10"
          alt=""
        />
        <span
          className={`absolute bottom-0 right-0 w-3 h-3 rounded-full ${getPresenceClasses(
            presenceStatus
          )}`}
        ></span>
      </div>
      <div className="flex-grow hidden lg:inline">
        <Group position="apart">
          <h5 className="text-lg font-semibold">
            {recipient.first_name} {recipient.last_name}
          </h5>
          <div className="text-gray-500 text-xs">
            {moment(lastMessage?.created_at).fromNow()}
          </div>
        </Group>
        <p className="text-gray-500 text-sm line-clamp-2">
          {isTyping ? "Typing..." : lastMessage?.content}
        </p>
      </div>
    </li>
  );
}

function NoChatsFound() {
  return (
    <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
      <svg
        className="size-10 text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="29"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="22" x2="2" y1="12" y2="12" />
        <path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
        <line x1="6" x2="6.01" y1="16" y2="16" />
        <line x1="10" x2="10.01" y1="16" y2="16" />
      </svg>
      <p className="mt-5 text-md text-gray-800">
        No any record of chats found.
      </p>
    </div>
  );
}

export function DisplayMessages({
  chatId,
  updateTypingStatus,
  updatePresenceStatus,
}) {
  const [messages, setMessages] = useState([]);
  const [recipient, setRecipient] = useState({});
  const [newMessageContent, setNewMessageContent] = useState("");
  const [userChannel, setUserChannel] = useState(null);

  const {
    user: {
      user: { id },
    },
  } = useContext(UserContext);

  let typingTimeout = null;

  useEffect(() => {
    if (!chatId) return;
    // Fetch initial messages from the server
    const fetchMessages = () => {
      fetch(`/chats/${chatId}/fetch_messages`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setRecipient(data.recipient);
          setMessages(data.messages);
          markMessagesAsRead(data.messages);
        })
        .catch((error) => console.error("Error fetching messages:", error));
    };

    const markMessagesAsRead = (messages) => {
      messages.forEach((message) => {
        if (!message.read_at && message.user_id !== id) {
          subscription.perform("mark_as_read", {
            message_id: message.id,
          });
        }
      });
    };

    const subscription = App.subscriptions.create(
      { channel: "UserChannel", user_id: id },
      {
        received: (data) => {
          if (data.type === "message" && data.message.chat_id === chatId) {
            setMessages((prevMessages) => [data.message, ...prevMessages]);
            markMessagesAsRead([data.message]);
          } else if (data.type === "typing" && data.user !== id) {
            if (updateTypingStatus) updateTypingStatus(data.user, true);
            clearTimeout(typingTimeout);
            typingTimeout = setTimeout(() => {
              if (updateTypingStatus) updateTypingStatus(data.user, false);
            }, 2000);
          } else if (data.type === "read_receipt") {
            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.id === data.message_id
                  ? { ...msg, read_at: data.read_at }
                  : msg
              )
            );
          } else if (data.type === "presence") {
            if (updatePresenceStatus)
              updatePresenceStatus(data.user_id, data.presence);
          }
        },
      }
    );

    setUserChannel(subscription);

    fetchMessages();

    const presenceSubscription = App.subscriptions.create("PresenceChannel", {
      received: (data) => {
        if (data.type === "presence") {
          if (updatePresenceStatus)
            updatePresenceStatus(data.user_id, data.presence);
        }
      },
    });

    return () => {
      subscription.unsubscribe();
      presenceSubscription.unsubscribe();
      clearTimeout(typingTimeout);
    };
  }, [chatId, id]);

  const handleTyping = () => {
    clearTimeout(typingTimeout);
    userChannel.perform("typing", {
      recipient_id: recipient?.id,
    });
    typingTimeout = setTimeout(() => {
      // Reset typing status after 2 seconds of inactivity
    }, 2000);
  };

  const handleReceivedMessage = (data) => {
    console.log(data);
    // Update state to include the newly received message
    if (data.message.chat_id === chatId)
      setMessages((prevMessages) => [data.message, ...prevMessages]);
  };

  const handleInputChange = (event) => {
    setNewMessageContent(event.target.value);
  };

  const sendMessage = () => {
    // Send message via WebSocket
    userChannel.send({
      content: newMessageContent,
      recipient_id: recipient.id,
    });
    // Clear input field after sending message
    setNewMessageContent("");
  };

  return (
    <div className="w-full bg-gray-200 h-full flex flex-col justify-between flex-grow ">
      <div className="p-4 border-b bg-white border-gray-200">
        <div className="flex items-center">
          <div className="">
            <a href="#" className="p-2 text-gray-500 user-chat-remove text-16">
              <i className="ri-arrow-left-s-line"></i>
            </a>
          </div>
          <div className="mr-3">
            <Avatar
              src={recipient.avatar_url}
              className="rounded-full h-9 w-9"
              alt=""
            />
          </div>
          <div className="flex-grow overflow-hidden">
            <h5 className="mb-0 truncate text-16 block">
              <a href="#" className="text-gray-800 font-semibold">
                {recipient.first_name} {recipient.last_name}
              </a>
              {/* <i className="text-green-500 ml-1ri-record-circle-fill text-10 "></i> */}
            </h5>
          </div>
        </div>
      </div>
      <ul className="flex flex-col-reverse flex-grow p-4 bg-gray-200 overflow-y-scroll h-[60vh]">
        {messages.map((message, index) => (
          <li
            className={`flex w-full mt-2 space-x-3 ${
              message.user_id === id ? "ml-auto justify-end" : ""
            }`}
            key={index}
          >
            <div
              className={`flex-shrink-0 h-10 w-10 rounded-full bg-gray-300 bg-no-repeat bg-cover ${
                message.user_id === id ? "hidden" : ""
              }`}
              style={{
                backgroundImage: `url(${recipient.avatar_url})`,
              }}
            ></div>
            <div
              className={`w-1/2 ${message.user_id === id ? "order-last" : ""}`}
            >
              <div
                className={`p-3 rounded-lg ${
                  message.user_id === id
                    ? "bg-blue-900 text-white"
                    : "bg-gray-300"
                }`}
              >
                {message.user_id !== id && (
                  <p className="text-xs mb-2">
                    {recipient.first_name} {recipient.last_name}
                  </p>
                )}
                <p className="text-sm">{message.content}</p>
                {message.read_at && (
                  <span className="text-xs text-gray-500 leading-none">
                    Read {moment(message.read_at).fromNow()}
                  </span>
                )}
              </div>
              <span className="text-xs text-gray-500 leading-none">
                {moment(message.created_at).fromNow()}
              </span>
            </div>
          </li>
        ))}
      </ul>
      <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
        <div>
          <button className="flex items-center justify-center text-gray-400 hover:text-gray-600">
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
              ></path>
            </svg>
          </button>
        </div>
        <div className="flex-grow ml-4">
          <div className="relative w-full">
            <input
              type="text"
              className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
              disabled={!chatId}
              value={newMessageContent}
              onChange={(e) => {
                handleInputChange(e);
                handleTyping();
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  sendMessage();
                }
              }}
            />
            <button className="absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div className="ml-4">
          <button
            className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
            onClick={sendMessage}
          >
            <span>Send</span>
            <span className="ml-2">
              <svg
                className="w-4 h-4 transform rotate-45 -mt-px"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                ></path>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
