import React from "react";
import { Link, NavLink } from "react-router-dom";

const CustomNavLink = ({ to, children, ...rest }) => {
  // Check if the router type is HashRouter or BrowserRouter
  const isHashRouter = window.location.hash && window.location.hash !== "";

  // Use Link for BrowserRouter and NavLink for HashRouter
  const NavLinkComponent = isHashRouter ? NavLink : Link;

  return isHashRouter ? (
    <NavLinkComponent to={to.hash} {...rest}>
      {children}
    </NavLinkComponent>
  ) : (
    <a href={to.href} {...rest}>
      {children}
    </a>
  );
};

export default CustomNavLink;
