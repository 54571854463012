import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../Lib/Layout";
import { UserProvider } from "../Lib/UserContext";
import TopicsContainer from "../Forums/Pages/TopicsList";
import PostContainer from "../Forums/Pages/TopicsShow";

export default function AuthenticationPage() {
  return (
    <Layout>
      <UserProvider>
        <Routes>
          <Route path="/channel/:channel_type" element={<TopicsContainer />} />
          <Route
            path="/channel/:channel_type/:post"
            element={<PostContainer />}
          />
        </Routes>
      </UserProvider>
    </Layout>
  );
}
