import React, { useState, useEffect, useContext } from "react";
import App from "../../channels/consumer";
import { Dialog } from "@mantine/core";
import { UserContext } from "../Lib/UserContext";
import moment from "moment";

const ChatComponent = ({ startupId, setOpenChat }) => {
  const [messages, setMessages] = useState([]);
  const [newMessageContent, setNewMessageContent] = useState("");
  const [startupChannel, setStartupChannel] = useState();
  const {
    user: {
      user: { id },
    },
  } = useContext(UserContext);

  useEffect(() => {
    // Fetch initial messages from the server
    fetchMessages();

    // Subscribe to the StartupEntityChannel for real-time updates
    const subscription = App.subscriptions.create(
      { channel: "StartupEntityChannel", startup_entity_slug: startupId },
      {
        received: handleReceivedMessage,
      }
    );

    setStartupChannel(subscription);

    return () => {
      // Unsubscribe from the channel when the component is unmounted
      subscription.unsubscribe();
    };
  }, [startupId]);

  const fetchMessages = () => {
    fetch(`/startup_entities/${startupId}/messages`)
      .then((response) => response.json())
      .then((data) => setMessages(data.messages))
      .catch((error) => console.error("Error fetching messages:", error));
  };

  const handleReceivedMessage = (data) => {
    // Update state to include the newly received message
    setMessages((prevMessages) => [data.message, ...prevMessages]);
  };

  const handleInputChange = (event) => {
    setNewMessageContent(event.target.value);
  };

  const sendMessage = () => {
    // Send message via WebSocket
    startupChannel.send({
      content: newMessageContent,
      user_id: id,
    });
    // Clear input field after sending message
    setNewMessageContent("");
  };

  return (
    <Dialog
      opened={true}
      onClose={() => setOpenChat(false)}
      withCloseButton
      className="max-h-[63vh] bg-gray-300"
      size="xl"
      radius="md"
      withBorder
      p={0}
      pt={35}
    >
      <div>
        <ul className="flex flex-col-reverse flex-grow  p-4 bg-gray-100 overflow-y-scroll h-[48vh]">
          {messages.map((message, index) => {
            return message?.user?.id != id ? (
              <li className="flex w-full mt-2 space-x-3 max-w-xs" key={index}>
                <div
                  className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300 bg-no-repeat bg-cover"
                  style={{
                    backgroundImage: `url(${message.user.avatar_url})`,
                  }}
                ></div>

                <div>
                  <div className="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                    <p className="text-xs mb-2">
                      {message.user.first_name} {message.user.last_name}
                    </p>
                    <p className="text-sm">{message.content}</p>
                  </div>
                  <span className="text-xs text-gray-500 leading-none">
                    {moment(message.created_at).fromNow()}
                  </span>
                </div>
              </li>
            ) : (
              <li
                className="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-end"
                key={index}
              >
                <div>
                  <div className="bg-blue-900 text-white p-3 rounded-l-lg rounded-br-lg">
                    <p className="text-sm">{message.content}</p>
                  </div>
                  <span className="text-xs text-gray-500 leading-none">
                    {moment(message.created_at).fromNow()}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>

        <div className="bg-gray-300 p-3">
          <input
            className="flex items-center w-full rounded px-3 h-8 text-md"
            type="text"
            placeholder="Type your message…"
            value={newMessageContent}
            onChange={handleInputChange}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                sendMessage();
              }
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ChatComponent;
