import React from "react";
import { Routes, Route } from "react-router-dom";
import HashLayout from "../Lib/HashLayout";
import { UserProvider } from "../Lib/UserContext";
import Dashboard from "../Dashbaord/Dashboard";
import Startups from "../Dashbaord/DashboardPages/Startups";
import Forums from "../Dashbaord/DashboardPages/Forums";
import Members from "../Dashbaord/DashboardPages/Members";
import Resources from "../Dashbaord/DashboardPages/Resources";
import Activity from "../Dashbaord/DashboardPages/activities";
import Messages from "../Dashbaord/DashboardPages/Messages";
import OwnStartups from "../Dashbaord/DashboardPages/OwnStartups";
import Workspace from "../Dashbaord/DashboardPages/Workspace";

export default function DashbaordPage() {
  return (
    <HashLayout>
      <UserProvider>
        <Dashboard>
          <Routes>
            <Route path="/" element={<Startups />} />
            <Route path="/forums" element={<Forums />} />
            <Route path="/members" element={<Members />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/activity" element={<Activity />} />
            <Route path="/workspace" element={<Workspace />} />
          </Routes>
        </Dashboard>
      </UserProvider>
    </HashLayout>
  );
}
