import { Group, Anchor } from "@mantine/core";
import React, { useEffect, useState } from "react";
import fetchApi from "../../../Lib/api";
import { IconExternalLink } from "@tabler/icons-react";
import moment from "moment";

export default function ModerationActivity({}) {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchApi(
          `/hub/admin/moderation/activity`,
          "GET"
        );
        if (response.ok) {
          const res = await response.json();
          setPosts(res.posts);
          console.log(res);
        } else {
          console.error((await response.json()).error);
        }
      } catch (error) {
        console.error("Error during data fetching:", error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="max-w-4xl mx-auto bg-white">
        <div className="text-xl font-semibold text-gray-800 p-4 border-b">
          Recent Activity
        </div>
        {posts.map((p, index) => (
          <div
            key={index}
            className="flex items-center gap-4 p-4 hover:bg-gray-50 border-b last:border-b-0"
          >
            <div className="flex items-center space-x-4">
              <img
                src={p.user.avatar_url}
                alt={p.user.name}
                className="w-10 h-10 rounded-full object-cover"
              />

              <Anchor href={`/profile/user/${p.user.id}`}>{p.user.name}</Anchor>
            </div>
            <span>{p.is_topic_starter ? "Started " : "Posted in "} </span>
            <Anchor href={p.postable.url}>
              <Group className="mb-2" position="center" spacing={4}>
                {p.postable.title}
                <IconExternalLink size={18} color="#1c7ed6" />
              </Group>
            </Anchor>
            <span className="text-sm text-gray-600">
              {moment(p.created_at).format("MMM DD YYYY")}
            </span>
          </div>
        ))}
      </div>
    </>
  );
}
