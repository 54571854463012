const industriesData = [
  "Financial Services",
  "Health and Wellness",
  "Education",
  "Legal Services",
  "Technology",
  "Marketing",
  "Hospitality",
  "Engineering",
  "Manufacturing",
  "Biotechnology",
  "Entertainment",
  "Real Estate",
  "Agriculture",
  "Fashion",
  "Art and Design",
  "Research",
  "Environmental",
  "Renewable Energy",
  "Transportation",
  "Sports",
  "Gaming",
  "Government",
  "Security",
  "Retail",
  "E-commerce",
  "Logistics",
  "Insurance",
  "Media and Broadcasting",
  "Food and Beverage",
  "Healthcare",
  "Aviation",
  "Space Exploration",
  "Automotive",
  "Maritime",
  "Culinary",
  "Tourism",
  "Luxury Brands",
  "Non-Profit",
  "Financial Technology",
  "Blockchain",
  "Cryptocurrency",
  "Law",
  "Human Resources",
  "Fitness",
  "Music",
  "Film and Television",
  "Travel",
  "Consulting",
  "Construction",
  "Energy",
  "Telecommunications",
  "Banking",
  "Pharmaceutical",
  "Consumer Goods",
  "Aerospace",
  "Defense",
  "Market Research",
  "Luxury Goods",
  "Social Services",
  "Information Technology",
  "Legal",
  "Gaming",
  "Arts and Entertainment",
  "Environmental",
  "Music and Entertainment",
  "Financial Services",
  "Hospitality and Tourism",
  "Healthcare and Medical",
  "Education and Training",
  "Media and Communications",
  "Government and Public Administration",
  "Retail and Consumer Goods",
  "Transportation and Logistics",
  "Manufacturing and Industrial",
  "Construction and Architecture",
  "Energy and Utilities",
  "Aerospace and Defense",
  "Insurance and Risk Management",
  "Real Estate and Property",
  "Consulting and Business Services",
  "Environmental and Green Technology",
  "Sports and Recreation",
  "Fashion and Beauty",
  "Market Research and Analysis",
  "Renewable Energy and Sustainability",
  "Security and Law Enforcement",
  "Food and Beverage Industry",
  "Fitness and Wellness Industry",
  "Music and Entertainment Sector",
  "Film and Television Production",
  "Travel and Tourism Industry",
  "Culinary Arts and Hospitality Services",
  "Luxury Goods and Services Sector",
  "Non-Profit and Community Development Organizations",
];
const skillsData = [
  "Web Development",
  "Graphic Design",
  "Content Writing",
  "Digital Marketing",
  "SEO",
  "Social Media Management",
  "Data Analysis",
  "Software Development",
  "Project Management",
  "Financial Forecasting",
  "Customer Service",
  "Public Speaking",
  "Video Editing",
  "Photography",
  "Translation",
  "3D Modeling",
  "Animation",
  "Blockchain Development",
  "Cryptocurrency Analysis",
  "Cloud Computing",
  "DevOps",
  "IT Support",
  "Networking",
  "Cybersecurity",
  "Penetration Testing",
  "Data Entry",
  "Virtual Assistance",
  "Legal Consulting",
  "Educational Tutoring",
  "Voice Over",
  "Podcast Editing",
  "Brand Strategy",
  "Market Research",
  "UX/UI Design",
  "Copywriting",
  "Email Marketing",
  "PPC Campaign Management",
  "HR Consulting",
  "Sales Strategy",
  "Product Management",
  "Agile Coaching",
  "Scrum Mastery",
  "Environmental Consulting",
  "Health and Wellness Coaching",
  "Nutrition Planning",
  "Personal Training",
  "Interior Design",
  "Architectural Design",
  "CAD Design",
  "Electrical Engineering",
  "Mechanical Engineering",
  "Chemical Engineering",
  "Civil Engineering",
  "Biotechnology Research",
  "Clinical Trial Management",
  "Pharmaceutical Consulting",
  "Academic Research",
  "Grant Writing",
  "Non-Profit Fundraising",
  "Event Planning",
  "Logistics Coordination",
  "Supply Chain Management",
  "Quality Control",
  "Lean Manufacturing",
  "Six Sigma",
  "Renewable Energy Consulting",
  "Petroleum Engineering",
  "Mining Consultancy",
  "Agricultural Planning",
  "Fisheries Management",
  "Textile Design",
  "Fashion Illustration",
  "Costume Design",
  "Jewelry Design",
  "Ceramics",
  "Printmaking",
  "Sculpture",
  "Creative Writing",
  "Scriptwriting",
  "Historical Research",
  "Archaeological Consulting",
  "Political Analysis",
  "International Relations",
  "Public Policy Consulting",
  "Ethical Hacking",
  "Artificial Intelligence Development",
  "Machine Learning Projects",
  "Quantum Computing Research",
  "Astronomy",
  "Astrophysics Research",
  "Meteorology",
  "Oceanography",
  "Environmental Science",
  "Sustainable Development Projects",
  "Wildlife Conservation",
  "Veterinary Medicine",
  "Dental Consulting",
  "Medical Imaging",
  "Nursing Education",
  "Mental Health Therapy",
  "Physical Therapy",
  "Occupational Therapy",
  "Speech Therapy",
  "Audiology",
  "Chiropractic Services",
  "Acupuncture",
  "Homeopathic Medicine",
  "Ayurvedic Consulting",
  "Personal Finance Advice",
  "Investment Strategy",
  "Crypto Investment Advice",
  "Real Estate Analysis",
  "Property Management",
  "Interior Decorating",
  "Wedding Planning",
  "Culinary Arts",
  "Baking",
  "Mixology",
  "Sommelier Expertise",
  "Brewing Science",
  "Food Safety Consulting",
  "Public Health Advisory",
  "Epidemiology",
  "Disaster Relief Planning",
  "Military Strategy",
  "Defense Contracting",
  "Aviation Management",
  "Aeronautical Engineering",
  "Space Exploration Projects",
  "Maritime Navigation",
  "Shipbuilding",
  "Automotive Engineering",
  "Motorcycle Maintenance",
  "Bicycle Repair",
  "Sports Coaching",
  "Athletic Training",
  "eSports Strategy",
  "Game Development",
  "Game Design",
  "Character Animation",
  "Esports Coaching",
  "VR/AR Development",
  "IoT Solutions",
  "Wearable Technology Development",
  "Smart Home Design",
  "Robotics Engineering",
  "Nanotechnology Research",
  "Patent Law",
  "Copyright Law",
  "Trademark Consulting",
  "Corporate Law",
  "International Law",
  "Human Rights Advocacy",
  "Labor Relations",
  "Union Negotiation",
  "Education Technology",
  "Curriculum Development",
  "Online Course Creation",
  "E-learning Strategy",
  "Childcare Consulting",
  "Elder Care Planning",
  "Genealogical Research",
  "Cultural Heritage Consulting",
  "Art Restoration",
  "Museum Curation",
  "Gallery Management",
  "Music Production",
  "Audio Engineering",
  "Concert Planning",
  "Theater Production",
  "Dance Choreography",
  "Opera Singing",
  "Instrumental Music Teaching",
  "Choir Directing",
  "Band Management",
  "Film Production",
  "Documentary Filmmaking",
  "Cinematography",
  "Film Editing",
  "Special Effects Makeup",
  "Fashion Photography",
  "Travel Writing",
  "Adventure Guiding",
  "Hospitality Management",
  "Tour Operating",
  "Cruise Ship Operations",
  "Airline Customer Service",
  "Airport Management",
  "Space Tourism Planning",
  "Luxury Brand Management",
  "Retail Merchandising",
  "E-commerce Optimization",
  "Dropshipping Management",
  "Affiliate Marketing",
  "Network Marketing",
  "Multi-level Marketing Strategy",
  "Direct Sales",
  "B2B Sales",
  "B2C Sales",
  "Wholesale Distribution",
  "Import/Export Consulting",
  "Trade Compliance",
  "Customs Brokerage",
  "Tariff Negotiation",
  "International Trade Law",
  "Export Controls",
  "Sanctions Advisory",
  "Anti-money Laundering Compliance",
  "Fraud Investigation",
  "Corporate Security",
  "Personal Security",
  "Event Security",
  "Fire Safety Consulting",
  "Occupational Health and Safety",
  "First Aid Training",
  "CPR Instruction",
  "Emergency Preparedness",
  "Crisis Management",
  "Business Continuity Planning",
  "Data Recovery",
  "Cloud Storage Solutions",
  "Database Management",
  "Data Mining",
  "Big Data Analytics",
  "Predictive Modeling",
  "Statistical Analysis",
  "Econometrics",
  "Financial Modeling",
  "Actuarial Science",
  "Insurance Risk Assessment",
  "Reinsurance",
  "Banking Compliance",
  "Financial Regulation Advisory",
  "Investment Banking",
  "Venture Capital",
  "Private Equity",
  "Hedge Fund Management",
  "Mutual Fund Advisory",
  "Stock Market Analysis",
  "Forex Trading",
  "Commodity Trading",
  "Options Trading",
  "Futures Trading",
  "Bond Trading",
  "Cryptocurrency Trading",
  "Payment Processing",
  "FinTech Innovation",
  "Blockchain for Finance",
  "Digital Wallet Integration",
  "Mobile Payment Solutions",
  "Credit Scoring",
  "Personal Loans",
  "Mortgage Consulting",
  "Commercial Lending",
  "Asset Management",
  "Wealth Management",
  "Retirement Planning",
  "Estate Planning",
  "Tax Advisory",
  "AI/ML Development",
  "Content Strategy",
  "Cloud Architecture",
  "Big Data Analysis",
  "Cybersecurity Frameworks",
  "IoT Integration",
  "DevOps Practices",
  "Quantum Computing Research",
  "User Experience Design",
  "User Interface Design",
  "Natural Language Processing",
  "Robotics Programming",
  "Augmented Reality Development",
  "Virtual Reality Development",
  "Blockchain Development",
  "Cryptography",
  "Ethical Hacking",
  "Forensic Analysis",
  "Mobile App Development",
  "Web Application Development",
  "SaaS Product Management",
  "Agile Project Management",
  "Scrum Methodology",
  "Lean Startup Methodology",
  "Business Analytics",
  "Data Visualization",
  "Statistical Analysis",
  "Financial Analysis",
  "Market Analysis",
  "SEO/SEM",
  "Affiliate Marketing Strategy",
  "Social Media Marketing",
  "Email Marketing",
  "Influencer Partnership Management",
  "Public Relations Strategy",
  "Brand Development",
  "Sales Funnel Optimization",
  "Customer Relationship Management",
  "Supply Chain Optimization",
  "Logistics Planning",
  "Manufacturing Process Design",
  "Quality Assurance Testing",
  "Regulatory Compliance",
  "Legal Research",
  "Intellectual Property Law",
  "Environmental Law",
  "Contract Negotiation",
  "Mediation and Arbitration",
  "Education Curriculum Design",
  "E-Learning Development",
  "Corporate Training",
  "Health and Wellness Coaching",
  "Nutrition Planning",
  "Fitness Training Program Design",
  "Mental Health Support Services",
  "Medical Research",
  "Clinical Trial Design",
  "Pharmaceutical Sales",
  "Renewable Energy Systems",
  "Environmental Impact Assessment",
  "Urban Planning",
  "Real Estate Development",
  "Interior Design",
  "Fashion Design",
  "Graphic Design",
  "Illustration",
  "Photography",
  "Videography",
  "Film Editing",
  "Music Production",
  "Audio Engineering",
  "Podcast Production",
  "Voice Acting",
  "Performance Arts",
  "Creative Writing",
  "Technical Writing",
  "Grant Writing",
  "Translation Services",
  "Language Instruction",
  "Culinary Arts",
  "Beverage Studies",
  "Tourism and Hospitality Management",
  "Event Planning and Management",
  "Personal Finance Planning",
  "Investment Strategy",
  "Risk Management",
  "Insurance Underwriting",
  "Banking Operations",
  "Cryptocurrency Analysis",
  "E-commerce Strategy",
  "Retail Management",
  "Customer Service Excellence",
];

const countries = [
  { label: "Afghanistan" },
  { label: "Albania" },
  { label: "Algeria" },
  { label: "Andorra" },
  { label: "Angola" },
  { label: "Antigua and Barbuda" },
  { label: "Argentina" },
  { label: "Armenia" },
  { label: "Australia" },
  { label: "Austria" },
  { label: "Azerbaijan" },
  { label: "Bahamas" },
  { label: "Bahrain" },
  { label: "Bangladesh" },
  { label: "Barbados" },
  { label: "Belarus" },
  { label: "Belgium" },
  { label: "Belize" },
  { label: "Benin" },
  { label: "Bhutan" },
  { label: "Bolivia" },
  { label: "Bosnia and Herzegovina" },
  { label: "Botswana" },
  { label: "Brazil" },
  { label: "Brunei" },
  { label: "Bulgaria" },
  { label: "Burkina Faso" },
  { label: "Burundi" },
  { label: "Cabo Verde" },
  { label: "Cambodia" },
  { label: "Cameroon" },
  { label: "Canada" },
  { label: "Central African Republic" },
  { label: "Chad" },
  { label: "Chile" },
  { label: "China" },
  { label: "Colombia" },
  { label: "Comoros" },
  { label: "Congo" },
  { label: "Costa Rica" },
  { label: "Croatia" },
  { label: "Cuba" },
  { label: "Cyprus" },
  { label: "Czech Republic" },
  { label: "Denmark" },
  { label: "Djibouti" },
  { label: "Dominica" },
  { label: "Dominican Republic" },
  { label: "Ecuador" },
  { label: "Egypt" },
  { label: "El Salvador" },
  { label: "Equatorial Guinea" },
  { label: "Eritrea" },
  { label: "Estonia" },
  { label: "Eswatini" },
  { label: "Ethiopia" },
  { label: "Fiji" },
  { label: "Finland" },
  { label: "France" },
  { label: "Gabon" },
  { label: "Gambia" },
  { label: "Georgia" },
  { label: "Germany" },
  { label: "Ghana" },
  { label: "Greece" },
  { label: "Grenada" },
  { label: "Guatemala" },
  { label: "Guinea" },
  { label: "Guinea-Bissau" },
  { label: "Guyana" },
  { label: "Haiti" },
  { label: "Honduras" },
  { label: "Hungary" },
  { label: "Iceland" },
  { label: "India" },
  { label: "Indonesia" },
  { label: "Iran" },
  { label: "Iraq" },
  { label: "Ireland" },
  { label: "Israel" },
  { label: "Italy" },
  { label: "Jamaica" },
  { label: "Japan" },
  { label: "Jordan" },
  { label: "Kazakhstan" },
  { label: "Kenya" },
  { label: "Kiribati" },
  { label: "Kosovo" },
  { label: "Kuwait" },
  { label: "Kyrgyzstan" },
  { label: "Laos" },
  { label: "Latvia" },
  { label: "Lebanon" },
  { label: "Lesotho" },
  { label: "Liberia" },
  { label: "Libya" },
  { label: "Liechtenstein" },
  { label: "Lithuania" },
  { label: "Luxembourg" },
  { label: "Madagascar" },
  { label: "Malawi" },
  { label: "Malaysia" },
  { label: "Maldives" },
  { label: "Mali" },
  { label: "Malta" },
  { label: "Marshall Islands" },
  { label: "Mauritania" },
  { label: "Mauritius" },
  { label: "Mexico" },
  { label: "Micronesia" },
  { label: "Moldova" },
  { label: "Monaco" },
  { label: "Mongolia" },
  { label: "Montenegro" },
  { label: "Morocco" },
  { label: "Mozambique" },
  { label: "Myanmar" },
  { label: "Namibia" },
  { label: "Nauru" },
  { label: "Nepal" },
  { label: "Netherlands" },
  { label: "New Zealand" },
  { label: "Nicaragua" },
  { label: "Niger" },
  { label: "Nigeria" },
  { label: "North Korea" },
  { label: "North Macedonia" },
  { label: "Norway" },
  { label: "Oman" },
  { label: "Pakistan" },
  { label: "Palau" },
  { label: "Palestine" },
  { label: "Panama" },
  { label: "Papua New Guinea" },
  { label: "Paraguay" },
  { label: "Peru" },
  { label: "Philippines" },
  { label: "Poland" },
  { label: "Portugal" },
  { label: "Qatar" },
  { label: "Romania" },
  { label: "Russia" },
  { label: "Rwanda" },
  { label: "Saint Kitts and Nevis" },
  { label: "Saint Lucia" },
  { label: "Saint Vincent and the Grenadines" },
  { label: "Samoa" },
  { label: "San Marino" },
  { label: "Sao Tome and Principe" },
  { label: "Saudi Arabia" },
  { label: "Senegal" },
  { label: "Serbia" },
  { label: "Seychelles" },
  { label: "Sierra Leone" },
  { label: "Singapore" },
  { label: "Slovakia" },
  { label: "Slovenia" },
  { label: "Solomon Islands" },
  { label: "Somalia" },
  { label: "South Africa" },
  { label: "South Korea" },
  { label: "South Sudan" },
  { label: "Spain" },
  { label: "Sri Lanka" },
  { label: "Sudan" },
  { label: "Suriname" },
  { label: "Sweden" },
  { label: "Switzerland" },
  { label: "Syria" },
  { label: "Taiwan" },
  { label: "Tajikistan" },
  { label: "Tanzania" },
  { label: "Thailand" },
  { label: "Timor-Leste" },
  { label: "Togo" },
  { label: "Tonga" },
  { label: "Trinidad and Tobago" },
  { label: "Tunisia" },
  { label: "Turkey" },
  { label: "Turkmenistan" },
  { label: "Tuvalu" },
  { label: "Uganda" },
  { label: "Ukraine" },
  { label: "United Arab Emirates" },
  { label: "United Kingdom" },
  { label: "United States" },
  { label: "Uruguay" },
  { label: "Uzbekistan" },
  { label: "Vanuatu" },
  { label: "Vatican City" },
  { label: "Venezuela" },
  { label: "Vietnam" },
  { label: "Yemen" },
  { label: "Zambia" },
  { label: "Zimbabwe" },
];

const industries = industriesData.map((label) => ({
  label,
  value: label,
}));

const skills = skillsData.map((label) => ({
  label,
  value: label,
}));

const countriesData = countries.map((l) => l.label);

const tags = [
  { label: "Founding Team" },
  { label: "Contributor" },
  { label: "Intern/Volunteer" },
  { label: "Consultant" },
  { label: "Investor" },
];

const stages = [
  { label: "Idea" },
  { label: "Prototype" },
  { label: "Start-up" },
  { label: "Project" },
  { label: "Growth" },
  { label: "Maturity" },
];

const sorting = [
  { label: "Recently Modified" },
  { label: "Newest First" },
  { label: "A-Z" },
  { label: "Z-A" },
  { label: "Followers (P1)" },
];

const emojis = [
  "❤️ - Red Heart",
  "🎉 - Party Popper",
  "👍 - Thumbs Up",
  "👏 - Clapping Hands",
  "🌟 - Glowing Star",
  "🌈 - Rainbow",
  "✨ - Sparkles",
  "🍕 - Pizza",
  "🍷 - Wine Glass",
  "🎈 - Balloon",
  "🎶 - Musical Notes",
  "🎵 - Musical Note",
  "🎊 - Confetti Ball",
  "🎁 - Wrapped Gift",
  "📷 - Camera",
  "📱 - Mobile Phone",
  "📚 - Books",
  "📺 - Television",
  "💻 - Laptop Computer",
  "💡 - Light Bulb",
  "💯 - Hundred Points",
  "🔥 - Fire",
  "🚀 - Rocket",
  "🌸 - Cherry Blossom",
  "🌼 - Blossom",
  "🍀 - Four Leaf Clover",
  "🍂 - Fallen Leaf",
  "🌞 - Sun with Face",
  "🌝 - Full Moon with Face",
  "🌎 - Globe Showing Americas",
  "🌍 - Globe Showing Europe-Africa",
  "⭐️ - Star",
  "🌊 - Water Wave",
  "🍔 - Hamburger",
  "🍟 - French Fries",
  "🍦 - Soft Ice Cream",
  "🍩 - Doughnut",
  "🍻 - Clinking Beer Mugs",
  "🍹 - Tropical Drink",
  "🎮 - Video Game",
  "🎬 - Clapper Board",
  "🎤 - Microphone",
  "🎸 - Guitar",
  "🎭 - Performing Arts",
  "🎩 - Top Hat",
  "🎰 - Slot Machine",
  "🎳 - Bowling",
  "🎲 - Game Die",
  "🎻 - Violin",
  "🎼 - Musical Score",
  "🏆 - Trophy",
  "⚽️ - Soccer Ball",
  "🏀 - Basketball",
  "🏈 - American Football",
  "🎾 - Tennis",
  "🏓 - Ping Pong",
  "🏸 - Badminton",
  "🏒 - Ice Hockey",
  "🚗 - Car",
  "🚲 - Bicycle",
  "🚌 - Bus",
  "🚀 - Rocket",
  "🚢 - Ship",
  "✈️ - Airplane",
  "🚁 - Helicopter",
  "🚂 - Locomotive",
  "🚒 - Fire Engine",
  "🚓 - Police Car",
  "🚑 - Ambulance",
  "🚔 - Oncoming Police Car",
  "🚕 - Taxi",
  "🚚 - Delivery Truck",
  "🚜 - Tractor",
  "🛵 - Motor Scooter",
  "🚤 - Speedboat",
  "⛵️ - Sailboat",
  "🚲 - Bicycle",
  "🛴 - Kick Scooter",
  "🚅 - Bullet Train",
  "🎯 - Direct Hit",
  "🎱 - Pool 8 Ball",
  "🏹 - Bow and Arrow",
  "🏓 - Ping Pong",
  "🏸 - Badminton",
  "🥅 - Goal Net",
  "🥇 - 1st Place Medal",
  "🥈 - 2nd Place Medal",
  "🥉 - 3rd Place Medal",
  "🎖️ - Military Medal",
  "🏅 - Sports Medal",
  "🎮 - Video Game",
  "🎲 - Game Die",
  "🧩 - Puzzle Piece",
  "♟️ - Chess Pawn",
  "🎯 - Direct Hit",
  "🎱 - Pool 8 Ball",
  "🏂 - Snowboarder",
  "🚣‍♀️ - Woman Rowing Boat",
  "🚣‍♂️ - Man Rowing Boat",
  "🛌 - Person in Bed",
  "🛋️ - Couch and Lamp",
  "🚽 - Toilet",
  "🚿 - Shower",
  "🛁 - Bathtub",
  "⌚ - Watch",
  "📱 - Mobile Phone",
  "💻 - Laptop Computer",
  "🖥️ - Desktop Computer",
  "🖨️ - Printer",
  "🖱️ - Computer Mouse",
  "🖲️ - Trackball",
  "🕹️ - Joystick",
  "🗜️ - Clamp",
  "⌨️ - Keyboard",
  "💽 - Computer Disk",
  "💾 - Floppy Disk",
  "💿 - Optical Disk",
  "📀 - DVD",
  "🎥 - Movie Camera",
  "🎞️ - Film Frames",
  "📽️ - Film Projector",
  "📷 - Camera",
  "📸 - Camera with Flash",
  "📹 - Video Camera",
  "📼 - Videocassette",
  "📺 - Television",
  "📻 - Radio",
  "🎙️ - Studio Microphone",
  "🎚️ - Level Slider",
  "🎛️ - Control Knobs",
  "⏱️ - Stopwatch",
  "⏲️ - Timer Clock",
  "⏰ - Alarm Clock",
  "🕰️ - Mantelpiece Clock",
  "⌛ - Hourglass Done",
  "⏳ - Hourglass Not Done",
  "📡 - Satellite Antenna",
  "🔋 - Battery",
  "🔌 - Electric Plug",
  "💡 - Light Bulb",
  "🔦 - Flashlight",
  "🕯️ - Candle",
  "🧯 - Fire Extinguisher",
  "🛢️ - Oil Drum",
  "💸 - Money with Wings",
  "💵 - Dollar Banknote",
  "💴 - Yen Banknote",
  "💶 - Euro Banknote",
  "💷 - Pound Banknote",
  "💰 - Money Bag",
  "💳 - Credit Card",
  "🧾 - Receipt",
  "💎 - Gem Stone",
  "📦 - Package",
  "📫 - Closed Mailbox with Raised Flag",
  "📪 - Closed Mailbox with Lowered Flag",
  "📬 - Open Mailbox with Raised Flag",
  "📭 - Open Mailbox with Lowered Flag",
  "📮 - Postbox",
  "🗳️ - Ballot Box with Ballot",
  "✉️ - Envelope",
  "📩 - Envelope with Arrow",
  "📨 - Incoming Envelope",
  "📧 - E-Mail",
  "💌 - Love Letter",
  "📤 - Outbox Tray",
  "📥 - Inbox Tray",
  "📦 - Package",
  "📯 - Postal Horn",
  "📜 - Scroll",
];

export {
  emojis,
  industriesData,
  skillsData,
  industries,
  skills,
  countries,
  tags,
  stages,
  sorting,
  countriesData,
};
