import { SimpleGrid, Stack, Title } from "@mantine/core";
import React, { useEffect, useState } from "react";
import StartupCard from "./Card";
import fetchApi from "../../Lib/api";

export default function OwnStartups() {
  const [startups, setStartups] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(
          `/startup_entities/user_owner_startup_entities`,
          "GET"
        );

        if (response.ok) {
          const res = await response.json();
          // setNotification({ type: "success", content: msg });
          console.log(res);
          setStartups(res);
          // window.location.href = "/startup_entities/1";
        } else {
          const msg = (await response.json()).error;
          // setNotification({ type: "failure", content: msg });
          console.error(msg);
        }
      } catch (error) {
        console.error("Error during password change:", error);
      }
    };

    fetchData(); // Call the async function immediately
  }, []);

  return (
    <main className="flex justify-center ">
      <div className="max-w-7xl w-full p-6">
        <Stack spacing={12}>
          <Title order={4} className="text-slate-900">
            Own Startups
          </Title>
          <SimpleGrid cols={3} spacing="lg" verticalSpacing="lg">
            {startups.map(({ startup_entity, openings_length }) => (
              <StartupCard
                key={startup_entity.id}
                startup={startup_entity}
                isFollowing={null}
                roles={openings_length}
              />
            ))}
          </SimpleGrid>
        </Stack>
      </div>
    </main>
  );
}
